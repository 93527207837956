import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { SegmentRules } from './SegmentRules';
import { Grid, InputLabel, SelectChangeEvent } from '@mui/material';
import { colorMenus, getColorIndexByTitle, getColorTitleByIndex, renderColorSelect } from '../../utils';

interface Props extends BaseDialogProps {
    readonly selectedSegment: Entities.SegmentData | null;
    readonly onUpdate: (id: string, name: string, color: string, rules: Entities.SegmentRule[]) => void;
}

interface State extends BaseDialogState {
    readonly id: string;
    readonly name: string;
    readonly color: number;
    readonly rules: Entities.SegmentRule[];
}

export class UpdateSegmentDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedSegment !== prevProps.selectedSegment) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { name, color, rules } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateName}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Color</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    {renderColorSelect(this.handleColorChange, color)}
                </Grid>
                <Grid item={true} xs={12}>
                    <SegmentRules
                        rules={rules}
                        onUpdateRules={this.onUpdateRules}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onSubmit} variant="text" color="secondary" />
        );
    }

    protected initState(): State {
        const { selectedSegment } = this.props;
        if (!selectedSegment) {
            return {
                id: '',
                name: '',
                color: colorMenus.Grey.value,
                rules: [],
            };
        }

        return {
            id: selectedSegment.segment.id,
            name: selectedSegment.segment.name,
            color: getColorIndexByTitle(selectedSegment.segment.color),
            rules: selectedSegment.rules,
        };
    }

    private onUpdateName = (name: string) => this.setState({ name });

    protected handleColorChange = (event: SelectChangeEvent<number>) => this.setState({ color: event.target.value as number });

    private onUpdateRules = (rules: Entities.SegmentRule[]) => this.setState({rules});

    private onSubmit = () => {
        const { id, name, rules } = this.state;
        const color = getColorTitleByIndex(this.state.color);

        this.props.onUpdate(id, name, color, rules);
        this.onClose();
    };
}
