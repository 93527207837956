import * as React from 'react';
import actions, { ActionRequest } from '../actions';
import { ContainerContext, mapProps } from './';
import {
    BaseContainer,
    BaseElement,
    WarningDialog,
    LiveEventsCalendarDialog,
    LiveEventsFilter,
    Table,
    HeaderButton,
} from '../components';
import { connect } from 'react-redux';
import {
    formatDate,
    isActive,
    isComing,
    isEnded,
    Link,
    truncateString,
} from '../utils';
import { Chip, Grid, MenuItem, Menu, Hidden } from '@mui/material';
import {
    AddOutlined,
    BarChartOutlined,
    CalendarToday as CalendarTodayIcon,
    CalendarTodayOutlined,
    DeleteOutlineOutlined,
    DeveloperModeOutlined,
    EventOutlined, LibraryAddOutlined, ModeEditOutlineOutlined,
} from '@mui/icons-material';
import {
    EventType,
    LiveEventTypeFilter,
    LiveEventStatusFilter,
    UserTitleRole,
} from '../enums';
import { useNavigate } from 'react-router';
import { styled } from '@mui/styles';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

type DialogType = 'DeleteLiveEvent' | 'ShowCalendar';
interface State {
    readonly typeFilter: LiveEventTypeFilter;
    readonly statusFilter: LiveEventStatusFilter;
    readonly developerOnly: boolean;
    readonly authAnchorEl: HTMLElement | undefined;
    readonly openDialog: DialogType | null;
    readonly selectedLiveEvent: Entities.LiveEvent | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        typeFilter: LiveEventTypeFilter.All,
        statusFilter: LiveEventStatusFilter.All,
        developerOnly: false,
        authAnchorEl: undefined,
        openDialog: null,
        selectedLiveEvent: null,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.handleCreateMenu},
            { text: 'View Calendar', icon: CalendarTodayOutlined, onClick: this.openCalendarDialog},
        ];
        return (
            <BaseContainer
                {...this.props}
                title = "Live Events"
                TitleIcon = {CalendarTodayIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateMenu()}
                {this.renderDeleteLiveEventDialog()}
                {this.renderCalendarDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const { developerOnly, typeFilter, statusFilter } = this.state;

        const tableRef = React.createRef();

        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Schedule', field: 'schedule'},
        ];

        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };

        const onResetDeveloper = () => {
            this.setState({ developerOnly: false });
            onSearch();
        };

        const onResetTypeFilter = () => {
            this.setState({ typeFilter: LiveEventTypeFilter.All });
            onSearch();
        };

        const onResetStatusFilter = () => {
            this.setState({ statusFilter: LiveEventStatusFilter.All });
            onSearch();
        };

        const onFilter = (type: LiveEventTypeFilter, status: LiveEventStatusFilter, developer: boolean) => {
            this.setState({ typeFilter: type, statusFilter: status, developerOnly: developer });
            onSearch();
        };

        const showChips = typeFilter !== LiveEventTypeFilter.All || statusFilter !== LiveEventStatusFilter.All || developerOnly;
        const title = (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                    <LiveEventsFilter
                        typeFilter={typeFilter}
                        statusFilter={statusFilter}
                        developerOnly={developerOnly}
                        onFilter={onFilter}
                    />
                </Grid>
                {showChips &&
                    <Grid item={true} xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gridColumnGap: 5, gridGap: 5}} >
                            {typeFilter === LiveEventTypeFilter.Regular && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<EventOutlined />}
                                    label='Event Type: "Regular"'
                                    onDelete={onResetTypeFilter}
                                />
                            )}
                            {typeFilter === LiveEventTypeFilter.LeaderBoard && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<BarChartOutlined />}
                                    label='Event Type: "Leaderboard"'
                                    onDelete={onResetTypeFilter}
                                />
                            )}
                            {statusFilter === LiveEventStatusFilter.Active && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "In Progress"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {statusFilter === LiveEventStatusFilter.Coming && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "Scheduled"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {statusFilter === LiveEventStatusFilter.Completed && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "Completed"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {developerOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<DeveloperModeOutlined />}
                                    label='Player Type: "Developer only"'
                                    onDelete={onResetDeveloper}
                                />
                            )}
                        </div>
                    </Grid>
                }
            </Grid>
        );

        const onRowClick = (event: any, rowData: any) => this.toLink(`/liveEvent/${rowData.liveEvent.eventId}/details`);

        return (
            <Table
                tableRef={tableRef}
                title={title}
                columns = {columns}
                onRowClick={onRowClick}
                options = {{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    sorting: false,
                    draggable: false,
                    search: true,
                    actionsColumnIndex: -1,
                }}
                data = {this.getLiveEvents}
                actions = {
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                hidden: !isComing(rowData.liveEvent.startTimestamp, rowData.liveEvent.endTimestamp),
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.editLiveEvent(rowData.liveEvent)
                            }),
                            rowData => ({
                                icon: LibraryAddOutlined,
                                tooltip: 'Duplicate',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.duplicateLiveEvent(rowData.liveEvent)
                            }),
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.deleteLiveEvent(rowData.liveEvent)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private getLiveEvents = async (query: any) => {
        const { typeFilter, statusFilter, developerOnly } = this.state;
        const { search, page, pageSize } = query;

        const list = await ActionRequest.get<Entities.ListLiveEventResponse>(`liveEvent/listByPage`, {
            name: search,
            typeFilter,
            statusFilter,
            developerOnly,
            page: page + 1,
            perPage: pageSize,
        });


        if (!list) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const liveEvents = list.liveEvents
            .sort((e1, e2) => {
                const e1Active = isActive(e1.startTimestamp, e1.endTimestamp);
                const e2Active = isActive(e2.startTimestamp, e2.endTimestamp);
                const e1Coming = isComing(e1.startTimestamp, e1.endTimestamp);
                const e2Coming = isComing(e2.startTimestamp, e2.endTimestamp);
                if (e1Active && e2Coming) {
                    return -1;
                } else if (e2Active && e1Coming) {
                    return 1;
                } else if ((e1Active && !e2Active) || (e1Coming && !e2Coming)) {
                    return -1;
                } else if (!e1Active && e2Active || (!e1Coming && e2Coming)) {
                    return 1;
                }
                return e2.startTimestamp - e1.startTimestamp;
            });

        const data = liveEvents.map(e => {
                const eventDetails = this.getDetails(e);
                const eventSchedule = this.getSchedule(e);
                return {
                    liveEvent: e,
                    details: eventDetails,
                    schedule: eventSchedule,
                };
            }
        );
        return {
            data,
            page: list.page - 1,
            totalCount: list.totalCount,
        };
    };

    private getDetails = (liveEvent: Entities.LiveEvent) => {
        const to = `/liveEvent/${liveEvent.eventId}/details`;
        let icon: React.JSX.Element = (<></>);
        switch (liveEvent.eventType) {
            case EventType.Regular:
                icon = <EventOutlined style={{width: 25, height: 25}}/>;
                break;
            case EventType.LeaderBoard:
                icon = <BarChartOutlined style={{width: 25, height: 25}}/>;
                break;
            default:
                break;
        }

        return (
            <div>
                {liveEvent.developerOnly && (
                    <Chip
                        color={'secondary'}
                        icon={<DeveloperModeOutlined />}
                        size='small'
                        label='Developer Only'
                        style={{ backgroundColor: '#21BA47', color: 'white' }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    <Hidden smDown={true}>
                        <div style={{
                            padding: '4px 0px',
                            display: 'grid',
                            justifyContent: 'flex-end',
                            gridAutoFlow: 'column',
                        }}>
                            {icon}
                        </div>
                    </Hidden>
                    <div style={{display: 'inline-block', marginLeft: 10, verticalAlign: 'top'}}>
                        <Link to={to}>
                            {liveEvent.name !== '' ? truncateString(liveEvent.name) : liveEvent.eventId}
                        </Link>
                        <div>
                            <div style={{ fontWeight: 500 }}>Created</div>
                            {formatDate(liveEvent.createdTimestamp)}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>Last Edited</div>
                            {formatDate(liveEvent.lastUpdatedTimestamp)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private getSchedule = (liveEvent: Entities.LiveEvent) => {
        return (
            <div>
                <div>
                    {this.getStatus(liveEvent)}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>Start</div>
                    {formatDate(liveEvent.startTimestamp)}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>End</div>
                    {formatDate(liveEvent.endTimestamp)}
                </div>
            </div>
        );
    };

    private getStatus = (liveEvent: Entities.LiveEvent) => {
        if (isActive(liveEvent.startTimestamp, liveEvent.endTimestamp)) {
            return <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        if (isEnded(liveEvent.endTimestamp)) {
            return <div style={{color: 'orange', fontWeight: 500}}>Completed</div>;
        }
        return <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
    };

    private editLiveEvent = (liveEvent: Entities.LiveEvent) => this.toLink(`/liveEvent/${liveEvent.eventId}/edit`);

    private duplicateLiveEvent = (liveEvent: Entities.LiveEvent) => this.toLink(`/liveEvent/${liveEvent.eventId}/duplicate`);

    private deleteLiveEvent = (liveEvent: Entities.LiveEvent) => this.setState({openDialog: 'DeleteLiveEvent', selectedLiveEvent: liveEvent});

    private renderCreateMenu = () => {
        const { authAnchorEl } = this.state;
        const open = !!authAnchorEl;

        return (
            <StyledMenu
                id="menu-new-event"
                anchorEl={authAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={this.closeCreateMenu}
            >
                <MenuItem color="inherit" onClick={this.goToCreateRegular}>
                    <EventOutlined style={{ paddingRight: 16 }}/>
                    Regular Live Event
                </MenuItem>
                <MenuItem color="inherit" onClick={this.goToCreateLeaderBoard}>
                    <BarChartOutlined style={{ paddingRight: 16 }}/>
                    Leaderboard Live Event
                </MenuItem>
            </StyledMenu>
        );
    };

    private goToCreateRegular = () => this.toLink('/liveEvent/createRegular');

    private goToCreateLeaderBoard = () => this.toLink('/liveEvent/createLeaderBoard');

    private renderDeleteLiveEventDialog = () => {
        const { openDialog, selectedLiveEvent } = this.state;
        if (!selectedLiveEvent) {
            return;
        }
        const title = `Delete live event '${selectedLiveEvent ? selectedLiveEvent.name : ''}'?`;

        return (
            <WarningDialog
                open={openDialog === 'DeleteLiveEvent'}
                title={title}
                TitleIcon = {CalendarTodayIcon}
                content="This will permanently delete the event and all associated configuration."
                onClose={this.onCloseDialog}
                onSubmit={this.onDeleteLiveEvent}
            />
        );
    };
    private onDeleteLiveEvent = async () => {
        const { selectedLiveEvent } = this.state;
        if (!selectedLiveEvent) {
            return;
        }
        await actions.liveEvent.remove(selectedLiveEvent.eventId);
    };

    private openCalendarDialog = () => this.setState({ openDialog: 'ShowCalendar' });
    private renderCalendarDialog = () => {
        const { openDialog } = this.state;
        return (
            <LiveEventsCalendarDialog
                open={openDialog === 'ShowCalendar'}
                title={'Live Events Calendar'}
                TitleIcon={CalendarTodayOutlined}
                liveEvents={[]}
                onClose={this.onCloseDialog}
            />
        );
    };

    private handleCreateMenu = (event: any) => this.setState({ authAnchorEl: event.currentTarget });

    private closeCreateMenu = () => this.setState({ authAnchorEl: undefined });

    private onCloseDialog = () => this.setState({ openDialog: null, selectedLiveEvent: null});
}

export const StyledChip = styled(Chip)(({
    backgroundColor: '#1a73e8',
    borderRadius: 0,
}));

const StyledMenu = styled(Menu)(({
    '& .MuiMenu-paper': {
        borderRadius: 0,
        marginTop: 2,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 8px 3px, rgba(0, 0, 0, 0.3) 0px 1px 3px',
    },
    '& .MuiMenu-list': {
        padding: 0,
    },
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
