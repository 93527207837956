import { UserActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState, UserAction, UserState } from '../reducers';

export const getUsers = async () => {
    const response = await Request.get('users/list');
    const action: UserAction = {
        type: UserActionType.UpdateUsers,
        payload: {
            users: response.users,
        },
    };
    dispatch(action);
};

export const createUser = async (newUser: Entities.BaseUser) => {
    const user: Entities.User | null = await Request.post(`users/create`, newUser);
    if (!user) {
        return;
    }

    const state: UserState = getState().user;
    dispatch({
        type: UserActionType.UpdateUsers,
        payload: {
            users: [...state.users, user],
        },
    });
};

export const deleteUser = async ({ username }: Entities.BaseUser) => {
    await Request.post(`users/delete`, { username });
    const state: UserState = getState().user;
    dispatch({
        type: UserActionType.UpdateUsers,
        payload: {
            users: state.users.filter((u: Entities.User) => u.username !== username),
        },
    });
};

export const updateUser = async ({ username, role }: Entities.BaseUser) => {
    const { admin } = await Request.post(`users/update/${username}`, { username, role });
    const state: UserState = getState().user;
    const { loginUser } = state;
    const users = [...state.users];
    const index = users.findIndex(u => u.username === admin.username);
    users[index] = admin;
    const action: UserAction = {
        type: UserActionType.UpdateUsers,
        payload: {
            loginUser: loginUser && loginUser.username === admin.username ? admin : loginUser,
            users,
        },
    };
    dispatch(action);
};

export const refreshTitles = async ({ username }: Entities.BaseUser) => {
    await Request.post(`users/refreshTitles`, { username });
};

export const updatePassword = async (password: string) => {
    await Request.post('users/me/password', { password });
};
