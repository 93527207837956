import { BaseDialog, BaseDialogProps } from './BaseDialog';
import { DialogContentText } from '@mui/material';
import * as React from 'react';
import Dropzone from '../Dropzone';
import { Button } from '../input';

interface ImportDialogProps extends BaseDialogProps {
    readonly content: string;
    readonly dropzoneText: string;
    readonly submitButtonText?: string;
    readonly onImport: (data: string) => void;
}

interface State {
    readonly file: any;
}

export class ImportJsonDialog extends BaseDialog<ImportDialogProps, State> {
    state: State = this.initState();

    protected renderContent(): React.JSX.Element {
        const { content, dropzoneText, children } = this.props;
        return (
            <>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Dropzone
                    accept={{
                        "application/json": ['.json'],
                    }}
                    text={dropzoneText}
                    height={100}
                    onDropFile={this.handleDrop}
                />
                {children}
            </>

        );
    }

    protected renderActions(): React.JSX.Element {
        const { submitButtonText } = this.props;
        const { file } = this.state;
        return (
            <Button text={submitButtonText || 'Import'} onClick={this.onImport} variant="text" color="secondary" disabled={!file} />
        );
    }

    protected initState(): State {
        return {
            file: null,
        };
    }

    private handleDrop = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            const { result } = reader;
            if (typeof result !== 'string') {
                return;
            }
            const json = result.trim();
            try {
                JSON.parse(json);
                this.setState({ file: json });
            } catch (e) {
                console.error('Invalid JSON format', e);
            }
        };
        reader.readAsText(file, 'utf8');
    };

    private onImport = async () => {
        const { file } = this.state;
        this.onClose();
        this.props.onImport(file);
    };
}
