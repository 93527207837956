import { Action } from './index';
import { TitleActionType, SeasonActionType, AppActionType } from '../enums';

export interface SeasonState {
    readonly list?: Entities.ListSeasonsResponse;
    readonly season?: Entities.Season;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
}

export type SeasonAction = Action<Partial<SeasonState>>;

const initState: SeasonState = {
};

const reducer = (
    state: SeasonState = initState,
    action: SeasonAction,
): SeasonState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case SeasonActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
