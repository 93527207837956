import * as React from 'react';
import actions from '../actions';
import { connect } from 'react-redux';
import { BaseContainer, BaseElement, CreateTitleDialog, TitleCard } from '../components';
import { AddOutlined } from '@mui/icons-material';
import { ContainerContext, mapProps } from './index';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { UserRole } from '../enums';
import { StateMap, TitleState } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly title: TitleState;
}

type DialogType = 'New';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedTitle: Entities.Title | null;
}

class Container extends BaseElement<Props, State> {
    state = {
        openDialog: null,
        selectedTitle: null,
    };

    async componentDidMount() {
        actions.title.resetTitle();
        await actions.title.list();
    }

    protected renderContainer(): React.JSX.Element {
        return (
            <BaseContainer
                loading={this.props.loading}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderNewDialog()}
            </>
        );
    }

    private renderContent = () => {
        return (
            <div style={{ maxWidth: 960, margin: 'auto' }}>
                <Grid container={true} alignItems="center" spacing={4}>
                    {this.renderTitles()}
                </Grid>
            </div>
        );
    };

    private renderTitles = () => {
        let { titles } = this.props.title;
        if (!titles) {
            return;
        }
        const titleViews: React.JSX.Element[] = [];
        titles = [...titles].sort((t1, t2) => t2.name > t1.name ? -1 : t2.name < t1.name ? 1 : 0);
        if (this.props.userRole === UserRole.Admin) {
            titleViews.push(
                <Grid item={true} xs={12} sm={12} md={6}>
                    <Card
                        key={`add_new`}
                        style={{
                            border: '1px solid rgb(217, 217, 217)',
                            boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                            borderRadius: 8,
                            backgroundColor: 'white',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <CardActionArea onClick={this.openNewDialog}>
                            <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <AddOutlined style={{width: 36, height: 36, color: '#1a73e8'}}/>
                                <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 500, color: '#1a73e8', marginTop: 10 }}>
                                    Add project
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        }
        titles.forEach( (title: Entities.UserTitleData) => {
            titleViews.push(
                <Grid item={true} xs={12} sm={6}>
                    <TitleCard
                        title={title}
                        onClick={this.openTitleSelect}
                    />
                </Grid>
            );
        });
        return titleViews;
    };

    private openTitleSelect = (title: Entities.UserTitleData) => {
        actions.title.setTitle(title);
        const { navigate } = this.props;
        if (navigate === null || navigate === undefined) {
            return;
        }
        navigate(`/${title.id}/dashboard`)
    };

    private renderNewDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateTitleDialog
                open={openDialog === 'New'}
                title={'Create Title'}
                onCreate={this.onCreateTitle}
                onClose={this.closeDialog}
            />
        );
    };

    private openNewDialog = () => this.setState({ openDialog: 'New' });

    private onCreateTitle = async (name: string, apiUrl: string, apiSecretKey: string) => {
        this.closeDialog();
        await actions.title.create(name, apiUrl, apiSecretKey);
    };

    private closeDialog = () => {
        this.setState({ openDialog: null });
    };
}

const mapStateToProps = (state: StateMap): Props => {
    return {
        ...mapProps(state),
        title: state.title,
    };
};
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
