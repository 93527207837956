import * as _ from 'lodash';
import * as React from 'react';
import {
    Avatar,
    CardMedia,
    Divider,
    Grid,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuProps,
    AppBar,
    MenuItem,
    Button as MaterialButton,
    useScrollTrigger,
    AppBarProps, Typography,
} from '@mui/material';
import {
    ArrowDropDown,
    CasinoOutlined,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { styled } from '@mui/styles';
import { AccountDialog } from '../../admin';
import actions from '../../../actions';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { UserRole } from '../../../enums';
import { Link as ReactRouterLink } from 'react-router-dom';

interface Props {
    readonly position?: 'fixed' | 'absolute' | 'sticky' | 'static';
    readonly navigate?: NavigateFunction;
    readonly userRole: UserRole;
    readonly showDrawerOpener: boolean;
    readonly loginUser: Entities.User;
    readonly titles?: Entities.Title[];
    readonly titleSelected?: Entities.Title;
    readonly auth: boolean;
    readonly clickLogoutMenu: () => any;
    readonly onTitlesMenuClick: (index: number) => any;
    readonly style: React.CSSProperties;
}

interface State {
    readonly authAnchorEl: HTMLElement | undefined;
    readonly titlesAnchorEl: HTMLElement | undefined;
    readonly openAdminDialog: boolean;
}

export class AppHeader extends React.PureComponent<Props, State> {
    state = {
        authAnchorEl: undefined,
        titlesAnchorEl: undefined,
        openAdminDialog: false,
    };

    render() {
        const { position, style } = this.props;
        return (
            <>
                <ElevateAppBar position={position} style={style}>
                    <Grid container={true} justifyContent="center" alignItems="center" spacing={0}>
                        <Grid item={true} xs={10} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {this.renderNucleoIcon()}
                            {this.renderDrawerOpener()}
                            {this.renderSelectTitlesMenu()}
                        </Grid>
                        <Grid item={true} xs={2} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                            {this.renderAuthMenu()}
                        </Grid>
                    </Grid>
                </ElevateAppBar>
                {this.renderTitlesMenu()}
                {this.renderAccountDialog()}
            </>
        );
    }

    private renderNucleoIcon() {
        const { titleSelected } = this.props;
        if (titleSelected) {
            return;
        }

        return (
            <StyledTitleLink to={'/'} >
                <CardMedia
                    sx={{ width: 24, height: 24, backgroundSize: 23 }}
                    image={`${process.env.PUBLIC_URL}/images/nucleo-logo.png`}
                    title="Logo"
                />
                <Typography children={'Nucleo Portal'} variant="h1" color="inherit" sx={{ fontSize: '1rem', fontWeight: 600, fontFamily: '"Google Sans", sans-serif'}}/>
            </StyledTitleLink>
        );
    }

    private renderDrawerOpener() {
        const { showDrawerOpener } = this.props;
        if(!showDrawerOpener) {
            return <></>;
        }

        return (
            <StyledButton onClick={this.handleDrawerOpen}>
                <MenuIcon style={{ width: 24, height: 24 }}/>
            </StyledButton>
        );
    }

    private renderSelectTitlesMenu() {
        const { titleSelected, titles } = this.props;
        if (!titles || !titleSelected || titleSelected.id === '') {
            return;
        }

        const openMenu = (event: any) => this.setState({ titlesAnchorEl: event.currentTarget });

        return (
            <MaterialButton onClick={openMenu} style={{
                justifyContent: 'flex-start',
                fontSize: '14px',
                fontWeight: 500,
                padding: 0,
                textTransform: 'none',
                color: 'black',
                borderRadius: 0,
            }}>
                <Grid container={true} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item={true} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {!titleSelected.imageUrl && (
                            <CasinoOutlined style={{width: 28, height: 28, borderRadius: 5, color: '#476282'}}/>
                        )}
                        {titleSelected.imageUrl && (
                            <CardMedia image={titleSelected.imageUrl} style={{width: 28, height: 28, borderRadius: 5}}/>
                        )}
                    </Grid>
                    <Grid item={true} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#476282'}}>
                        {titleSelected.name}
                    </Grid>
                    <Grid item={true} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#476282'}}>
                        <ArrowDropDown style={{ width: 20 , height: 20}}/>
                    </Grid>
                </Grid>
            </MaterialButton>
        );
    }

    private renderTitlesMenu() {
        const { titles } = this.props;
        const { titlesAnchorEl } = this.state;
        const open = !!titlesAnchorEl;

        const closeMenu = () => this.setState({ titlesAnchorEl: undefined });

        const menuItems = _.map(titles, (title, index) => {
            const clickTitlesMenu = () => {
                const { onTitlesMenuClick } = this.props;
                closeMenu();
                if (onTitlesMenuClick) {
                    onTitlesMenuClick(index);
                }
            };
            return (
                <MenuItem color="inherit" key={title.name} value={index} onClick={clickTitlesMenu}>
                    <ListItemIcon>
                        {!title.imageUrl && (
                            <CasinoOutlined
                                style={{ width: 28, height: 28, borderRadius: 5, color: 'rgba(0,0,0,0.8)' }} />
                        )}
                        {title.imageUrl && (
                            <CardMedia image={title.imageUrl} style={{ width: 28, height: 28, borderRadius: 5 }} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={title.name} />
                </MenuItem>
            );
        });

        const clickAllProjectsMenu = () => {
            const { onTitlesMenuClick } = this.props;
            closeMenu();
            if (onTitlesMenuClick) {
                onTitlesMenuClick(-1);
            }
        };
        return (
            <TitlesMenu
                id="menu-title"
                anchorEl={titlesAnchorEl}
                open={open}
                onClose={closeMenu}
                style={{ margin: '30px 0px' }}
            >
                <MenuItem color="inherit" key={'all projects'} value={-1} onClick={clickAllProjectsMenu}>
                    {'See all projects'}
                </MenuItem>
                <Divider style={{ backgroundColor: '#0000001f', margin: '8px 0'}}/>
                <div style={{color: '#0000008a', padding: '8px 30px'}}>
                    All projects
                </div>
                {menuItems}
            </TitlesMenu>
        );
    }

    private renderAuthMenu() {
        const { auth, loginUser } = this.props;
        const { authAnchorEl } = this.state;
        if (!auth) {
            return;
        }

        const openMenu = (event: any) => this.setState({ authAnchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ authAnchorEl: undefined });

        const clickAccountMenu = () => {
            closeMenu();
            this.clickAccountMenu();
        };

        const clickLogoutMenu = () => {
            closeMenu();
            if (this.props.clickLogoutMenu) {
                this.props.clickLogoutMenu();
            }
        };

        const open = !!authAnchorEl;

        return (
            <>
                <AuthButton
                    style={{ padding: 0, minWidth: 32 }}
                    onClick={openMenu}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={loginUser.picture}
                        style={{
                            height: 32,
                            width: 32,
                            backgroundColor: 'rgba(0,0,0,0.025)',
                        }}
                    />
                </AuthButton>
                <AuthMenu
                    id="menu-appbar"
                    anchorEl={authAnchorEl}
                    open={open}
                    onClose={closeMenu}
                >
                    <Grid container={true}>
                        <Grid item={true} xs={12} style={{ display: 'inline-block', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Avatar
                                src={loginUser.picture}
                                style={{
                                    height: 80,
                                    width: 80,
                                    backgroundColor: 'rgba(0,0,0,0.025)',
                                    margin: '10px auto'
                                }}
                            />
                            <div>
                                <div style={{
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    lineHeight: '1.25rem',
                                }}>
                                    {loginUser.name}
                                </div>
                                <div style={{
                                    fontSize: '0.75rem',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    lineHeight: '1.2',
                                    fontWeight: 400,
                                }}>
                                    {loginUser.username}
                                </div>
                            </div>
                            <MaterialButton
                                style={{
                                    padding: '0px 12px',
                                    width: 150,
                                    color: '#1a73e8',
                                    margin: '10px auto',
                                    height: 30,
                                }}
                                onClick={clickAccountMenu}
                            >
                                {'Account'}
                            </MaterialButton>
                            {this.props.userRole === UserRole.Admin && (
                                <MaterialButton
                                    style={{
                                        padding: '0px 12px',
                                        width: 150,
                                        color: '#1a73e8',
                                        margin: '10px auto',
                                        height: 30,
                                    }}
                                    onClick={this.goToAdmin}
                                >
                                    {'Admin'}
                                </MaterialButton>
                            )}
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item={true} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <MaterialButton
                                style={{
                                    padding: '0px 12px',
                                    width: 150,
                                    border: '1px solid rgb(218, 220, 224)',
                                    color: 'black',
                                    margin: '10px auto',
                                    height: 38,
                                }}
                                onClick={clickLogoutMenu}
                            >
                                {'Sign Out'}
                            </MaterialButton>
                        </Grid>
                    </Grid>
                </AuthMenu>
            </>
        );
    }

    private renderAccountDialog = () => {
        const { loginUser } = this.props;
        const { openAdminDialog } = this.state;
        if (!loginUser) {
            return;
        }

        return (
            <AccountDialog
                open={openAdminDialog}
                title={'Account'}
                loginUser={loginUser as Entities.User}
                onClose={this.closeAdminDialog}
                onUpdatePassword={this.onUpdatePassword}
            />
        );
    }

    private clickAccountMenu = () => this.setState({ openAdminDialog: true });

    private closeAdminDialog = () => this.setState({ openAdminDialog: false });

    private onUpdatePassword = async (password: string) => {
        if (password) {
            await actions.admin.updatePassword(password);
        }
        this.closeAdminDialog();
    };

    private handleDrawerOpen = () => actions.app.changeSmDrawerOpen();

    private goToAdmin = () => {
        if (this.props.navigate) {
            this.props.navigate('/admin');
        }
    }
}

const ElevateAppBar = styled((props: AppBarProps) => {
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 5});
    return (
        <AppBar
            {...props}
            style={{ ...props.style, boxShadow: trigger ? '0px 1px 2px 0px rgba(60,64,67,.3), 0px 1px 3px 1px rgba(60,64,67,.15)' : 'none'}}
        />
    );
})(() => ({}));


const TitlesMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(() => ({
    '& .MuiMenu-paper': {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 8px 3px, rgba(0, 0, 0, 0.3) 0px 1px 3px',
        borderRadius: 0,
        marginTop: 0,
        backgroundColor: 'white',
    },
}));

const AuthMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(() => ({
    '& .MuiMenu-paper': {
        boxShadow: '0 4px 8px 3px rgba(0,0,0,.15), 0 1px 3px rgba(0,0,0,.3)',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        borderRadius: 28,
        marginTop: 12,
        display: 'grid',
    },
    '& .MuiMenu-list': {
        padding: 0,
    }
}));

const StyledButton = styled(MaterialButton)(({
    justifyContent: 'flex-start',
    fontSize: '16px',
    fontWeight: 500,
    padding: '0px 10px 0px 0px',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: 0,
    minWidth: 0,
}));

const AuthButton = styled(MaterialButton)(({
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent'
    },
    '&:focus': {
        backgroundColor: 'transparent',
    }
}));

const StyledTitleLink = styled(ReactRouterLink)(({
    display: 'flex',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#F2F2F2',
    gridColumnGap: 10,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));
