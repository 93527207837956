import * as React from 'react';
import { Table } from '../common';
import { Box, Modal } from '@mui/material';

interface Props {
    readonly open: boolean;
    readonly items: Entities.CompensationPreDefineItem[];
    readonly onClose: () => void;
    readonly onSelect: (key: string, amount: number) => void;
}

export class SelectCompensationPreDefinedItemDialog extends React.PureComponent<Props> {
    render() {
        const { open, items, onClose } = this.props;
        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Key', field: 'key'},
            { title: 'Amount', field: 'amount'},
        ];
        return (
            <Modal
                keepMounted={false}
                open={open}
                onClose={onClose}
            >
                <Box style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <Table
                        data={items}
                        columns={columns}
                        onRowClick={this.onRowClick}
                        options={{
                            showTitle: false,
                            selection: false,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: true,
                            search: true,
                            sorting: false,
                            draggable: false,
                            actionsColumnIndex: -1
                        }}
                    />
                </Box>
            </Modal>
        );
    }

    private onRowClick = (event: any, rowData: any) => {
        const { items } = this.props;
        const selected = items.find( i => i.id === rowData.id);
        if (!selected) {
            return;
        }
        this.props.onSelect(selected.key, selected.amount);
    }
}
