import React from 'react';
import { default as moment, Moment } from 'moment';
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    ListItem,
    ListItemText,
    Menu,
    List, Box,
} from '@mui/material';
import { DateRangePicker } from 'materialui-daterange-picker';
import { DateRange, DefinedRange } from 'materialui-daterange-picker/src/types';
import { ArrowDropDown, DateRange as DateRangeIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';

interface Props {
    readonly onFilter: (start: Moment, end: Moment) => void;
    readonly start: Moment;
    readonly end: Moment;
    readonly definedRanges?: DefinedRange[];
}

interface State {
    readonly anchorEl: HTMLElement | undefined;
}

export class FilterDates extends React.Component<Props, State> {
    state: State = {
        anchorEl: undefined,
    };

    render() {
        const { onFilter, start, end } = this.props;
        const { anchorEl } = this.state;
        const handleMenu = (event: any) => this.setState({ anchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ anchorEl: undefined });

        const onCancelButton = () => {
            closeMenu();
        };

        const now = moment.parseZone().set('seconds', 0).set('millisecond', 0);

        let { definedRanges } = this.props;
        if (!definedRanges) {
            definedRanges = [
                {
                    startDate: now.clone().subtract(60, 'minutes').toDate(),
                    endDate: now.clone().toDate(),
                    label: 'Last 60 minutes',
                },
                {
                    startDate: now.clone().subtract(24, 'hours').toDate(),
                    endDate: now.clone().toDate(),
                    label: 'Last 24 hours',
                },
                {
                    startDate: now.clone().subtract(7, 'days').toDate(),
                    endDate: now.clone().toDate(),
                    label: 'Last 7 days',
                },
                {
                    startDate: now.clone().subtract(1, 'months').toDate(),
                    endDate: now.clone().toDate(),
                    label: 'Last Month',
                },
                {
                    startDate: now.clone().subtract(2, 'months').toDate(),
                    endDate: now.clone().toDate(),
                    label: 'Last 2 Months',
                },
                {
                    startDate: now.clone().subtract(6, 'months').toDate(),
                    endDate: now.clone().toDate(),
                    label: 'Last 6 Months',
                },
            ];
        }

        const open = !!anchorEl;

        const setDateRange = (dateRange: DateRange) => {
            let startDate = moment(dateRange.startDate);
            let endDate = moment(dateRange.endDate);
            if (startDate.diff(endDate) === 0) {
                startDate = startDate.startOf('day');
                endDate = endDate.endOf('day');
            }
            closeMenu();
            onFilter(startDate, endDate);
        };

        const toggle = () => closeMenu();
        const initialDateRange: DateRange = {
            startDate: start.clone().set('seconds', 0).set('millisecond', 0).toDate(),
            endDate: end.clone().set('seconds', 0).set('millisecond', 0).toDate(),
        };

        const selectedDateRange = definedRanges
            .find(r => {
                if(!initialDateRange.startDate || !initialDateRange.endDate) {
                    return false;
                }

                return r.startDate.getTime() === initialDateRange.startDate.getTime() &&
                r.endDate.getTime() === initialDateRange.endDate.getTime();
            });
        const label = selectedDateRange ? selectedDateRange.label : 'Custom';

        return (
            <>
                <CardStyled>
                    <CardActionAreaStyles onClick={handleMenu}>
                        <CardContentStyles>
                            <DateRangeIcon style={{ margin: `auto`}}/>
                            <Grid container={true} spacing={0} style={{ margin: `auto`}}>
                                <Grid item={true} xs={12} style={{ fontSize: 12, lineHeight: '16px', fontWeight: 600}}>
                                    {label}
                                </Grid>
                                <Grid item={true} xs={12} style={{ fontSize: 12, lineHeight: '14px'}}>
                                    {`${start.format('ll')} - ${end.format('ll')}`}
                                </Grid>
                            </Grid>
                            <ArrowDropDown style={{ margin: `auto`}}/>
                        </CardContentStyles>
                    </CardActionAreaStyles>
                </CardStyled>
                <MainMenu
                    id={`filter`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={onCancelButton}
                    style={{
                        marginTop: 2,
                    }}
                >
                    <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                        <MainList>
                            {definedRanges.map((dateRange, index) => {
                                const selected = selectedDateRange ? selectedDateRange === dateRange: false;
                                const handleListItemClick = () => setDateRange(dateRange);
                                return (
                                    <MainListItem
                                        key={`date_range_list_item_${index}`}
                                        selected={selected}
                                        onClick={handleListItemClick}
                                    >
                                        <ListItemText primary={dateRange.label} />
                                    </MainListItem>
                                );
                            })}
                        </MainList>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <DateRangePicker
                            open={true}
                            toggle={toggle}
                            onChange={setDateRange}
                            closeOnClickOutside={true}
                            initialDateRange={initialDateRange}
                            definedRanges={definedRanges}
                            maxDate={moment.parseZone().toDate()}
                        />
                    </Box>
                </MainMenu>
            </>
        );
    }
}

const MainMenu = styled(Menu)(({
    '& .MuiMenu-paper': {
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        border: '1px solid #e0e0e0',
        borderRadius: 0,
        backgroundColor: 'white',
    },
    '& .MuiMenu-list': {
        padding: 0,
    },
}));

const CardStyled = styled(Card)(({
    '&.MuiCard-root': {
        margin: 0,
        border: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        maxWidth: 230,
    },
}));

const CardContentStyles = styled(CardContent)(({
    '&.MuiCardContent-root': {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        gridColumnGap: 10,
        color: 'rgba(0, 0, 0, 0.5)',
    },
}));

const CardActionAreaStyles = styled(CardActionArea)(({
    '&.MuiCardActionArea-root': {
        "&$selected": {
            backgroundColor: 'transparent',
            color: 'rgb(0, 112, 201)',
            boxShadow: 'none',
        },
        "&:disabled": {
            backgroundColor: 'transparent',
            color: 'rgb(0, 112, 201)',
            boxShadow: 'none',
        },
        "&:hover": {
            backgroundColor: 'transparent',
            color: 'rgb(0, 112, 201)',
            boxShadow: 'none',
        },
        "&:focus": {
            backgroundColor: 'transparent',
            color: 'rgb(0, 112, 201)',
            boxShadow: 'none',
        },
    },
}));

const MainList = styled(List)(({
    '&.MuiList-root': {
        minWidth: 200,
        padding: '14px 0',
    },
}));

const MainListItem = styled(ListItem)(({
    '&.MuiListItem-root': {
        padding: '0px 4px 0px 16px',
        height: 32,
        "&$selected": {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            "&:disabled": {
                backgroundColor: 'rgba(0, 0, 0, 0.08)'
            },
            "&:hover": {
                backgroundColor: 'rgba(0, 0, 0, 0.08)'
            },
            "&:focus": {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
        }
    },
    '&.MuiListItem-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    padding: 0,
}));
