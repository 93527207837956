import { Action } from './index';
import { TitleActionType, ClientVersionActionType, AppActionType } from '../enums';

export interface ClientVersionState {
    readonly clientVersions?: Entities.ClientVersion[];
    readonly clientVersionOverrides?: Entities.ClientVersionOverride[];
    readonly cloudCodeRevisions?: Entities.CloudCode[];
    readonly titleDataVersions?: Entities.TitleData[];
    readonly contentVersions?: Entities.FileVersion[];
}

export type ClientVersionAction = Action<Partial<ClientVersionState>>;

const initState: ClientVersionState = {
};

const reducer = (
    state: ClientVersionState = initState,
    action: ClientVersionAction,
): ClientVersionState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case ClientVersionActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
