import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogProps,
} from '@mui/material';
import { Button } from '../input';

export interface BaseDialogProps extends DialogProps {
    readonly title: any;
    readonly TitleIcon?: any;
    readonly TitleIconObject?: any;
    readonly onClose: () => void;
    readonly fullWidth?: boolean;
    readonly maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export interface BaseDialogState {
}

export abstract class BaseDialog<P extends BaseDialogProps = BaseDialogProps, S extends BaseDialogState = BaseDialogState> extends React.Component<P, S> {
    render() {
        const { title, TitleIcon, TitleIconObject, fullWidth, maxWidth } = this.props;

        return (
            <>
                <Dialog
                    open={this.props.open}
                    onClose={this.onClose}
                    fullWidth={fullWidth ? fullWidth : true}
                    maxWidth={maxWidth ? maxWidth : 'md'}
                >
                    <DialogTitle style={{borderBottom: '1px solid #dfdfdf'}}>
                        <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-start', alignItems: 'center'}}>
                            {TitleIcon && (
                                <TitleIcon style={{width: 20, height: 20, verticalAlign: 'middle'}}/>
                            )}
                            {TitleIconObject && TitleIconObject}
                            <div style={{
                                color: 'black',
                                margin: 'auto 0px',
                                fontSize: 13,
                                fontWeight: 600,
                                lineHeight: '1.5rem',
                                letterSpacing: '-0.014rem',
                            }}>
                                {title}
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {this.renderContent()}
                    </DialogContent>
                    <DialogActions style={{
                        borderTop: '1px solid #dfdfdf',
                        padding: 8
                    }}>
                        <Button text="Cancel" onClick={this.onClose} variant="text" />
                        {this.renderActions()}
                    </DialogActions>
                </Dialog>
                {this.renderExtraDialogs()}
            </>
        );
    }

    protected renderContent(): React.JSX.Element {
        return <></>;
    }

    protected renderActions(): React.JSX.Element {
        return <></>;
    }

    protected renderExtraDialogs(): React.JSX.Element {
        return <></>;
    }

    protected onClose = () => {
        this.setState(this.initState());
        this.props.onClose();
    };

    protected initState(): BaseDialogState {
        return {};
    }
}
