import * as React from 'react';
import { default as moment, Moment } from 'moment';
import {
    colorMenus,
    DurationMenuType,
    getColorTitleByIndex,
    getDurationMenus,
    Menu,
    renderColorSelect,
} from '../../utils';
import {
    Button,
    Dropzone,
    AutomatePlayerAction,
    Calendar,
    Metadata,
    TextField,
} from '../common';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
    Grid,
    InputLabel,
    Select,
    Switch,
    MenuItem,
    Typography,
    Hidden, SelectChangeEvent,
} from '@mui/material';
import { MobileDatePicker as DatePicker, DateTimeValidationError, PickerChangeHandlerContext, MobileTimePicker as TimePicker } from '@mui/x-date-pickers';
import { EventType } from '../../enums';

export interface CreateLiveEventProps {
    readonly type: 'Create' | 'Edit';
    readonly eventType: EventType;
    readonly liveEvents: Entities.LiveEvent[];
    readonly oldLiveEvent?: Entities.LiveEvent;
}

type Step = 'Detail' | 'Schedule' | 'Rewards' | 'FinishActions' | 'Confirm';
export interface CreateLiveEventState {
    readonly step: Step;
    readonly name: string;
    readonly start: Moment;
    readonly end: Moment;
    readonly duration: number;
    readonly color: number;
    readonly timesMenus: Record<DurationMenuType, Menu>;
    readonly gameData: string;
    readonly developerOnly: boolean;
    readonly file: File | null;
    readonly finishActions: Entities.AutomaticPlayerAction[];
    readonly useDuration: boolean;
}

export class BaseCreateNewEventComponent<P extends CreateLiveEventProps = CreateLiveEventProps, S extends CreateLiveEventState = CreateLiveEventState> extends React.Component<P, S> {

    render() {
        return (
            <></>
        );
    }

    componentDidMount() {
        this.updateFromOld();
    }

    componentDidUpdate(prevProps: CreateLiveEventProps) {
        if (this.props.oldLiveEvent === prevProps.oldLiveEvent) {
            return;
        }
        this.updateFromOld();
    }

    protected updateFromOld() {
    }

    protected renderDetailContent(): React.JSX.Element {
        return <></>;
    }

    protected renderDetailButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onScheduleStepSet} variant="contained"/>
            </>
        );
    }

    protected renderScheduleContent(): React.JSX.Element {
        const { liveEvents, oldLiveEvent } = this.props;
        const {
            name,
            start,
            end,
            duration,
            color,
            useDuration,
        } = this.state;

        const events: any[] = liveEvents.filter(e => {
            if(!oldLiveEvent) {
                return true;
            }
            return  e.eventId !== oldLiveEvent.eventId;
        })
            .map(e => {
                    return {
                        id: e.eventId,
                        title: e.name,
                        start: new Date(e.startTimestamp),
                        end: new Date(e.endTimestamp),
                        backgroundColor: e.scheduleColor,
                        borderColor: e.scheduleColor,
                        textColor: 'white',
                        editable: false,
                    };
                }
            );
        events.push({
            title: name,
            start: new Date(start.valueOf()),
            end: useDuration ? new Date(start.clone().add(duration, 'minutes').valueOf()) : new Date(end.valueOf()),
            backgroundColor: getColorTitleByIndex(color),
            borderColor: 'white',
            textColor: 'white',
            editable: false,
        });

        return (
            <Grid container={true} spacing={2} >
                <Grid item={true} xs={12} sm={12} md={4}>
                    <Grid container={true} spacing={0} style={{marginTop: 10}}>
                        {this.renderTimerCreation()}
                        <Grid item={true} xs={12}>
                            <InputLabel>Color</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            {renderColorSelect(this.handleColorChange, color)}
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdDown={true}>
                    <Grid item={true} xs={12} sm={12} md={8}>
                        <Calendar
                            headerToolbar = {{
                                left: 'dayGridMonth,timeGridWeek,timeGridDay',
                                center: 'title',
                                right: 'prevYear,prev,next,nextYear'
                            }}
                            weekends={true}
                            height={'parent'}
                            contentHeight={'auto'}
                            handleWindowResize={true}
                            events={events}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    protected renderScheduleButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onRewardsStepSet} variant="contained"/>
            </>
        );
    }

    protected renderRewardsContent(): React.JSX.Element {
        return <></>;
    }

    protected renderRewardsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onScheduleStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onFinishActionsStepSet} variant="contained"/>
            </>
        );
    }

    protected renderFinishActionsContent(): React.JSX.Element {
        const { finishActions } = this.state;
        return (
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} >
                    <Typography variant="caption">
                        List here the actions that Nucleo will be automatically trigger at the moment to end the event.
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} >
                    <AutomatePlayerAction
                        actions={finishActions}
                        onUpdateActions={this.onUpdateActions}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderFinishActionsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onRewardsStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained"/>
            </>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        return <></>;
    }

    protected renderConfirmButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onFinishActionsStepSet} variant="contained" color="secondary" />
                <Button text="Confirm" onClick={this.onCreate} variant="contained"/>
            </>
        );
    }

    protected renderTimerCreation = () => {
        const { type } = this.props;
        const { start, end, duration, timesMenus, useDuration } = this.state;
        const durationViews: React.JSX.Element[] = [];
        Object.keys(timesMenus).forEach(key => {
            const durationMenuType = key as DurationMenuType;
            const {title, value} = timesMenus[durationMenuType];
            durationViews.push(
                <MenuItem key={key} value={value}>
                    {title}
                </MenuItem>
            );
        });
        return (
            <>
                <Grid item={true} xs={12}>
                    <InputLabel>Start Date</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <DatePicker
                        value={start}
                        onChange={this.handleStartDateChange}
                        disablePast={type !== 'Edit'}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Start Time</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TimePicker
                        label=""
                        value={start}
                        onChange={this.handleStartDateChange}
                        ampm={false}
                    />
                </Grid>
                {useDuration && (
                    <>
                        <Grid item={true} xs={12}>
                            <InputLabel>Duration</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Select
                                value={duration}
                                onChange={this.handleDurationChange}
                                style={{ width: '100%', backgroundColor: 'white' }}
                                variant={'outlined'}
                            >
                                {durationViews}
                            </Select>
                        </Grid>
                    </>
                )}
                {!useDuration && (
                    <>
                        <Grid item={true} xs={12}>
                            <InputLabel>End Date</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <DatePicker
                                value={end}
                                onChange={this.handleEndDateChange}
                                disablePast={type !== 'Edit'}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <InputLabel>End Time</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TimePicker
                                label=""
                                value={end}
                                onChange={this.handleEndDateChange}
                                ampm={false}
                            />
                        </Grid>
                    </>
                )}
                <Grid item={true} xs={12}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Set Time</Typography>
                        <Switch
                            checked={useDuration}
                            onChange={this.endTimeModeChange}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Duration</Typography>
                    </div>
                </Grid>
            </>
        );
    };

    protected onCreate = () => {
    }

    protected initState(): CreateLiveEventState {
        const timesMenus = getDurationMenus();

        return {
            step: 'Detail',
            name: '',
            start: moment.parseZone(),
            end: moment.parseZone().add(1, 'day'),
            duration: timesMenus['1day'].value,
            color: colorMenus.BlueViolet.value,
            timesMenus,
            gameData: '',
            file: null,
            developerOnly: false,
            finishActions: [],
            useDuration: true,
        };
    }

    protected handleColorChange = (event: SelectChangeEvent<number>) => this.setState({ color: event.target.value as number });

    private handleDurationChange = (event: SelectChangeEvent<number>) => this.setState({ duration: event.target.value as number });

    protected handleStartDateChange = (start: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (start === null) {
            return;
        }
        this.setState({ start });
    }

    protected handleEndDateChange = (end: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (end === null) {
            return;
        }
        this.setState({ end });
    }

    protected endTimeModeChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ useDuration: event.target.checked });

    protected handleGameDataChange = (gameData: string) => this.setState({ gameData });

    protected handleDrop = (file: File) => this.setState({file});

    protected handleDeveloperOnlyChange = (developerOnly: boolean) => this.setState({developerOnly});

    protected renderNameField = () => {
        const { name } = this.state;
        return (
            <>
                <Grid item={true} xs={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        label=""
                        value={name}
                        onChange={this.updateName}
                        fullWidth={true}
                        required={true}
                        variant="outlined"
                    />
                </Grid>
            </>
        );
    };

    protected renderImageField = () => {
        return (
            <>
                <Grid item={true} xs={12}>
                    <InputLabel>Image</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Dropzone
                        accept={{
                            "image/jpeg": ['.jpeg'],
                            "image/jpg": ['.jpg'],
                            "image/png": ['.png'],
                        }}
                        text={'No file chosen'}
                        description={'Upload image (200x200px - JPEG, JPG or PNG)'}
                        onDropFile={this.handleDrop}
                    />
                </Grid>
            </>
        );
    };

    protected renderGameDataField = (readOnly: boolean = false) => {
        const { gameData } = this.state;
        return (
            <>
                <Grid item={true} xs={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Metadata
                        text={gameData}
                        readOnly={readOnly}
                        onChange={this.handleGameDataChange}
                    />
                </Grid>
            </>
        );
    };

    protected updateName = (name: string) => this.setState({ name });

    protected onUpdateActions = (finishActions: Entities.AutomaticPlayerAction[]) => this.setState({finishActions});

    protected onDetailStepSet = () => this.setState({ step: 'Detail'});

    protected onRewardsStepSet = () => this.setState({ step: 'Rewards'});

    protected onScheduleStepSet = () => this.setState({ step: 'Schedule'});

    protected onFinishActionsStepSet = () => this.setState({ step: 'FinishActions'});

    protected onConfirmStepSet = () => this.setState({ step: 'Confirm'});
}
