import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { ContainerContext, mapProps } from './';
import { formatDate, getCloudCodeRevisionLink } from '../utils';
import {
    AlertDialog,
    LabelCheckBox,
    Dropzone,
    Table,
    BaseElement,
    BaseContainer,
    HeaderButton,
} from '../components';
import {
    AddOutlined,
    CheckCircleOutline,
    CheckCircleOutlineOutlined,
    Code as CodeIcon,
} from '@mui/icons-material';
import { UserTitleRole } from '../enums';
import { Chip, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Upload';
interface State {
    readonly openDialog: DialogType | null;
    readonly file: File | null;
    readonly isLive: boolean;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        file: null,
        isLive: false,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openUploadDialog}
        ];
        return (
            <BaseContainer
                {...this.props}
                title = "Cloud Code"
                TitleIcon = {CodeIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderUploadDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Details', field: 'details'},
        ];

        const onRowClick = (event: any, rowData: any) => this.toLink(`/cloudCode/${rowData.revision}`);

        return (
            <Table
                columns={columns}
                data={this.getData}
                onRowClick={onRowClick}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: false,
                    sorting: false,
                    draggable: false,
                    emptyRowsWhenPaging: false,
                    rowStyle: ((rowData, index, level) => {
                        if (rowData.live) {
                            return {
                                backgroundColor: '#f1faff'
                            };
                        }
                        return {
                            backgroundColor: 'transparent',
                        };
                    }),
                    actionsColumnIndex: -1
                }}
                actions={[
                    rowData => ({
                        icon: CheckCircleOutlineOutlined,
                        tooltip: 'Set Default',
                        iconProps: {
                            color: 'primary',
                        },
                        hidden: userTitleRole === UserTitleRole.Viewer || rowData.live,
                        onClick: () => this.activate(rowData.revision)
                    }),
                ]}
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`cloudCode/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.CloudCode) => {
            return {
                revision: e.revision,
                details: this.getDetails(e),
                created: formatDate(e.created),
                live: e.live,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getDetails = (script: Entities.CloudCode) => {
        return (
            <Grid container={true} justifyContent="center" spacing={0}>
                {script.live && (
                    <Grid item={true} xs={12}>
                        <Chip
                            color={'secondary'}
                            icon={<CheckCircleOutline />}
                            size='small'
                            label='Default'
                            style={{
                                width: 120,
                                backgroundColor: '#2c3e50',
                                color: 'white',
                                margin: '2px 0px',
                                borderRadius: 5,
                            }}
                        />
                    </Grid>
                )}
                <Grid item={true} xs={12}>
                    Revision: {getCloudCodeRevisionLink(script.revision)}
                </Grid>
                <Grid item={true} xs={12}>
                    Created: {formatDate(script.created)}
                </Grid>
            </Grid>
        );
    };

    private activate = async (revision: number) => {
        await actions.cloudCode.activate(revision);
    };

    private openUploadDialog = () => this.setState({ openDialog: 'Upload' });
    private updateIsLive = (isLive: boolean) => this.setState({ isLive });

    private closeDialog = () => this.setState({
        openDialog: null,
        file: null,
        isLive: false,
    });

    private uploadCloudCode = async () => {
        const { file, isLive } = this.state;
        this.closeDialog();
        this.setState({ file: null });
        await actions.cloudCode.upload(file, isLive);
    };

    private handleDrop = (file: File) => this.setState({ file });

    private renderUploadDialog = () => {
        const { openDialog, file, isLive } = this.state;
        return (
            <AlertDialog
                open={openDialog === 'Upload'}
                title="New Cloud Code Revision"
                TitleIcon = {CodeIcon}
                content="Upload a new cloud code revision from javascript file."
                onClose={this.closeDialog}
                submitButtonText={'Upload'}
                onSubmit={this.uploadCloudCode}
                disabledSubmitButton={!file}
            >
                <Dropzone
                    accept={{
                        "text/javascript": ['.js'],
                    }}
                    text={'Drop cloud code file.'}
                    height={100}
                    onDropFile={this.handleDrop}
                />
                <LabelCheckBox
                    label="Make live after upload"
                    checked={isLive}
                    onChange={this.updateIsLive}
                    readOnly={false}
                    labelPlacement={'end'}
                />
            </AlertDialog>
        );
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
