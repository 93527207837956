import { Action } from './index';
import { TitleActionType, SegmentActionType, AppActionType } from '../enums';

export interface SegmentState {
    readonly segments: Entities.Segment[];
}

export type SegmentAction = Action<Partial<SegmentState>>;

const initState: SegmentState = {
    segments: [],
};

const reducer = (
    state: SegmentState = initState,
    action: SegmentAction,
): SegmentState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case SegmentActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
