import * as React from 'react';
import { Provider } from 'react-redux';

import store from './reducers';
import AppContainer from './containers';

export default () => (
    <Provider store={store}>
        <AppContainer />
    </Provider>
);
