import AppContainer from './AppContainer';
import { Location } from 'history';
import { UserRole, UserTitleRole } from '../enums';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { AppState, StateMap } from '../reducers';

export default AppContainer;

export declare namespace ContainerContext {
    interface Props {
        readonly app: AppState;
        readonly loginUser: Entities.User | null | undefined;
        readonly titles?: Entities.UserTitleData[];
        readonly titleSelected?: Entities.UserTitleData;
        readonly loading: boolean;
        readonly titleId: string;
        readonly userRole: UserRole;
        readonly userTitleRole: UserTitleRole;
        readonly navigate?: NavigateFunction;
        readonly location?: Location;
    }
}
export const mapProps = (state: StateMap): ContainerContext.Props => ({
    app: state.app,
    loginUser: state.user.loginUser,
    titles: state.title.titles,
    titleSelected: state.title.selectedTitle,
    loading: state.app.loading,
    titleId: state.title.selectedTitle ? state.title.selectedTitle.id : '',
    userRole: state.user.loginUser ? state.user.loginUser.role : UserRole.NoRole,
    userTitleRole: state.title.selectedTitle ? state.title.selectedTitle.userTitleRole : UserTitleRole.NoRole,
});
