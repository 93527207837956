import axios, { AxiosRequestConfig } from 'axios';
import { LocalStorage } from './localStorage';
import { getTitleId } from '../reducers';
import config from '../config';
import { co } from '@fullcalendar/core/internal-common';

export class Request {
    static async get<T = any>(url: string, params?: any, opts?: any, checkTitleId: boolean = true): Promise<T | null> {
        const conf = {
            method: 'GET',
            params,
            ...opts,
        };
        return this.request<T>(url, conf, checkTitleId);
    }

    static async post<T = any>(url: string, data?: any, opts?: any, checkTitleId: boolean = true): Promise<T | null> {
        const conf = {
            method: 'POST',
            data,
            ...opts,
        };
        return this.request<T>(url, conf, checkTitleId);
    }

    static async postForm<T = any>(url: string, formData?: FormData, checkTitleId: boolean = true): Promise<T | null> {
        const conf = {
            method: 'POST',
            data: formData,
        };
        return this.request<T>(url, conf, checkTitleId, 'multipart/form-data');
    }

    private static async request<T>(url: string, requestConfig: AxiosRequestConfig, checkTitleId: boolean = true, contentType: string = 'application/json'): Promise<T | null> {
        if (checkTitleId) {
            const titleId = getTitleId();
            if (titleId !== '') {
                url = `${titleId}/${url}`;
            }
        }
        url = this.getFullUrl(url);

        const { sessionId } = LocalStorage.get().auth;
        const { data } = await axios({
            url,
            ...requestConfig,
            headers: {
                'admin-session': sessionId,
                'Content-Type': contentType,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                'Access-Control-Allow-Headers': 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
            },
        });
        return data;
    }

    private static getFullUrl(url: string): string {
        const local = location.host.includes('localhost:3000');
        const apiUrl = config.apiUrl;
        if (local || apiUrl === undefined || apiUrl === "") {
            return `http://localhost:8080/${url}`;
        } else {
            return `${apiUrl}/${url}`;
        }
    }
}
