import * as React from 'react';
import { connect } from 'react-redux';
import { BaseAnalyzePlayers, BaseElement, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { ActionRequest } from '../actions';
import { formatNumber, getPlayerLink } from '../utils';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

class Container extends BaseElement<Props> {
    protected renderContainer(): React.JSX.Element {
        return (
            <BaseAnalyzePlayers {...this.props} >
                {this.renderContent()}
            </BaseAnalyzePlayers>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Player Id', field: 'playerId'},
            { title: 'Name', field: 'name'},
            { title: 'Version', field: 'version'},
            { title: 'Value', field: 'value'},
        ];

        return (
            <Table
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                    showTitle: false,
                }}
                data={this.getData}
            />
        );
    };

    private getData = async (query: any) => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(`analyze/players/stats`, {
            filter: search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.data.map((d: any) => {
            return {
                playerId: getPlayerLink(d.playerId),
                name: d.name,
                version: d.version,
                value: formatNumber(d.value),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
