import * as React from 'react';
import { CommonHeader, CommonHeaderProps } from './header';
import { Loading } from './index';
import { Tabs, Tab, Grid, Divider } from '@mui/material';
import * as _ from 'lodash';
import { styled } from '@mui/system';
import { PropsWithChildren } from 'react';

interface TabProps {
    readonly titles: string[];
    readonly selected: number;
    readonly onChange: (tab: number) => void;
}

interface Props extends CommonHeaderProps, PropsWithChildren {
    readonly loading?: boolean;
    readonly mainTabs?: TabProps;
    readonly subTabs?: TabProps;
}

export class BaseContainer extends React.Component<Props> {
    render() {
        const { props } = this;
        const { title, TitleIcon, headline, headlines, onRefresh, subTabs } = props;

        const loading = props.loading;
        const buttons = loading ? undefined : props.buttons;
        const children = loading ? undefined : props.children;

        return (
            <Grid container={true} justifyContent="center" spacing={0}>
                <Grid item={true} xs={12}>
                    {this.renderMainTabs()}
                </Grid>
                <Grid item={true} xs={12}>
                    <Grid container={true} justifyContent="center" spacing={0}>
                        <Grid item={true} xs={12} style={{ flexGrow: 0, flexShrink: 0 }}>
                            <CommonHeader
                                title={title}
                                TitleIcon={TitleIcon}
                                headline={headline}
                                headlines={headlines}
                                onRefresh={onRefresh}
                                buttons={buttons}
                            />
                        </Grid>
                        {subTabs !== undefined && (
                            <Grid item={true} xs={12} style={{ padding: '2px 0px' }}>
                                {this.renderSubTabs()}
                            </Grid>
                        )}
                        {(title !== undefined || headline !== undefined || headlines !== undefined || buttons !== undefined) && subTabs === undefined && (
                            <Grid item={true} xs={12} style={{ padding: '5px 0px' }}>
                                <Divider style={{borderColor: '#d8dee4' }}/>
                            </Grid>
                        )}
                        {loading && (
                            <div style={{ marginTop: 50 }}>
                                <Loading />
                            </div>
                        )}
                        {!loading && (
                            <Grid item={true} xs={12} style={{ padding: '10px 0px 30px 0px' }}>
                                {children}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private renderMainTabs = () => {
        const { mainTabs } = this.props;
        if(!mainTabs) {
            return <></>;
        }
        return (
            <MainTabs
                value={mainTabs.selected}
                onChange={this.changeMainTab}
                indicatorColor="primary"
                textColor="primary"
            >
                {_.map(mainTabs.titles, (name, index) => (
                    <MainTab
                        key={`tab_${index}`}
                        disableRipple={true}
                        label={name}
                        value={index}
                    />
                ))}
            </MainTabs>
        );
    };

    private changeMainTab = (event: any, value: any) => {
        if (this.props.mainTabs) {
            this.props.mainTabs.onChange(value);
        }
    };

    private renderSubTabs = () => {
        const { subTabs } = this.props;
        if(!subTabs) {
            return;
        }
        return (
            <SubTabs
                value={subTabs.selected}
                onChange={this.changeSubTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                allowScrollButtonsMobile={true}
                scrollButtons="auto"
            >
                {_.map(subTabs.titles, (name, index) => (
                    <SubTab
                        key={`tab_${index}`}
                        disableRipple={true}
                        label={name}
                        value={index}
                    />
                ))}
            </SubTabs>
        );
    };

    private changeSubTab = (event: any, value: any) => {
        if (this.props.subTabs) {
            this.props.subTabs.onChange(value);
        }
    };
}

const MainTabs = styled(Tabs)(({
    '&.MuiTabs-root': {
        backgroundColor: '#dfdfdf',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
    },
    '& .MuiTabs-indicator': {
        display: 'contents',
        backgroundColor: 'white',
    },
}));

const MainTab = styled(Tab)(({
    '&.MuiTab-root': {
        fontSize: '0.8rem',
        color: 'gray',
        minWidth: 0,
        margin: 0,
        padding: '0px 25px',
        textTransform: 'none',
        '&:hover': {
            color: '#0073bb',
        },
        '&$selected': {
            color: '#0073bb',
        },
        '&:focus': {
            color: '#0073bb',
        },
    },
    '& .Mui-selected': {
        backgroundColor: 'white',
    },
}));


const SubTabs = styled(Tabs)(({ theme }) => ({
    '&.MuiTabs-root': {
        minHeight: 30,
        margin: 0,
        borderBottom: '1px solid rgb(216, 222, 228)',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginTop: 2,
            borderTop: '1px solid rgb(216, 222, 228)',
        },
    },
    '& .MuiTabs-flexContainer': {
        display: 'box',
    },
    '& .MuiTabs-indicator': {
        display: 'contents',
        backgroundColor: '#1967d2',
    },
    '& .MuiTabs-scrollButtons': {
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));

const SubTab = styled(Tab)(({
    '&.MuiTab-root': {
        padding: '4px 16px',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 600,
        minWidth: 0,
        margin: 0,
        textTransform: 'none',
        minHeight: 30,
        letterSpacing: '.25px',
        '&:hover': {
            backgroundColor: '#eef2f8',
        },
        '&$selected': {
            backgroundColor: '#eef2f8',
        },
        '&:focus': {
            backgroundColor: '#eef2f8',
        },
    },
    '&.Mui-selected': {
        color: '#1967d2',
        fontWeight: 'bold',
        borderBottom: '2px solid #1967d2',
    },
}));
