import React from 'react';
import ReactDropzone, { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';
import { Typography, CardMedia, Grid } from '@mui/material';

interface Props extends DropzoneProps {
    readonly text: string;
    readonly preview?: string;
    readonly description?: string;
    readonly width?: string | number;
    readonly height?: string | number;
    readonly borderStyle?: string;
    readonly onDropFile: (file: File) => void;
}

interface State {
    readonly preview: string;
    readonly file: File | null;
}

export class Dropzone extends React.Component<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.preview && this.props.preview !== prevProps.preview) {
            this.setState(this.initState());
        }
    }

    render() {
        const { text, description, width, height, borderStyle } = this.props;
        const { file, preview } = this.state;

        return (
            <Grid container={true} spacing={0}>
                {description && (
                    <Grid item={true} xs={12}>
                        <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.54)', margin: '2px 0px', fontSize: 10 }}>
                            {description}
                        </Typography>
                    </Grid>
                )}
                <Grid item={true} xs={12}>
                    <ReactDropzone {...this.props} onDrop={this.handleImageDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}
                                 style={{
                                     width: width ? width : '100%',
                                     height: height ? height : 20,
                                     borderStyle: borderStyle ? borderStyle : 'solid',
                                     padding: '4px 0px',
                                     backgroundColor: 'white',
                                     textAlign: 'center',
                                     borderWidth: 1,
                                     borderRadius: 0,
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                                     border: 'none',
                                 }}
                            >
                                <input {...getInputProps()} />
                                <Typography variant="subtitle2" style={{ fontSize: 11 }}>
                                    { file ? file.name : text }
                                </Typography>
                            </div>
                        )}
                    </ReactDropzone>
                </Grid>
                {preview !== '' && (
                    <Grid item={true} xs={12}>
                        <CardMedia image={preview} style={{height: 300, margin: 10, width: '100%'}}/>
                    </Grid>
                )}
            </Grid>
        );
    }

    private handleImageDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        const { onDropFile } = this.props;
        const file = acceptedFiles[0];
        if(file.type.includes('image')) {
            this.setState({ preview: URL.createObjectURL(file)});
        }
        this.setState({ file });
        onDropFile(file);
    };

    private initState(): State {
        const { preview } = this.props;
        if (preview) {
            return {
                preview,
                file: null,
            };
        }

        return {
            preview: '',
            file: null,
        };
    }
}
export default Dropzone;
