import { TitleActionType, UserTitleRole } from '../enums';
import { Request, LocalStorage } from '../utils';
import { dispatch, getState, TitleState } from '../reducers';

export const getTitle = () => {
    const { title } = LocalStorage.get();
    dispatch({
        type: TitleActionType.Update,
        payload: { selectedTitle: title },
    });
};

export const setTitle = (title: Entities.UserTitleData) => {
    LocalStorage.set('title', title);
    dispatch({
        type: TitleActionType.Update,
        payload: { selectedTitle: title },
    });
};

export const resetTitle = () => {
    const title: Entities.UserTitleData = {
        id: '',
        name: '',
        created: 0,
        updated: 0,
        imageUrl: '',
        organization: '',
        apiUrl: '',
        apiSecretKey: '',
        userTitleRole: 0,
    };
    LocalStorage.set('title', title);
    dispatch({ type: TitleActionType.Reset });
};

export const list = async () => {
    const titles: Entities.UserTitleData[] | null = await Request.get('title/list', undefined, undefined, false);
    if (!titles) {
        return;
    }
    dispatch({
        type: TitleActionType.Update,
        payload: { titles },
    });
};

export const create = async (name: string, apiUrl: string, apiSecretKey: string) => {
    const title: Entities.UserTitleData | null= await Request.post('title/create', {name, apiUrl, apiSecretKey}, undefined, false);
    if (!title) {
        return;
    }
    const state: TitleState = getState().title;
    const oldTitle = state.titles ? state.titles : [];
    const titles = [...oldTitle , title];
    dispatch({
        type: TitleActionType.Update,
        payload: { titles },
    });
};

export const update = async (titleId: string, name: string, apiUrl: string, apiSecretKey: string, file: any) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("apiUrl", apiUrl);
    formData.append("apiSecretKey", apiSecretKey);
    formData.append("file", file);
    const title = await Request.postForm(`title/${titleId}/update`, formData, false);
    const state: TitleState = getState().title;
    const titles: Entities.UserTitleData[] = state.titles ? state.titles.map((t: Entities.UserTitleData) => (t.id === titleId) ? title : t) : [title];
    dispatch({
        type: TitleActionType.Update,
        payload: { titles },
    });
};

export const deleteTitle = async(titleId: string) => {
    await Request.post(`title/${titleId}/delete`, undefined, undefined, false);
    resetTitle();
};

export const getConfig = async () => {
    const config = await Request.get(`titleConfig/get`);
    dispatch({
        type: TitleActionType.Update,
        payload: config,
    });
};

export const listUsers = async (titleId: string) => {
    const users = await Request.get(`title/${titleId}/listUsers`, undefined, undefined, false);
    dispatch({
        type: TitleActionType.Update,
        payload: { users },
    });
};

export const addTitleUser = async (titleId: string, username: string, role: UserTitleRole) => {
    const user = await Request.post(`title/${titleId}/addUser`, { username, role }, undefined, false);
    const state = getState().title;
    const users = state.users ? [...state.users, user] : [user];
    dispatch({
        type: TitleActionType.Update,
        payload: { users },
    });
};

export const deleteTitleUser = async (titleId: string, username: string) => {
    await Request.post(`title/${titleId}/deleteUser`, { username }, undefined, false);
    const state = getState().title;
    const users = state.users ? state.users.filter(u => u.username !== username) : [];
    dispatch({
        type: TitleActionType.Update,
        payload: { users },
    });
};

export const editTitleUser = async (titleId: string, username: string, role: UserTitleRole) => {
    await Request.post(`title/${titleId}/editUser`, { username, role }, undefined, false);
    const state = getState().title;
    const users = state.users ? state.users.map(u => u.username === username ? { ...u, userTitleRole: role } : u) : [];
    dispatch({
        type: TitleActionType.Update,
        payload: { users },
    });
};
