import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import { BaseContainer, BaseElement, Table } from '../components';
import actions from '../actions';
import {
    CardGiftcard as CardGiftcardIcon,
    DeleteOutlineOutlined,
    ExpandMore,
} from '@mui/icons-material';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Grid } from '@mui/material';
import {
    formatDate,
    getPlayerLink,
    isActive,
    isEnded,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../utils';
import { UserTitleRole } from '../enums';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly campaignId: string;
    readonly details?: {
        readonly campaign: Entities.PromoCampaign;
        readonly codes: Entities.PromoCampaignCode[];
        readonly rewards: Entities.PromoCampaignReward[];
        readonly players: Entities.PlayerPromoCampaign[];
    };
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        const { campaignId } = this.props;
        await actions.promoCampaign.get(campaignId);
    }

    protected renderContainer(): React.JSX.Element {
        const { campaignId } = this.props;
        const headlines = [
            {
                text: 'Promo Campaigns',
                to: `/marketing/promoCampaigns`,
            },
            {
                text: campaignId,
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                title = {"Details Promo Campaign"}
                TitleIcon = {CardGiftcardIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid item={true} xs={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid item={true} xs={12}>
                    {this.renderCodes()}
                </Grid>
                <Grid item={true} xs={12}>
                    {this.renderRewards()}
                </Grid>
                <Grid item={true} xs={12}>
                    {this.renderPlayers()}
                </Grid>
            </Grid>
        );
    }

    private renderDetails = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ backgroundColor: 'white' }}>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid item={true} xs={12} sm={3}>
                            <strong>Status</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {this.getStatus(details.campaign)}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {details.campaign.name}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>Start at</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {formatDate(details.campaign.start)}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>End at</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {formatDate(details.campaign.end)}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>Edited by</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {details.campaign.username}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>Edited at</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {formatDate(details.campaign.lastUpdated)}
                        </Grid>
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    }

    private renderCodes = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }
        if (details.codes.length === 0) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Codes
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ backgroundColor: 'white' }}>
                    <Grid container={true} direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        {details.codes.map(code => (
                            // tslint:disable-next-line:jsx-key
                            <Grid justifyContent="center" alignItems="center" item={true} xs={12} sm={4} md={3} style={{ textAlign: 'center'}}>
                                <strong>{code.key}</strong>
                            </Grid>
                        ))}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    }

    private renderRewards = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }
        if (details.rewards.length === 0) {
            return <></>;
        }

        const columns = [
            { title: 'Key', field: 'key'},
            { title: 'Amount', field: 'amount'},
        ];
        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Rewards
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ backgroundColor: 'white', padding: 0 }}>
                    <Table
                        showBox={false}
                        columns={columns}
                        options={{
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: false,
                            draggable: false,
                            showTitle: false,
                        }}
                        data={details.rewards}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    }

    private renderPlayers = () => {
        const { details, campaignId, userTitleRole } = this.props;
        if (!details) {
            return <></>;
        }
        if (details.players.length === 0) {
            return <></>;
        }

        const columns = [
            { title: 'Player Id', field: 'playerId', render: (player: Entities.PlayerPromoCampaign) => getPlayerLink(player.playerId) },
            { title: 'Code', field: 'code' },
            { title: 'Collected', field: 'collected', render: (player: Entities.PlayerPromoCampaign) => formatDate(player.collected) },
        ];
        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Players
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ backgroundColor: 'white', padding: 0 }}>
                    <Table
                        showBox={false}
                        columns={columns}
                        options={{
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: false,
                            draggable: false,
                            showTitle: false,
                            actionsColumnIndex: -1,
                            exportButton: true,
                            exportAllData: true,
                            exportFileName: `promo_${campaignId}_players`,
                        }}
                        data={details.players}
                        actions = {
                            userTitleRole > UserTitleRole.Viewer ?
                                [
                                    rowData => ({
                                        icon: DeleteOutlineOutlined,
                                        tooltip: 'Delete',
                                        iconProps: {
                                            color: 'primary',
                                        },
                                        onClick: async () => {
                                            await actions.promoCampaign.removePlayer(campaignId, rowData.playerId);
                                        }
                                    }),
                                ]
                                : undefined
                        }
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    }

    private getStatus = (promoCampaign: Entities.PromoCampaign) => {
        if (isActive(promoCampaign.start, promoCampaign.end)) {
            return <div style={{color: 'green'}}>In Progress</div>;
        }
        if (isEnded(promoCampaign.end)) {
            return <div style={{color: 'orange'}}>Completed</div>;
        }
        return <div style={{color: 'grey'}}>Scheduled</div>;
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    campaignId: '',
    details: state.promoCampaign.details,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const campaignId = params.campaignId || '';
    return (<Container {...props} navigate={navigate} campaignId={campaignId}/>);
};
export default connect(mapStateToProps)(AppContainer);
