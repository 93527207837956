import { Action } from './index';
import { AppActionType, PromoCampaignActionType, TitleActionType } from '../enums';

export interface PromoCampaignState {
    readonly promoCampaigns: Entities.PromoCampaign[];
    readonly details?: {
        campaign: Entities.PromoCampaign;
        codes: Entities.PromoCampaignCode[];
        rewards: Entities.PromoCampaignReward[];
        players: Entities.PlayerPromoCampaign[];
    };
}

export type PromoCampaignAction = Action<Partial<PromoCampaignState>>;

const initState: PromoCampaignState = {
    promoCampaigns: [],
};

const reducer = (
    state: PromoCampaignState = initState,
    action: PromoCampaignAction,
): PromoCampaignState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case PromoCampaignActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
