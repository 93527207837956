import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseCloudBuildContainer,
    Button,
    Table,
    CloudBuildFilter,
    StartBuildDialog,
    BaseElement,
    HeaderButton,
} from '../components';
import { UserTitleRole } from '../enums';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { formatDate, formatDuration } from '../utils';
import {
    CheckCircle,
    Error as ErrorIcon,
    RemoveCircle,
    PlayArrow,
    MoreHoriz,
    Cancel as CancelIcon,
    GetApp as GetAppIcon,
    PlayArrowOutlined,
    CheckCircleOutlined,
    ErrorOutlineOutlined,
    RemoveCircleOutlineOutlined,
} from '@mui/icons-material';
import { Chip, CircularProgress, Grid, IconButton, Menu, MenuProps, MenuItem } from '@mui/material';
import { default as moment } from 'moment';
import * as _ from 'lodash';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router';
import { Column } from 'material-table';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    jobs: Entities.CloudBuildJob[];
}

type DialogType = 'Build';
interface State {
    readonly openDialog: DialogType | null;
    readonly authAnchorEl: HTMLElement | undefined;
    readonly selectedJob: string;
    readonly selectedDetails: Entities.CloudBuildJobExecution | null;
    readonly successFilter: boolean;
    readonly buildingFilter: boolean;
    readonly canceledFilter: boolean;
    readonly failedFilter: boolean;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        authAnchorEl: undefined,
        openDialog: null,
        selectedJob: '',
        selectedDetails: null,
        successFilter: false,
        buildingFilter: false,
        canceledFilter: false,
        failedFilter: false,
    };

    private tableRef: any;
    private timer: NodeJS.Timeout | null = null;

    async componentDidMount() {
        await actions.devOps.getCloudBuildDetails();
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected renderContainer(): React.JSX.Element {
        const { jobs } = this.props;
        let buttons: HeaderButton[] | undefined;
        if (this.props.userTitleRole > UserTitleRole.Viewer) {
            buttons = [];
            if (jobs.length > 0) {
                buttons.push({ text: 'Build', onClick: this.openBuildDialog});
            }
        }
        return (
            <BaseCloudBuildContainer
                {...this.props}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseCloudBuildContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderBuildDialog()}
                {this.renderBuildDetailsMenu()}
            </>
        );
    }

    private renderContent = () => {
        const { jobs } = this.props;
        const { selectedJob, successFilter, buildingFilter, canceledFilter, failedFilter } = this.state;
        const columns: Column<any>[] = [
            { title: 'Status & Build #', field: 'details'},
            { title: 'Build target', field: 'target'},
            { title: 'Build time', field: 'duration', render: rowData => formatDuration(rowData.duration)},
            { title: '', field: 'actions', cellStyle: { textAlign: 'right' }},
        ];

        this.tableRef = React.createRef();

        const onFilter = (job: string, success: boolean, building: boolean, canceled: boolean, failed: boolean) => {
            this.setState({
                selectedJob: job,
                successFilter: success,
                buildingFilter: building,
                canceledFilter: canceled,
                failedFilter: failed,
            });
            this.onSearch();
        };

        const onResetSelectedJob = () => {
            this.setState({ selectedJob: '' });
            this.onSearch();
        };

        const onResetSuccess = () => {
            this.setState({ successFilter: false });
            this.onSearch();
        };

        const onResetBuilding = () => {
            this.setState({ buildingFilter: false });
            this.onSearch();
        };

        const onResetCanceled = () => {
            this.setState({ canceledFilter: false });
            this.onSearch();
        };

        const onResetFailed = () => {
            this.setState({ failedFilter: false });
            this.onSearch();
        };

        const showChips = selectedJob !== '' || successFilter || buildingFilter || canceledFilter || failedFilter;
        const title =  (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                    <CloudBuildFilter
                        jobs={jobs.map(j => j.name)}
                        selectedJob={selectedJob}
                        successFilter={successFilter}
                        buildingFilter={buildingFilter}
                        canceledFilter={canceledFilter}
                        failedFilter={failedFilter}
                        onFilter={onFilter}
                    />
                </Grid>
                {showChips &&
                    <Grid item={true} xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gridColumnGap: 5, gridGap: 5}} >
                            {selectedJob !== '' && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label={`Target: "${selectedJob}"`}
                                    onDelete={onResetSelectedJob}
                                />
                            )}
                            {successFilter && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<CheckCircleOutlined style={{ color: 'white', width: 24, height: 24 }}/>}
                                    label='Status: "Success"'
                                    onDelete={onResetSuccess}
                                />
                            )}
                            {buildingFilter && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<PlayArrowOutlined style={{ color: 'white', width: 24, height: 24 }}/>}
                                    label='Status: "Building"'
                                    onDelete={onResetBuilding}
                                />
                            )}
                            {canceledFilter && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<RemoveCircleOutlineOutlined style={{ color: 'white', width: 24, height: 24}}/>}
                                    label='Status: "Canceled"'
                                    onDelete={onResetCanceled}
                                />
                            )}
                            {failedFilter && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<ErrorOutlineOutlined style={{ color: 'white', width: 24, height: 24 }}/>}
                                    label='Status: "Failed"'
                                    onDelete={onResetFailed}
                                />
                            )}
                        </div>
                    </Grid>
                }
            </Grid>
        );

        return (
            <Table
                tableRef={this.tableRef}
                refreshSeconds={60}
                title={title}
                onRefresh={this.onRefreshData}
                data={this.getExecutionHistory}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: false,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                }}
                detailPanel={[
                    rowData => ({
                        disabled: rowData.job.result === 'Queue',
                        render: this.getDetailPanel,
                    })
                ]}
            />
        );
    };

    private onSearch = () => {
        // @ts-ignore
        this.tableRef.current.onSearchChangeDebounce();
    };

    private getExecutionHistory = async (query: any) => {
        const { page, pageSize, orderBy, orderDirection } = query;
        const { selectedJob, successFilter, buildingFilter, canceledFilter, failedFilter } = this.state;
        const result = await ActionRequest.get(
            `devOps/cloudBuild/getExecutionHistory`,
            {
                page: page + 1,
                perPage: pageSize,
                orderBy: orderBy ? orderBy.field : null,
                orderDirection: orderDirection !== "" ? orderDirection : null,
                selectedJob,
                successFilter,
                buildingFilter,
                canceledFilter,
                failedFilter,
            },
        );

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.jobs.map( (job: any) => {
            return {
                job,
                details: this.getDetails(job),
                target: job.target,
                duration: job.duration > 0 ? job.duration : moment.now() - job.timestamp,
                actions: this.getActions(job),
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getDetailPanel = (rowData: any) => {
        const job: Entities.CloudBuildJobExecution = rowData.job;
        return (
            <div style={{padding: '10px 20px'}}>
                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Result:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {job.result}
                    </Grid>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Build target:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {job.target}
                    </Grid>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Started:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {formatDate(job.timestamp)}
                    </Grid>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Build time:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {job.duration > 0 ? moment.duration(job.duration).humanize() : moment.duration(moment.now() - job.timestamp).humanize()}
                    </Grid>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Branch:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {job.branch}
                    </Grid>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Last commit:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {job.commit}
                    </Grid>
                    <Grid item={true} xs={12} sm={3} style={{ fontWeight: 600 }}>
                        Clean build:
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        {job.clean ? 'TRUE' : 'FALSE'}
                    </Grid>
                </Grid>
            </div>
        );
    }

    private getDetails = (details: Entities.CloudBuildJobExecution) => {
        let icon = <div style={{ width: 24, height: 24 }}>
            <CircularProgress style={{ color: '#0073bb', width: 24, height: 24 }} />
            <PlayArrow style={{ position: 'relative', color: '#0073bb', left: 4, top: -25, width: 16, height: 16 }}/>
        </div>;
        switch (details.result) {
            case 'Queue':
                icon = <CircularProgress style={{ color: '#9e9e9e', width: 24, height: 24 }} />;
                break;
            case 'Success':
                icon = <CheckCircle style={{ color: '#4eb709', width: 24, height: 24 }}/>;
                break;
            case 'Failed':
                icon = <ErrorIcon style={{ color: '#d20f08', width: 24, height: 24 }}/>;
                break;
            case 'Canceled':
                icon = <RemoveCircle style={{ color: '#e39f28', width: 24, height: 24}}/>;
                break;

        }
        return (
            <div style={{ display: 'flex', gridAutoColumns: 'column', gridColumnGap: 10, justifyContent: 'flex-start', alignItems: 'center' }}>
                {icon}
                {`#${details.number}`}
            </div>
        );
    }

    private getActions = (details: Entities.CloudBuildJobExecution) => {
        const showDetails = () => {
            this.toLink(`/cloudBuild/details/${details.id}/${details.number}`);
        };

        if (details.result === 'Queue') {
            return <></>;
        }

        const openMenu = (event: any) => this.setState({ authAnchorEl: event.currentTarget, selectedDetails: details });
        return (
            <div>
                <Button text="Details" onClick={showDetails} variant="text" style={{ margin: 'auto 0px', color: '#1a73e8' }}/>
                <IconButton
                    aria-owns={'menu-appbar'}
                    aria-haspopup="true"
                    onClick={openMenu}
                    color="inherit"
                >
                    <MoreHoriz style={{ color: '#0000008a', width: 18, height: 18 }}/>
                </IconButton>
            </div>
        );
    }

    private renderBuildDetailsMenu = () => {
        const { authAnchorEl, selectedDetails } = this.state;
        if (!selectedDetails) {
            return;
        }

        const closeMenu = () => this.setState({ authAnchorEl: undefined, selectedDetails: null });
        const open = !!authAnchorEl;

        const onCancel = async () => {
            closeMenu();
            await actions.devOps.cancelCloudBuild(selectedDetails.id, selectedDetails.number);
            this.onSearch();
        };

        const downloadItems = _.map(selectedDetails.artifacts, (artifact) => {
            const handleDownload = async () => {
                // if (selectedDetails.type === 'UnityCloud') {
                    // @ts-ignore
                    document.getElementById(artifact.file.fileName).click();
                /*
                }

                await actions.devOps.downloadArchive(selectedDetails.id, selectedDetails.number, artifact.file.relativePath);

                 */
            };

            return (
                <>
                    <a href={artifact.file.href} id={artifact.file.fileName} hidden={true} download={true} />
                    <MenuItem color="inherit" key={artifact.name} value={artifact.name} onClick={handleDownload} style={{ color: 'rgba(0, 0, 0, 0.54)', padding: '10px 24px' }} >
                        <GetAppIcon style={{ width: 16, height: 16, marginRight: 10, color: 'rgba(0, 0, 0, 0.54)' }} />
                        {`Download ${artifact.name}`}
                    </MenuItem>
                </>
            );
        });

        return (
            <BuildDetailsMenu
                anchorEl={authAnchorEl}
                open={open}
                onClose={closeMenu}
            >
                {(selectedDetails.result === 'Building' || selectedDetails.result === 'Queue') && (
                    <MenuItem color="inherit" key={'cancel'} value={'cancel'} onClick={onCancel} style={{ color: 'rgba(0, 0, 0, 0.54)', padding: '10px 24px' }}>
                        <CancelIcon style={{ width: 16, height: 16, marginRight: 10, color: 'rgba(0, 0, 0, 0.54)' }} />
                        Cancel
                    </MenuItem>
                )}
                {downloadItems}
            </BuildDetailsMenu>
        );
    }

    private openBuildDialog = () => {
        this.stopTimer();
        this.setState({ openDialog: 'Build' });
    }
    private renderBuildDialog = () => {
        const { jobs } = this.props;
        const { openDialog } = this.state;
        return (
            <StartBuildDialog
                open={openDialog === 'Build'}
                jobs={jobs}
                onClose={this.closeDialog}
                onStartBuild={this.onStartBuild}

            />
        );
    };
    private onStartBuild = async (jobs: Entities.CloudBuildJob[], clean: boolean) => {
        const ids = jobs.map(j => j.id);
        await actions.devOps.startCloudBuild(ids, clean);
    }

    private closeDialog = () => {
        this.setState({
            openDialog: null,
        });
        this.startTimer();
    }

    private onRefreshData = (oldData:any[], newData: any[]) => {
        if (!this.tableRef) {
            return;
        }
        const current = this.tableRef.current;
        if  (!current) {
            return;
        }

        if (!oldData.some( d => d.job.result === 'Building' || d.job.result === 'Queue')) {
            return;
        }

        if(!oldData || !newData) {
            return;
        }

        let someFinished = false;
        oldData.forEach( oldJob => {
            if(oldJob.job.result !== 'Building' && oldJob.job.result !== 'Queue') {
                return;
            }

            const newJob = newData.find(n => n.job.id === oldJob.job.id);
            if (!newJob) {
                return;
            }

            if (oldJob.job.result === 'Building' && newJob.job.result !== 'Building') {
                someFinished = true;
            }
            if (oldJob.job.result === 'Queue' && newJob.job.result !== 'Queue') {
                someFinished = true;
            }
        });
        if(!someFinished) {
            return;
        }

        const dataManager = current.dataManager;
        dataManager.setData(newData);
        current.setState({ ...dataManager.getRenderState() });
    }

    private onRefresh = () => {
        if (!this.tableRef) {
            return;
        }
        const current = this.tableRef.current;
        if  (!current) {
            return;
        }

        const dataManager = current.dataManager;
        const data = dataManager.data;
        if (data.length === 0) {
            return;
        }

        if (!data.some( (d: any) => d.job.result === 'Building' || d.job.result === 'Queue')) {
            return;
        }

        data.forEach((d: any) => {
            if(d.job.result === 'Building' || d.job.result === 'Queue') {
                d.duration+=1000;
            }
        });

        dataManager.setData(data);
        current.setState({ ...dataManager.getRenderState() });
    };

    private startTimer = () => {
        this.timer = setInterval(this.onRefresh, 1000);
    }

    private stopTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}

const BuildDetailsMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(() => ({
    '& .MuiMenu-paper': {
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        marginTop: 0,
    },
}));

export const StyledChip = styled(Chip)(({
    backgroundColor: '#1a73e8',
    borderRadius: 0,
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    jobs: state.devOps.jobs,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
