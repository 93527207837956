import React from 'react';
import { CalendarOptions } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

interface Props extends CalendarOptions {
    readonly style?: React.CSSProperties;
}

export class Calendar extends React.Component<Props> {
    render() {
        const { style } = this.props;
        return (
            <div style={style}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    {...this.props}
                />
            </div>
        );
    }
}
