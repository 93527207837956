import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { ContainerContext, mapProps } from './';
import { formatDate } from '../utils';
import {
    WarningDialog,
    CreateAchievementDialog,
    EditAchievementDialog,
    Table,
    BaseContainer,
    BaseElement, HeaderButton,
} from '../components';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    EmojiEvents as EmojiEventsIcon,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Create' | 'Edit' | 'ConfirmDelete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedAchievement: Entities.Achievement | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedAchievement: null,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openCreateDialog},
        ];
        return (
            <BaseContainer
                {...this.props}
                title = "Achievements"
                TitleIcon ={EmojiEventsIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderConfirmDeleteDialog()}
                {this.renderEditDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Updated', field: 'updated'},
        ];

        return (
            <Table
                columns={columns}
                data={this.getData}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                }}
                actions={
                    userTitleRole > UserTitleRole.Viewer ?
                    [
                        rowData => ({
                            icon: ModeEditOutlineOutlined,
                            tooltip: 'Edit',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openEditDialog(rowData.achievement)
                        }),
                        rowData => ({
                            icon: DeleteOutlineOutlined,
                            tooltip: 'Delete',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openConfirmDeleteDialog(rowData.achievement)
                        }),
                    ]
                    : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`achievement/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.Achievement) => {
            return {
                id: e.id,
                details: this.getDetails(e),
                updated: formatDate(e.updated),
                achievement: e,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getDetails = (achievement: Entities.Achievement) => {
        return (
            <div>
                <Typography variant={'subtitle2'} style={{fontSize: 14, fontWeight: '600'}}>
                    {achievement.id}
                </Typography>
                <div>
                    <div style={{ fontWeight: 500 }}>Metadata Id</div>
                    {achievement.metadataId}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>Apple Id</div>
                    {achievement.appleId}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>Google Id:</div>
                    {achievement.googleId}
                </div>
            </div>
        );
    };

    private openConfirmDeleteDialog = (achievement: Entities.Achievement) => this.setState({ openDialog: 'ConfirmDelete', selectedAchievement: achievement });
    private renderConfirmDeleteDialog = () => {
        const { openDialog } = this.state;
        const { selectedAchievement } = this.state;
        if (!selectedAchievement) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title="Delete Title Data"
                TitleIcon ={EmojiEventsIcon}
                content={`Are you sure you want to delete the ${selectedAchievement.id} achievement`}
                onClose={this.closeDialog}
                onSubmit={this.onDeleteAchievement}
                maxWidth={'xs'}
            />
        );
    };
    private onDeleteAchievement = async () => {
        this.closeDialog();
        const { selectedAchievement } = this.state;
        if (!selectedAchievement) {
            return;
        }
        await actions.achievement.remove(selectedAchievement.id);
    };

    private openEditDialog = (achievement: Entities.Achievement) => this.setState({ openDialog: 'Edit', selectedAchievement: achievement });
    private renderEditDialog = () => {
        const { openDialog, selectedAchievement } = this.state;
        if (!selectedAchievement) {
            return;
        }
        return (
            <EditAchievementDialog
                open={openDialog === 'Edit'}
                title={'Edit Achievement'}
                TitleIcon ={EmojiEventsIcon}
                achievement={selectedAchievement}
                onClose={this.closeDialog}
                onEdit={this.onEditAchievement}
            />
        );
    };
    private onEditAchievement = async (achievement: Entities.Achievement) => {
        this.closeDialog();
        await actions.achievement.update(achievement);
    };

    private closeDialog = () => this.setState({openDialog: null});

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateAchievementDialog
                open={openDialog === 'Create'}
                title={'Create Achievement'}
                TitleIcon ={EmojiEventsIcon}
                onClose={this.closeDialog}
                onCreate={this.onCreateAchievement}
            />
        );
    };
    private onCreateAchievement = async (achievement: Entities.Achievement) => {
        this.closeDialog();
        await actions.achievement.create(achievement);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
