import * as React from 'react';
import { Grid, Button as MaterialButton, ButtonProps, SvgIcon } from '@mui/material';

type SvgIconComponent = typeof SvgIcon;

export interface Props extends ButtonProps {
    readonly text?: string;
    readonly icon?: SvgIconComponent;
    readonly iconPlacement?: 'start' | 'end';
    readonly iconSize?: number;
    readonly iconColor?: string;
    readonly textColor?: string;
}

export class Button extends React.PureComponent<Props> {
    render() {
        const {text, icon: Icon, iconPlacement, iconSize, iconColor, textColor} = this.props;

        return (
            <MaterialButton {...this.props}>
                <Grid container={true} justifyContent="center" alignItems="center" spacing={1}>
                    {Icon && ( !iconPlacement || iconPlacement === 'start' ) &&
                        <Grid item={true} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon style={{ width: iconSize || 20, height: iconSize || 20, color: iconColor ? iconColor : 'inherit' }}/>
                        </Grid>
                    }
                    {text &&
                        <Grid item={true} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: textColor ? textColor : 'inherit'}}>
                            {text}
                        </Grid>
                    }
                    {Icon && iconPlacement === 'end' &&
                        <Grid item={true} style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon style={{ width: iconSize || 20 , height: iconSize || 20, color: iconColor ? iconColor : 'inherit'}}/>
                        </Grid>
                    }
                </Grid>
            </MaterialButton>
        );
    }
}
