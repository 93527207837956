import * as React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { ContainerContext, mapProps } from './';
import { BaseContainer, AceEditor, BaseElement } from '../components';
import { Select, SelectChangeEvent, OutlinedInput, Grid, FormControl } from '@mui/material';
import { Cloud as CloudIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly revision: string;
    readonly cloudCodeScripts: Entities.CloudCode[];
    readonly cloudCodeSelected?: Entities.CloudCode;
}

type DialogType = 'Upload';
interface State {
    readonly openDialog: DialogType | null;
    readonly file: any;
    readonly filename: string;
    readonly isLive: boolean;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        file: null,
        filename: '',
        isLive: false,
    };

    async componentDidMount() {
        const { revision } = this.props;
        await actions.cloudCode.get(parseInt(revision, 10));
    }

    protected renderContainer(): React.JSX.Element {
        const { loading, revision } = this.props;
        const headlines = [
            {
                text: 'Cloud Code',
                to: `/cloudCode/all`,
            },
            {
                text: revision,
            },
        ];
        return (
            <BaseContainer
                loading = {loading}
                title = "Cloud Code Details"
                TitleIcon = {CloudIcon}
                headlines={headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { cloudCodeScripts, cloudCodeSelected } = this.props;

        if (!cloudCodeSelected) {
            return;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={2}>
                <Grid item={true} xs={12}>
                    <FormControl variant="outlined" style={{ width: '100%', height: '100%', borderRadius: 4, backgroundColor: 'white', }}>
                        <Select
                            native={true}
                            value={cloudCodeSelected.revision}
                            onChange={this.onChangeRevision}
                            input={<OutlinedInput style={{ border: 'none'}}/>}
                            style={{
                                width: '100%',
                                height: '100%',
                                color: 'rgba(0, 0, 0, 0.8)',
                                borderRadius: 0,
                                boxSizing: 'border-box',
                                fontSize: '14px',
                                backgroundColor: 'white',
                                boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                                border: 'none',
                            }}
                        >
                            {cloudCodeScripts.map(value => (
                                <option key={value.revision} value={value.revision}>
                                    {`Revision ${value.revision} ${value.live ? '(live)' : ''}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item={true} xs={12}>
                    <div style={{
                        borderRadius: 0,
                        boxSizing: 'border-box',
                        backgroundColor: 'white',
                        margin: 'auto',
                        boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                        border: 'none',
                    }}>
                        <AceEditor
                            theme={'tomorrow'}
                            mode="github"
                            value={cloudCodeSelected.file}
                            readOnly={true}
                            height={`${screen.height-400}px`}
                            showGoTo={true}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    };

    private onChangeRevision = async (event: SelectChangeEvent<number>) => {
        const revision = event.target.value as number;
        await actions.cloudCode.get(revision);

    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    revision: '',
    cloudCodeScripts: state.cloudCode.scripts,
    cloudCodeSelected: state.cloudCode.selected,
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const revision = params.revision || '';
    return (<Container {...props} navigate={navigate} revision={revision}/>);
};
export default connect(mapStateToProps)(AppContainer);
