import { Action } from './index';
import { AppActionType, DevOpsActionType, TitleActionType } from '../enums';

export interface DevOpsState {
    jobs: Entities.CloudBuildJob[];
    jobVersion?: Entities.CloudBuildJobDetails;
}

export type DevOpsAction = Action<Partial<DevOpsState>>;

const initState: DevOpsState = {
    jobs: [],
};

const reducer = (
    state: DevOpsState = initState,
    action: DevOpsAction,
): DevOpsState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case DevOpsActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
