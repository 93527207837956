import { ContainerContext, mapProps } from './index';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import * as React from 'react';
import actions from '../actions';
import { BaseContainer, BaseElement, Button, Dropzone, Metadata, TextField } from '../components';
import { Newspaper as NewspaperIcon, Square } from '@mui/icons-material';
import { TitleNewsStatus } from '../enums';
import { Moment } from 'moment';
import { Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { localeNumber } from '../utils';
import * as moment from 'moment';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly segments: Entities.Segment[];
}

interface State {
    readonly date: Moment;
    readonly status: TitleNewsStatus;
    readonly title: string;
    readonly body: string;
    readonly file: File | null;
    readonly metadata: string;
    readonly segmentFilterId: string;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        date: moment.parseZone(),
        status: TitleNewsStatus.None,
        title: '',
        body: '',
        file: null,
        metadata: '',
        segmentFilterId: 'none',
    };

    async componentDidMount() {
        await actions.segment.listAll();
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Title News',
                to: `/titleNews/all`,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                title={'Create Title News'}
                TitleIcon={NewspaperIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { segments } = this.props;
        const { date, status, title, body, metadata, segmentFilterId } = this.state;
        const statusOptions: TitleNewsStatus[] = [
            TitleNewsStatus.None,
            TitleNewsStatus.Unpublished,
            TitleNewsStatus.Published,
            TitleNewsStatus.Archived,
        ];

        return (
            <Grid
                container={true}
                spacing={0}
                style={{ width: '90%', margin: 'auto' }}
            >
                <Grid item={true} xs={12}>
                    <InputLabel>Title</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={title}
                        label=""
                        required={true}
                        onChange={this.updateTitle}
                        variant="outlined"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Start</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <DateTimePicker
                        label=""
                        value={date}
                        onChange={this.updateDate}
                        disablePast={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Status</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Select
                        value={status}
                        onChange={this.updateStatus}
                        variant={'outlined'}
                        style={{width: '100%', backgroundColor: 'white'}}
                    >
                        {statusOptions.map(value => (
                            <MenuItem key={value} value={value}>
                                {`${TitleNewsStatus[value]}`}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Image</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Dropzone
                        accept={{
                            "image/jpeg": ['.jpeg'],
                            "image/jpg": ['.jpg'],
                            "image/png": ['.png'],
                        }}
                        multiple={false}
                        text={'Drop image file'}
                        onDropFile={this.handleDrop}
                        description={'Upload image (JPEG, JPG or PNG)'}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                        style={{ padding: '10px 0px' }}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Body</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        label=""
                        value={body}
                        variant="outlined"
                        multiline={true}
                        rows={4}
                        fullWidth={true}
                        onChange={this.updateBody}
                    />
                </Grid>
                {segments && segments.length > 0 && (
                    <>
                        <Grid item={true} xs={12}>
                            <InputLabel>Filter Segment</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Select
                                value={segmentFilterId}
                                onChange={this.updateSegmentFilterId}
                                variant={'outlined'}
                                style={{width: '100%', backgroundColor: 'white'}}
                            >
                                <MenuItem key={'none'} value={'none'}>
                                    <div style={{width: '100%', padding: '0px 10px', display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-start'}}>
                                        {`None`}
                                    </div>
                                </MenuItem>
                                {segments.map(segment => (
                                    <MenuItem key={segment.id} value={segment.id}>
                                        <div style={{width: '100%', padding: '0px 10px', display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-start'}}>
                                            <Square style={{ color: segment.color}} />
                                            ${segment.name}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </>
                )}
                <Grid item={true} xs={12} style={{
                    paddingTop: 18,
                    display: 'grid',
                    justifyContent: 'flex-end',
                    gridAutoFlow: 'column',
                    gridColumnGap: 10,
                }}>
                    <Button text="Create" onClick={this.onCreateTitleNews} variant="contained" disabled={!this.validate()}/>
                </Grid>
            </Grid>
        );
    }

    private updateDate = (date: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (date === null) {
            return;
        }
        this.setState({ date });
    }

    private updateStatus = (event: SelectChangeEvent<number>) => this.setState({ status: event.target.value as number });

    private updateSegmentFilterId = (event: SelectChangeEvent) => this.setState({ segmentFilterId: event.target.value });

    private updateTitle = (value: string) => this.setState({ title: value });

    private updateBody = (value: string) => this.setState({ body: value });

    private handleDrop = (file: File) => this.setState({ file });

    private handleChangeMetadata = (metadata: string) => this.setState({ metadata });

    private validate(): boolean {
        if (!this.validateStatus()) {
            return false;
        }

        const { title, body } = this.state;
        return !(title === '' || body === '');
    }

    private validateStatus(): boolean {
        const { status } = this.state;
        return status !== TitleNewsStatus.None;
    }

    private onCreateTitleNews = async () => {
        if (!this.validateStatus()) {
            return;
        }
        const { date, status, title, body, file, metadata, segmentFilterId } = this.state;
        const dateValue = date.utc().valueOf();
        const segmentId = segmentFilterId !== 'none' ? segmentFilterId : null;
        await actions.titleNews.create(dateValue, status, title, body, file, metadata, segmentId);
        this.toLink('/titleNews/all');
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    segments: state.segment.segments,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
