import { Action } from './index';
import { TitleActionType, PlayerActionType, AppActionType } from '../enums';

export interface PlayerState {
    readonly player?: Entities.Player;
    readonly geolocation?: Entities.Geolocation;
    readonly valueToDate?: number;
    readonly currencyCode?: string;
    readonly loginInfo?: Entities.LoginInfo;
    readonly wallet?: Record<string, number>;
    readonly firebaseInfo?: Entities.PlayerFirebaseInfo;
    readonly stackables?: Entities.PlayerStackableItem[];
    readonly nonStackables?: Entities.PlayerNonStackableItem[];
    readonly compensationInfo?: Entities.PlayerCompensationInfo;
}

export type PlayerAction = Action<Partial<PlayerState>>;

const initState = (): PlayerState => ({
});

const reducer = (
    state: PlayerState = initState(),
    action: PlayerAction,
): PlayerState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case PlayerActionType.UpdatePlayers:
            return { ...state, ...action.payload };
        case PlayerActionType.UpdatePlayerData:
            return { ...state, ...action.payload };
        case PlayerActionType.GetCompensations:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
