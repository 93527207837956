import { Action } from './index';
import {
    AppActionType,
    CostumerSupportActionType,
    PlayerActionType, PromoCampaignActionType,
    TitleActionType,
} from '../enums';

export interface CostumerSupportDataState {
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
}

export type CostumerSupportDataAction = Action<Partial<CostumerSupportDataState>>;

const initState: CostumerSupportDataState = {
    preDefinedItems: []
};

const reducer = (
    state: CostumerSupportDataState = initState,
    action: CostumerSupportDataAction,
): CostumerSupportDataState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case CostumerSupportActionType.Update:
            return { ...state, ...action.payload };
        case PlayerActionType.GetCompensations:
            return { ...state, ...action.payload };
        case PromoCampaignActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
