export const TitleActionType = {
    Update: 'TitleActionType.Update',
    Reset: 'TitleActionType.Reset',
};

export const PlayerActionType = {
    UpdatePlayers: 'PlayerActionType.UpdatePlayers',
    UpdatePlayerData: 'PlayerActionType.UpdatePlayerData',
    GetCompensations: 'PlayerActionType.GetCompensations',
};

export enum UserActionType {
    UpdateUsers = 'UserActionType.UpdateUsers',
    UpdateLoginUser = 'UserActionType.UpdateLoginUser',
}

export const EconomyActionType = {
    Update: 'EconomyActionType.Update',
};

export const LocalStorageActionType = {
    Update: 'LocalStorageActionType.Update',
};

export const AppActionType = {
    Init: 'AppActionType.Init',
    Update: 'AppActionType.Update',
};

export const TitleDataActionType = {
    Update: 'TitleDataActionType.Update',
};

export const ClientVersionActionType = {
    Update: 'ClientVersionActionType.Update',
};

export const CloudCodeActionType = {
    Update: 'CloudCodeActionType.Update',
};

export const LiveEventActionType = {
    Update: 'LiveEventActionType.Update',
};

export const SegmentActionType = {
    Update: 'SegmentActionType.Update',
};

export const CdnActionType = {
    Update: 'CdnActionType.Update',
};

export const SeasonActionType = {
    Update: 'SeasonActionType.Update',
};

export const CostumerSupportActionType = {
    Update: 'CostumerSupportActionType.Update',
};

export const DevOpsActionType = {
    Update: 'DevOpsActionType.Update',
};

export const PromoCampaignActionType = {
    Update: 'PromoCampaignActionType.Update',
};

export const GroupActionType = {
    Update: 'GroupEventActionType.Update',
};

export enum UserRole {
    NoRole = 0,
    Developer = 10,
    Admin = 20,
}

export enum UserTitleRole {
    NoRole = 0,
    Viewer = 10,
    Editor = 20,
    Owner = 30,
}

export enum ResetFrequency {
    Never = 0,
    Hour,
    Day,
    Week,
    Month,
}

export enum AggregationMethod {
    Last = 0,
    Min,
    Max,
    Sum,
}

export enum LoginType {
    Google = 'google',
    Password = 'password',
}

export enum TitleNewsStatus {
    None = 0,
    Unpublished,
    Published,
    Archived,
}

export enum TitleSecretStatus {
    Enable = 0,
    Disable,
}

export enum EventType {
    Regular = 0,
    LeaderBoard = 1,
}

export enum SeasonType {
    Regular = 0,
    LeaderBoard = 1,
}

export enum SegmentRuleType {
    PlayerSpecific  = 1,
    IsDeveloper     = 2,
    FirstLogin      = 3,
    LatLogin        = 4,
    ValueToDateUSD  = 5,

}

export enum SegmentRuleValueOperator {
    IsGraterThan            = 0,
    IsGrateThanOrEqualTo    = 1,
    IsLessThan              = 2,
    IsLessThanOrEqualTo     = 3,
}

export enum SegmentRuleOperator {
    OR      = 0,
    AND     = 1,
}

export enum AutomaticPlayerActionType {
    SendPushNotification = 1,
    ExecuteCloudCode = 2,
}

export enum GroupPrivacy {
    Open = 0,
    Apply = 1,
    Invite = 2,
}

export enum GroupHistoryType {
    PlayerJoined = 0,
    PlayerLeft = 1,
    PlayerKicked = 2,
    PlayerPromoted = 3,
    PlayerDemoted = 4,
    Message = 5,
}

export enum PlayerGroupState {
    JoinRequest = 0,
    Member      = 1,
    Admin       = 2,
    SuperAdmin  = 3,
}

export enum SeasonTypeFilter {
    All = 0,
    Regular = 1 ,
    LeaderBoard = 2,
}

export enum SeasonStatusFilter {
    All = 0,
    Active = 1,
    Coming = 2,
    Completed = 3,
}

export enum LiveEventTypeFilter {
    All = 0,
    Regular = 1 ,
    LeaderBoard = 2,
}

export enum LiveEventStatusFilter {
    All = 0,
    Active = 1,
    Coming = 2,
    Completed = 3,
}

export enum ListEventFilterStateType {
    All             = 0,
    Active          = 1,
    IsComing        = 2,
    ActiveAndComing = 3,
    Ended           = 4,
}

export enum StoreItemStatusFilter {
    All = 0,
    Active = 1,
    Coming = 2,
    Completed = 3,
}

export const Platform = {
    Android: 1,
    iOS: 2,
};

export const ENVS = {
    PROD: 1,
    SANDBOX: 2,
};

export enum LeaderBoardType {
    FirstComeFirstServe,
    Random,
    RandomOld,
}
