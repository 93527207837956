import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, Metadata, NumberField, TextField } from '../common';
import * as React from 'react';
import { toNumber } from 'lodash';

interface Props extends BaseDialogProps {
    readonly onCreate: (name: string, privacy: number, description: string, maxSize: number, metadata: string) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly privacy: number;
    readonly description: string;
    readonly maxSize: number;
    readonly metadata: string;
}

export class CreateGroupDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const {
            name,
            privacy,
            description,
            maxSize,
            metadata,
        } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        label=""
                        value={name}
                        onChange={this.updateName}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel id="select-privacy">Privacy</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Select
                        labelId={`select-privacy`}
                        value={privacy}
                        variant={'outlined'}
                        onChange={this.handleChangePrivacy}
                        disableUnderline={true}
                        fullWidth={true}
                    >
                        <MenuItem key={0} value={0}>
                            {'Open'}
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                            {'Apply'}
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                            {'Invite Only'}
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Description</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        label=""
                        value={description}
                        onChange={this.updateDescription}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Max Size</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <NumberField
                        label=""
                        value={maxSize}
                        onChange={this.updateMaxSize}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    protected initState(): State {
        return {
            name: '',
            privacy: 0,
            description: '',
            maxSize: 50,
            metadata: '',
        };
    }

    private updateName = (name: string) => this.setState({ name });

    private handleChangePrivacy = (event: SelectChangeEvent<number>) => {
        this.setState({...this.state, privacy: toNumber(event.target.value)});
    };

    private updateDescription = (description: string) => this.setState({ description });

    private updateMaxSize = (maxSize: number) => this.setState({ maxSize });

    protected handleChangeMetadata = (metadata: string) => this.setState({ metadata });

    private onCreate = () => {
        const { name, privacy, description, maxSize, metadata } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onCreate(name, privacy, description, maxSize, metadata);
        this.onClose();
    };

    private validate(): boolean {
        const { name } = this.state;
        return name !== '';
    }
}
