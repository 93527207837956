import { combineReducers, configureStore, UnknownAction } from '@reduxjs/toolkit';

import app, { AppState, AppAction } from './app';
import title, { TitleState, TitleAction } from './title';
import user, { UserState, UserAction } from './user';
import economy, { EconomyState, EconomyAction } from './economy';
import costumerSupport, { CostumerSupportDataState, CostumerSupportDataAction } from './costumerSupport';
import player, { PlayerState, PlayerAction } from './player';
import season, { SeasonState, SeasonAction } from './season';
import titleData, { TitleDataState, TitleDataAction } from './titleData';
import cloudCode, { CloudCodeState, CloudCodeAction } from './cloudCode';
import clientVersion, { ClientVersionState, ClientVersionAction } from './clientVersion';
import devOps, { DevOpsState, DevOpsAction } from './devOps';
import liveEvent, { LiveEventState, LiveEventAction } from './liveEvents';
import segment, { SegmentState, SegmentAction } from './segment';
import cdn, { CdnState, CdnAction } from './cdn';
import promoCampaign, { PromoCampaignState, PromoCampaignAction } from './promoCampaign';
import group, { GroupState, GroupAction } from './group';
import { AppActionType } from '../enums';

export interface Action<T> extends UnknownAction {
    readonly payload: T;
}

export interface StateMap {
    readonly app: AppState;
    readonly title: TitleState;
    readonly user: UserState;
    readonly economy: EconomyState;
    readonly devOps: DevOpsState;
    readonly player: PlayerState;
    readonly season: SeasonState;
    readonly titleData: TitleDataState;
    readonly cloudCode: CloudCodeState;
    readonly clientVersion: ClientVersionState;
    readonly liveEvent: LiveEventState;
    readonly segment: SegmentState;
    readonly cdn: CdnState;
    readonly costumerSupport: CostumerSupportDataState;
    readonly promoCampaign: PromoCampaignState;
    readonly group: GroupState;
}

const Store = configureStore({
    reducer : combineReducers({
        app,
        cdn,
        clientVersion,
        cloudCode,
        costumerSupport,
        devOps,
        economy,
        liveEvent,
        player,
        promoCampaign,
        season,
        segment,
        title,
        titleData,
        user,
        group,
    }),
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
});

export const setLoading = (status: boolean) : void => {
    Store.dispatch({
        type: AppActionType.Update,
        payload: {
            loading: status,
        },
    });
};

export const getTitleId = () : string => {
    try {
        const selectedTitle = getState().title.selectedTitle;
        if(!selectedTitle) {
            return '';
        }
        return selectedTitle.id;
    } catch (error) {
        return '';
    }
};

export type {
    TitleState,
    TitleAction,
    AppState,
    AppAction,
    UserState,
    UserAction,
    EconomyState,
    EconomyAction,
    DevOpsState,
    DevOpsAction,
    CloudCodeState,
    CloudCodeAction,
    ClientVersionState,
    ClientVersionAction,
    PlayerState,
    PlayerAction,
    SeasonState,
    SeasonAction,
    TitleDataState,
    TitleDataAction,
    LiveEventState,
    LiveEventAction,
    SegmentState,
    SegmentAction,
    CdnState,
    CdnAction,
    CostumerSupportDataState,
    CostumerSupportDataAction,
    PromoCampaignState,
    PromoCampaignAction,
    GroupState,
    GroupAction,
};

export default Store;
export const dispatch = Store.dispatch;
export const getState = Store.getState;
