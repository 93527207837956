import * as React from 'react';
import { AceEditor, TextField, Button } from '../../';
import { Grid, InputLabel } from '@mui/material';
import { BaseDialog, BaseDialogProps, BaseDialogState } from './BaseDialog';

interface Props extends BaseDialogProps {
    readonly create?: boolean;
    readonly id: string;
    readonly value: string;
    readonly onEdit?: (id: string, value: string) => void;
    readonly onDelete?: (id: string) => void;
    readonly validate?: (value: string) => boolean;
    readonly maxWidth?: 'xs' | 'sm' | 'md';
}

interface State extends BaseDialogState {
    readonly id: string;
    readonly value: string;
}

export class EditorDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        const { props } = this;
        if (props.id === prevProps.id) {
           return;
        }
        this.setState(this.initState());
    }

    protected renderContent(): React.JSX.Element {
        const { create } = this.props;
        const { id, value } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Key</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        variant="outlined"
                        value={id}
                        onChange={this.updateKey}
                        error={!id}
                        readOnly={!create}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Data</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <AceEditor
                        theme={'monokai'}
                        mode="json"
                        value={value}
                        onChange={this.updateValue}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        const { create, onEdit, onDelete } = this.props;
        return (
            <>
                {onDelete && (
                    <Button text="Delete" onClick={this.onDelete} variant="text"  color="secondary" />
                )}
                {onEdit && (
                    <Button text={create ? 'Create' : 'Update'} onClick={this.onSubmit} variant="text"  color="secondary" disabled={!this.validate()} />
                )}
            </>
        );
    }

    protected initState(): State {
        const { id, value } = this.props;
        return {
            id,
            value,
        };
    }

    private updateKey = (id: string) => this.setState({ id });

    private updateValue = (value: string) => this.setState({ value });

    private validate = () => {
        const { value: prev, validate } = this.props;
        const { id, value } = this.state;
        if (prev === value || !id) {
            return false;
        }
        return !validate || validate(value);
    };

    private onSubmit = () => {
        const { onEdit } = this.props;
        const { id, value } = this.state;
        if (onEdit) {
            onEdit(id, value);
        }
        this.onClose();
    };

    private onDelete = () => {
        const { onDelete } = this.props;
        const { id } = this.state;
        if (onDelete) {
            onDelete(id);
        }
        this.onClose();
    };
}
