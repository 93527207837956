import * as React from 'react';
import { Button } from '../common';
import {
    Avatar,
    Grid,
    InputLabel,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
} from '@mui/material';
import { UserTitleRole } from '../../enums';
import { UserTitleRoleSelect } from './UserTitleRoleSelect';

interface Props {
    readonly open: boolean;
    readonly selectedUser: Entities.UserTitleRoleData;
    readonly onClose: () => void;
    readonly onUpdate: (role: UserTitleRole) => void;
}

interface State {
    readonly role: UserTitleRole;
}

export class EditUserTitleDialog extends React.PureComponent<Props, State> {
    state: State = {
        role: UserTitleRole.NoRole,
    };

    componentDidMount() {
        const { selectedUser } = this.props;
        this.setState({ role: selectedUser.userTitleRole });
    }

    componentDidUpdate(prevProps: Props) {
        const { selectedUser } = this.props;
        if (selectedUser.userTitleRole !== prevProps.selectedUser.userTitleRole) {
            this.setState({ role: selectedUser.userTitleRole });
        }
    }

    render() {
        const { open } = this.props;
        const { username, name, picture } = this.props.selectedUser;
        const { role } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle style={{borderBottom: '1px solid #dfdfdf', padding: 10, height: 40 }}>
                    <Grid container={true} justifyContent="center" alignItems="center" spacing={0}>
                        <Grid item={true} xs={12} style={{ display: 'grid', gridColumnGap: 10, gridAutoFlow: 'column', justifyContent: 'flex-start'}}>
                            <Avatar alt="Remy Sharp" src={picture} style={{ height: 40, width: 40, backgroundColor: 'rgba(0,0,0,0.025)' }} />
                            {name !== '' && (
                                <Grid container={true}>
                                    <Grid item={true} xs={12}>
                                        <div style={{ color: 'rgba(0,0,0,.87)', fontSize: 14 }} >
                                            {name}
                                        </div>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <div style={{ color: 'rgba(0,0,0,.54)', fontSize: 12 }} >
                                            {username}
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            {name === '' && (
                                <Grid container={true} xs={12}>
                                    <Grid item={true} >
                                        <div style={{ color: 'rgba(0,0,0,.87)', fontSize: 14 }} >
                                            {username}
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container={true} justifyContent="center" alignItems="center" spacing={0}>
                        <Grid item={true} xs={12}>
                            <InputLabel>Role</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <UserTitleRoleSelect role={role} updateRole={this.updateRole} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    borderTop: '1px solid #dfdfdf',
                    padding: 8
                }}>
                    <Button text="Cancel" onClick={this.onClose} variant="text" />
                    <Button text="Update role" onClick={this.onCreate} variant="text" color="secondary" disabled={!this.validate()} />
                </DialogActions>
            </Dialog>
        );
    }

    private validate() {
        const { role } = this.state;
        return !(role === UserTitleRole.NoRole || role === this.props.selectedUser.userTitleRole);
    }

    private onClose = () => this.props.onClose();

    private updateRole = (role: UserTitleRole) => this.setState({ role });

    private onCreate = () => {
        const { role } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onUpdate(role);
        this.onClose();
    };
}
