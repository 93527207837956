import * as React from 'react';
import actions, { ActionRequest } from '../actions';
import {
    WarningDialog,
    BaseContainer,
    Table,
    UpdateTitleNewsDialog,
    BaseElement,
    HeaderButton,
} from '../components';
import { formatDate } from '../utils';
import { TitleNewsStatus, UserTitleRole } from '../enums';
import { connect } from 'react-redux';
import { CardMedia, Chip, Hidden } from '@mui/material';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    Newspaper as NewspaperIcon,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { ContainerContext, mapProps } from './index';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly segments: Entities.Segment[];
}

type DialogType = 'Description' | 'ConfirmDelete';

interface State {
    readonly openDialog: DialogType | null;
    readonly selectedTitleNews: Entities.TitleNews | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedTitleNews: null,
    };

    async componentDidMount() {
        await actions.segment.listAll();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.goToCreate}
        ];
        return (
            <>
                <BaseContainer
                    {...this.props}
                    title = {"Title News"}
                    TitleIcon={NewspaperIcon}
                    buttons = {buttons}
                >
                    {this.renderContent()}
                </BaseContainer>
            </>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDescriptionDialog()}
                {this.renderConfirmDeleteDialog()}
            </>
        );
    }

    private renderDescriptionDialog() {
        const { segments } = this.props;
        const { openDialog, selectedTitleNews } = this.state;

        return (
            <UpdateTitleNewsDialog
                open={openDialog === 'Description'}
                title={'Update Title News'}
                TitleIcon={NewspaperIcon}
                segments={segments}
                selectedTitleNews={selectedTitleNews}
                onClose={this.closeDialog}
                onUpdate={this.onUpdateTitleNews}
            />
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;

        const columns = [
            { title: 'Details', field: 'details'},
        ];

        return (
            <Table
                data={this.getData}
                columns={columns}
                options={{
                    showTitle: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                }}
                actions={ userTitleRole > UserTitleRole.Viewer ?
                    [
                        rowData => ({
                            icon: ModeEditOutlineOutlined,
                            tooltip: 'edit',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.editTitleNew(rowData.titleNews)
                        }),
                        rowData => ({
                            icon: DeleteOutlineOutlined,
                            tooltip: 'delete',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openDeleteDialog(rowData.titleNews)
                        }),
                    ]
                    : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`titleNews/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.TitleNews) => {
            return {
                details: this.getDetails(e),
                id: e.newsId,
                titleNews: e,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getDetails = (titleNews: Entities.TitleNews) => {
        const segment = titleNews.segmentFilterId !== '' ? this.props.segments.find(s => s.id === titleNews.segmentFilterId) : null;
        return (
            <div style={{ display: 'flex' }}>
                {titleNews.imageUrl !== '' && (
                    <Hidden smDown={true}>
                        <CardMedia image={titleNews.imageUrl} style={{ width: 200, height: 150 }}/>
                    </Hidden>
                )}
                <div style={{ display: 'inline-block', margin: '0px 12px' }}>
                    {segment && (
                        <Chip
                            color={'secondary'}
                            label={segment.name}
                            style={{ margin: '5px 0px', backgroundColor: segment.color, color: 'white' }}
                        />
                    )}
                    <div style={{fontSize: 14, fontWeight: '600'}}>
                        {titleNews.title}
                    </div>
                    <div style={{ fontWeight: 500 }}>Status:</div>
                    <div>
                        {this.getStatus(titleNews.status)}
                    </div>
                    <div style={{ fontWeight: 500 }}>Start:</div>
                    <div>
                        {formatDate(titleNews.timestamp)}
                    </div>
                    <div style={{ fontWeight: 500 }}>Last Edited:</div>
                    <div>
                        {formatDate(titleNews.updated)}
                    </div>
                </div>
            </div>
        );
    };

    private getStatus = (status: TitleNewsStatus) => {
        if (status === TitleNewsStatus.Published) {
            return <div style={{color: 'green'}}>Published</div>;
        }
        if (status === TitleNewsStatus.Archived) {
            return <div style={{color: 'orange'}}>Archived</div>;
        }
        return <div style={{color: 'grey'}}>Unpublished</div>;
    };

    private closeDialog = () => {
        this.setState({
            openDialog: null,
            selectedTitleNews: null,
        });
    };

    private goToCreate = () => {
        this.toLink('/titleNews/create');
    };

    private onUpdateTitleNews = async (id: string, date: number, status: TitleNewsStatus, title: string, body: string, file: any, metadata: string, segmentFilterId: string | null) => {
        this.closeDialog();
        await actions.titleNews.update(id, date, status, title, body, file, metadata, segmentFilterId);
    };

    private editTitleNew = (titleNews: Entities.TitleNews) => this.setState({openDialog: 'Description', selectedTitleNews: titleNews});


    private openDeleteDialog = (titleNews: Entities.TitleNews) => this.setState({openDialog: 'ConfirmDelete', selectedTitleNews: titleNews});
    private renderConfirmDeleteDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title={'Confirm Delete'}
                TitleIcon={NewspaperIcon}
                content="This will permanently delete the news."
                onClose={this.closeDialog}
                onSubmit={this.deleteTitleNew}
                maxWidth={'xs'}
            />
        );
    };
    private deleteTitleNew = async () => {
        const { selectedTitleNews } = this.state;
        this.closeDialog();
        if (!selectedTitleNews) {
            return;
        }
        await actions.titleNews.remove(selectedTitleNews.newsId);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    segments: state.segment.segments,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
