import { Action } from './index';
import { TitleActionType, CloudCodeActionType, AppActionType } from '../enums';

export interface CloudCodeState {
    readonly scripts: Entities.CloudCode[];
    readonly selected?: Entities.CloudCode;
    readonly methods?: string[];
    readonly titleData?: Entities.TitleData[];
}

export type CloudCodeAction = Action<Partial<CloudCodeState>>;

const initState: CloudCodeState = {
    scripts: [],
};

const reducer = (
    state: CloudCodeState = initState,
    action: CloudCodeAction,
): CloudCodeState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case CloudCodeActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
