import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import { BaseContainer, BaseElement, CreateSeasonRegularComponent } from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Games as GamesIcon } from '@mui/icons-material';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly seasonId: string;
    readonly season?: Entities.Season;
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        const { seasonId } = this.props;
        await actions.season.get(seasonId);
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Season',
                to: `/season/all`,
            },
            {
                text: 'Edit',
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                title = {"Edit Season"}
                TitleIcon = {GamesIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { season } = this.props;
        return (
            <CreateSeasonRegularComponent
                type='Edit'
                onCreate={this.onEditSeason}
                oldSeason={season}
            />
        );
    }

    private onEditSeason = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[]) => {
        const { seasonId } = this.props;
        await actions.season.edit(seasonId, name, start, end, metadata, developerOnly, tiers);
        this.toSeason();
    };

    private toSeason = () => this.toLink(`/season/all`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    seasonId: '',
    season: state.season.season,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const seasonId = params.seasonId || '';
    return (<Container {...props} navigate={navigate} seasonId={seasonId}/>);
};
export default connect(mapStateToProps)(AppContainer);
