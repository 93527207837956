import React from 'react';
import {
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    List,
} from '@mui/material';
import { Button } from './input';
import { ArrowForwardIos, FilterList } from '@mui/icons-material';
import { styled } from '@mui/styles';

interface Props {
    readonly title: string;
    readonly menuTitles: string[];
    readonly menuElements: React.ReactNode[];
    readonly onFilter: () => void;
    readonly onClose: () => void;
}

interface State {
    readonly anchorEl: HTMLElement | undefined;
    readonly selectedIndex: number;
}

export class Filter extends React.Component<Props, State> {
    state: State = {
        anchorEl: undefined,
        selectedIndex: 0,
    };

    render() {
        const { title, menuTitles, menuElements, onFilter } = this.props;
        const { anchorEl, selectedIndex } = this.state;
        const handleMenu = (event: any) => this.setState({ anchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ anchorEl: undefined, selectedIndex: 0 });
        const onCancelButton = () => {
            closeMenu();
            this.props.onClose();
        };
        const onFilterButton = () => {
            closeMenu();
            onFilter();
        };

        const open = !!anchorEl;

        return (
            <>
                <Button
                    icon={FilterList}
                    iconSize={18}
                    text={title}
                    variant="contained"
                    onClick={handleMenu}
                    style={{
                        backgroundColor: '#fff',
                        color: `rgba(0,0,0,0.5)`,
                        fontWeight: 600,
                        fontSize: 13,
                        borderRadius: 0,
                        height: 20,
                    }}
                />
                <MainMenu
                    id={`filter`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    style={{ padding: 0 }}
                    onClose={onCancelButton}
                >
                    <div style={{ display: 'flex' , minHeight: 200}}>
                        <MainList style={{ minWidth: 150 }}>
                            {menuTitles.map((menuTitle, index) => {
                                const selected = selectedIndex === index;
                                const handleListItemClick = () => this.setState({selectedIndex: index});
                                const primary = (<div style={{ fontSize: 14 }}>{menuTitle}</div>);
                                return (
                                    <MainListItem
                                        key={`filter_list_item_${index}`}
                                        selected={selected}
                                        onClick={handleListItemClick}
                                    >
                                        <ListItemText primary={primary}/>
                                        {selected && (
                                            <MainListItemIcon>
                                                <ArrowForwardIos style={{ color: 'rgba(0,0,0,.54)', width: 15, height: 15 }}/>
                                            </MainListItemIcon>
                                        )}
                                    </MainListItem>
                                );
                            })}
                        </MainList>
                        <Divider orientation="vertical" flexItem={true} />
                        <div style={{ minWidth: 150, padding: '14px 0', display: 'flex', flexDirection: 'column'}}>
                            {menuElements[selectedIndex]}
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8, gridColumnGap: 10 }}>
                        <Button text="Cancel" variant="text" onClick={onCancelButton} />
                        <Button text="Apply" variant="contained" onClick={onFilterButton} />
                    </div>
                </MainMenu>
            </>
        );
    }
}

const MainMenu = styled(Menu)(({
    '& .MuiMenu-paper': {
        borderRadius: 0,
        marginTop: 2,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 8px 3px, rgba(0, 0, 0, 0.3) 0px 1px 3px',
    },
    '& .MuiMenu-list': {
        padding: 0,
    },
}));

const MainList = styled(List)(({
    '&.MuiList-root': {
        padding: '14px 0',
    },
}));

const MainListItem = styled(ListItem)(({
    '&.MuiListItem-root': {
        padding: '0px 4px 0px 16px',
        height: 32,
        "&$selected": {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            "&:disabled": {
                backgroundColor: 'rgba(0, 0, 0, 0.08)'
            },
            "&:hover": {
                backgroundColor: 'rgba(0, 0, 0, 0.08)'
            },
            "&:focus": {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
        }
    },
    '&.MuiListItem-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    padding: 0,
}));

const MainListItemIcon = styled(ListItemIcon)(({
    '&.MuiListItemIcon-root': {
        color: 'unset',
        padding: 0,
        minWidth: 50,
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
