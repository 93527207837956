import * as React from 'react';
import { Calendar } from '../common';
import { BaseDialog, BaseDialogProps } from '../common/dialog/BaseDialog';

interface Props extends BaseDialogProps {
    readonly liveEvents: Entities.LiveEvent[];
}

export class LiveEventsCalendarDialog extends BaseDialog<Props> {

    protected renderContent(): React.JSX.Element {
        const { liveEvents } = this.props;
        const events: any[] = liveEvents.map(e => {
            return {
                id: e.eventId,
                title: e.name,
                start: new Date(e.startTimestamp),
                end: new Date(e.endTimestamp),
                backgroundColor: e.scheduleColor,
                textColor: 'white',
                editable: false,
                url: `/liveEvents/${e.eventId}`,
            };
        });

        return (
            <Calendar
                headerToolbar = {{
                    left: 'dayGridMonth,timeGridWeek,timeGridDay',
                    center: 'title',
                    right: 'prevYear,prev,next,nextYear'
                }}
                weekends={true}
                height={'parent'}
                contentHeight={'auto'}
                handleWindowResize={true}
                events={events}
            />
        );
    }
}
