import React from 'react';
import { Filter } from '../common';
import { StoreItemStatusFilter } from '../../enums';
import { Checkbox } from '@mui/material';
import { FilterList, FilterListItem, FilterListItemText } from '../../utils';
import { toNumber } from 'lodash';

interface Props {
    readonly statusFilter: StoreItemStatusFilter;
    readonly onFilter: (statusFilter: StoreItemStatusFilter) => void;
}

interface State {
    readonly statusFilter: StoreItemStatusFilter;
}

export class EconomyStoreFilter extends React.Component<Props, State> {
    state: State = {
        statusFilter: StoreItemStatusFilter.All,
    };

    componentDidUpdate(prevProps: Props) {
        const { statusFilter } = this.props;
        if ( prevProps.statusFilter === statusFilter ) {
            return;
        }

        this.setState({
            statusFilter,
        });
    }

    render() {
        const { statusFilter } = this.state;

        const onFilter = () => {
            this.props.onFilter(statusFilter);
        };

        const onClose = () => this.setState({
            statusFilter: this.props.statusFilter,
        });

        return (
            <Filter
                title={'Filter Store Items'}
                menuTitles = {['Status']}
                menuElements = {[
                    (
                        <FilterList>
                            {Object.keys(StoreItemStatusFilter)
                                .filter((key) => Number.isNaN(+key))
                                .map((key, value) => {
                                    if (value === StoreItemStatusFilter.All) {
                                        return;
                                    }
                                    const handleStatusFilterChange = () => this.setState({ statusFilter: value });

                                    let text = '';
                                    let color = '';
                                    switch (value) {
                                        case StoreItemStatusFilter.Active:
                                            text = 'In Progress';
                                            color = 'green';
                                            break;
                                        case StoreItemStatusFilter.Coming:
                                            text = 'Scheduled';
                                            color = 'grey';
                                            break;
                                        case StoreItemStatusFilter.Completed:
                                            text = 'Completed';
                                            color = 'orange';
                                            break;
                                    }

                                    return (
                                        <FilterListItem
                                            key={`live_event_status_filter_${value}`}
                                            secondaryAction={
                                                <Checkbox
                                                    edge="end"
                                                    onChange={handleStatusFilterChange}
                                                    checked={statusFilter === value}
                                                />
                                            }
                                        >
                                            <FilterListItemText text={text} color={color} />
                                        </FilterListItem>
                                    );
                                }
                            )}
                        </FilterList>
                    ),
                ]}
                onFilter={onFilter}
                onClose={onClose}
            />
        );
    }
}
