import { Request } from '../utils';

export const remove = async (compensationId: string) => {
    await Request.post(`compensation/delete`, { compensationId });
};

export const create = async (title: string, description: string, from: number, to:number, 
    allUsers:boolean, userList: string, itemList: string) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("from", from.toString());
    formData.append("to", to.toString());
    formData.append("allUsers", allUsers.toString());
    formData.append("userList", userList);
    formData.append("itemList", itemList);

    await Request.postForm(`compensation/create`, formData);
};

export const update = async (id:string, title: string, description: string, from: number, to:number, 
    allUsers:boolean, userList: string, itemList: string) => {
    
    const formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("from", from.toString());
    formData.append("to", to.toString());
    formData.append("allUsers", allUsers.toString());
    formData.append("userList", userList);
    formData.append("itemList", itemList);

    await Request.postForm(`compensation/update`, formData);
};
