import React from 'react';
import MaterialTable, { MaterialTableProps, Query, QueryResult, Components, Column } from 'material-table';
import {
    Grid,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    alpha,
    Select,
    SelectChangeEvent,
    ListItemText,
    Input, Hidden,
} from '@mui/material';
import * as _ from 'lodash';
import {
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    SaveAlt,
} from '@mui/icons-material';
import { CsvBuilder } from "filefy";
import "jspdf-autotable";
import * as FileSaver from 'file-saver';
import { Loading, SearchTextField } from './';
import { styled } from '@mui/styles';
import { fontFamily, styleComponents, localeNumber } from '../../utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CSSObject } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { Components as StyleComponents } from '@mui/material/styles/components';

// tslint:disable-next-line:no-var-requires
const jsPDF = typeof window !== "undefined" ? require("jspdf").jsPDF : null;

interface Props extends MaterialTableProps<any> {
    readonly showBox?: boolean;
    readonly refreshSeconds?: number;
    readonly onRefresh?: (oldData: any, newData: any) => void;
    readonly style?: React.CSSProperties;
    readonly cellStyle?: CSSObject;
    readonly ignoreSmall?: boolean;
    readonly ignoreSearchReadOnly?: boolean;
}

interface State {
    readonly openExportMenu: boolean;
}

export class Table extends React.Component<Props, State> {
    state = {
        openExportMenu: false,
    };

    private tableRef: any;
    private timer: NodeJS.Timeout | null = null;

    componentDidMount() {
        const { refreshSeconds } = this.props;
        if (refreshSeconds) {
            this.timer = setInterval(this.onRefresh, refreshSeconds * 1000);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
        const { ignoreSmall } = this.props;
        this.tableRef = this.props.tableRef ? this.props.tableRef : React.createRef();

        if (ignoreSmall) {
            return this.renderTableRegular();
        }
        return (
            <>
                <Hidden smUp={true}>
                    {this.renderTableSmall()}
                </Hidden>
                <Hidden smDown={true}>
                    {this.renderTableRegular()}
                </Hidden>
            </>
        );
    }

    private getTheme(large: boolean = true): Theme {
        const { cellStyle } = this.props;

        const tableComponents: StyleComponents<Omit<Theme, 'components'>> = {
            ...styleComponents,
            MuiTable: {
                styleOverrides: {
                    root: {
                        fontWeight: 400,
                        fontSize: 12,
                        fontFamily,
                    },
                },
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                    }
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                    }
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: large ? cellStyle || {
                            padding: 5,
                            textAlign: 'left',
                            fontWeight: 400,
                            fontSize: 12,
                            fontFamily,
                            lineHeight: 'inherit',
                        }
                        : {
                            padding: 16,
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: 12,
                            fontFamily,
                            lineHeight: 'inherit',
                        },
                    footer: {
                        border: 'none',
                        borderRadius: 0,
                    },
                    paddingNone: {
                        padding: '0px !important',
                    }
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        fontSize: 10,
                        fontWeight: 400,
                    },
                    select: {
                        fontSize: 10,
                        fontWeight: 400,
                    },
                    toolbar: {
                        display: 'flex',
                        fontSize: 10,
                        fontWeight: 400,
                    },
                    displayedRows: {
                        fontSize: 10,
                        fontWeight: 400,
                    },
                    selectLabel: {
                        fontSize: 10,
                        fontWeight: 400,
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        width: 20,
                        height: 20,
                    },
                    colorSecondary: {
                        '&$checked': {
                            color: '#0073bb',
                        },
                    }
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        fontSize: '1rem',
                    },
                },
            },
            MuiIcon: {
                styleOverrides: {
                    root: {
                        fontSize: '1.2rem',
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                    },
                    multiline: {
                    },
                    input: {
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        height: 20,
                    },
                    icon: {
                        width: 15,
                        height: 15,
                    },
                    label: {
                        padding: '2px 8px',
                        margin: 'auto',
                    }
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        color: '#0073bb',
                        fontSize: '0.875rem',
                        fontWeight: 600,
                        borderColor: 'rgba(0, 0, 0, 0)',
                        textTransform: 'none',
                        '&:disabled': {
                            borderColor: 'rgba(0, 0, 0, 0)',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&:focus': {
                            backgroundColor: 'transparent',
                        },
                    }
                }
            }
        };
        return createTheme({ components: tableComponents });
    }

    private renderTableSmall() {
        const { style, columns, data, options, actions } = this.props;
        let { showBox } = this.props;
        showBox = showBox !== undefined ? showBox : true;
        const newData: any[] = [];

        if (typeof data !== "function" ) {
            data.forEach(d => {
                newData.push(
                    {
                        ...d,
                        newValue: (this.renderSmallTableData(d, columns, actions)),
                    }
                );
            });
        }

        const tableComponents: Components = {
            ...this.renderComponents(),
            Header: props => <></>,
        };

        const newColumns: any[] = columns.map(c => ({ ...c, hidden: true, searchable: true}));
        newColumns.push({ title: 'Data', field: 'newValue', sorting: false, searchable: false });

        const getData = (query: any) => this.getSmallTableData(query, columns, actions);

        const toShowActions = actions ? actions.filter(action => {
            if (typeof action === "function") {
                return false;
            }
            return action.position === 'toolbarOnSelect' || action.position === 'toolbar';
        }) : undefined;

        return (
            <ThemeProvider theme={this.getTheme(false)}>
                <div
                    style={{
                        width: '100%',
                        boxShadow: showBox ? '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)' : 'none',
                        borderRadius: 0,
                        boxSizing: showBox ? 'border-box' : undefined,
                        backgroundColor: 'white',
                        padding: 0,
                        ...style,
                    }}
                >
                    <MaterialTable
                        {...this.props}
                        tableRef={this.tableRef}
                        columns={newColumns}
                        data={ typeof data !== "function" ? newData : getData}
                        components = {tableComponents}
                        actions={toShowActions}
                        options={{
                            ...options,
                            overflowY: 'hidden',
                        }}
                    />
                </div>
            </ThemeProvider>
        );
    }

    private renderTableRegular() {
        const { style, options } = this.props;
        let { showBox } = this.props;
        showBox = showBox !== undefined ? showBox : true;
        const tableComponents: Components = this.renderComponents();

        const headerStyle = this.getHeaderStyle();

        return (
            <ThemeProvider theme={this.getTheme()}>
                <div
                    style={{
                        width: '100%',
                        boxShadow: showBox ? 'rgb(224 224 224) 0px 0px 1px 1px' : 'none',
                        borderRadius: 0,
                        boxSizing: showBox ? 'border-box' : undefined,
                        backgroundColor: 'white',
                        padding: 0,
                        border: 'none',
                        ...style,
                    }}
                >
                    <MaterialTable
                        {...this.props}
                        tableRef={this.tableRef}
                        components = {tableComponents}
                        options={{
                            ...options,
                            headerStyle,
                            overflowY: 'hidden',
                        }}
                    />
                </div>
            </ThemeProvider>
        );
    }

    private getHeaderStyle(): React.CSSProperties {
        const { options } = this.props;
        const selection = options && options.selection;
        const headerStyle = options && options.headerStyle ? options.headerStyle : {};
        return {
            height: 25,
            padding: selection ? '0px 5px' : '4px 5px',
            color: '#000000b3',
            fontSize: '0.75rem',
            fontWeight: 600,
            lineHeight: '1.3',
            backgroundColor: '#fafafa',
            ...headerStyle,
        };
    }

    private renderComponents(): Components {
        return {
            Toolbar: props => this.renderComponentToolbar(props),
            Actions: props => this.renderComponentActions(props),
            Action: props => this.renderComponentAction(props),
            OverlayLoading: props => this.renderComponentLoading(props),
            Pagination: props => this.renderComponentPagination(props),
            ...this.props.components
        };
    }

    private renderComponentActions(props: any) {
        if (!props.actions) {
            return <></>;
        }
        return (
            <div style={{
                width: '100%',
                display: 'grid',
                gridAutoFlow: 'column',
                justifyContent: 'center',
            }}>
                {_.map(props.actions, action => this.renderDataAction(props.data, action))}
            </div>
        );
    }

    private renderComponentAction(props: any) {
        return (
            <Tooltip title={props.tooltip}>
                <IconButton color="secondary" component="label" disabled={props.disabled} onClick={props.onClick}>
                    <props.icon style={{ width: 20 , height: 20, padding: '0px 10px'}}/>
                </IconButton>
            </Tooltip>
        );
    }

    private renderComponentLoading(props: any) {
        return (
            <div
                style={{
                    display: "table",
                    width: "100%",
                    height: "100%",
                    backgroundColor: alpha('#fafafa', 0.85),
                    borderRadius: 0,
                }}
            >
                <div
                    style={{
                        display: "table-cell",
                        width: "100%",
                        height: "100%",
                        verticalAlign: "middle",
                        textAlign: "center",
                    }}
                >
                    <Loading />
                </div>
            </div>
        );
    }

    private renderComponentPagination(props: any) {
        const {
            count,
            page,
            rowsPerPage,
            rowsPerPageOptions,
        } = props;

        const handleFirstPageButtonClick = (event: any) => {
            props.onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: any) => {
            props.onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: any) => {
            props.onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: any) => {
            props.onPageChange(
                event,
                Math.max(0, Math.ceil(props.count / rowsPerPage) - 1)
            );
        };

        const onChangePerPageOptions = async (event: SelectChangeEvent<unknown>) => {
            props.onRowsPerPageChange(event);
        };

        const from = count === 0 ? 0 : page * rowsPerPage + 1;
        const to = Math.min((page + 1) * rowsPerPage, count);
        const renderValue = (value: any) => (`${value} rows`);

        if (count === 0) {
            return <></>;
        }

        return (
            <div style={{
                    display: 'grid',
                    justifyContent: 'flex-end',
                    gridAutoFlow: 'column',
                    columnGap: '15px',
                    padding: '0px 10px',
                    backgroundColor: 'white',
                    height: 32,
                    alignItems: 'center'
                }}
            >
                <StyledSelect
                    variant={'standard'}
                    value={rowsPerPage}
                    onChange={onChangePerPageOptions}
                    native={false}
                    input={<StyledInput />}
                    style={{
                        width: '100%',
                        height: 30,
                    }}
                    renderValue={renderValue}
                >
                    {rowsPerPageOptions.map((option: any) => (
                        <MenuItem key={option} value={option}>
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </StyledSelect>
                <Tooltip title={'First Page'}>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        size={'medium'}
                        style={{ padding: '8px 0px', height: 30 }}
                    >
                        <FirstPage style={{ height: 20, width: 20 }}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Previous Page'}>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        size={'medium'}
                        style={{ padding: '8px 0px', height: 30 }}
                    >
                        <KeyboardArrowLeft style={{ height: 20, width: 20 }}/>
                    </IconButton>
                </Tooltip>
                <Typography
                    variant="caption"
                    style={{
                        flex: 1,
                        textAlign: "center",
                        alignSelf: "center",
                        flexBasis: "inherit",
                        fontSize: '10px',
                        fontWeight: 500,
                        color: '#0000008c',
                    }}
                >
                    {`${from}-${to} of ${localeNumber(props.count)}`}
                </Typography>
                <Tooltip title={'Next Page'}>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        size={'medium'}
                        style={{ padding: '8px 0px', height: 30 }}
                    >
                        <KeyboardArrowRight style={{ height: 20, width: 20 }}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Last Page'}>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        size={'medium'}
                        style={{ padding: '8px 0px', height: 30 }}
                    >
                        <LastPage style={{ height: 20, width: 20 }}/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    private renderComponentToolbar(props: any) {
        const { title, search, exportButton, actions, showTitle } = props;
        const toShowActions = actions.filter((action: any) => action.position !== 'row');

        if (!showTitle && !search && !exportButton && toShowActions.length === 0) {
            return <></>;
        }


        return (
            <Grid
                container={true}
                spacing={1}
                style={{
                    width: '100%',
                    margin: 'auto',
                    padding: '0px 8px 8px 0px',
                    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
                    backgroundColor: '#fafafa',
                    borderRadius: 0,
                }}
            >
                {showTitle &&
                    <Grid item={true} xs={12}>
                        {_.isString(title) && (
                            <Typography variant="subtitle1" style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                                {title}
                            </Typography>
                        )}
                        {!_.isString(title) && title}
                    </Grid>
                }
                {search &&
                    <Grid item={true} xs={12} sm={5} style={{ margin: 'auto 0px' }}>
                        {this.renderSearch(props)}
                    </Grid>
                }
                {(toShowActions.length > 0 || exportButton) &&
                    <Grid item={true} xs={12} sm={search ? 7 : 12} style={{ margin: 'auto' }}>
                        <Grid container={true} justifyContent="flex-end" alignItems="flex-end" spacing={0}>
                            <Grid item={true} xs={12} justifyContent="flex-end" alignItems="flex-end" style={{ display: 'flex' }}>
                                {_.map(toShowActions, action => {
                                    if (typeof action === "function") {
                                        return <></>;
                                    }
                                    if (action.hidden) {
                                        return <></>;
                                    }
                                    if (action.position === "toolbarOnSelect" && (!props.selectedRows || props.selectedRows.length === 0)) {
                                        return <></>;
                                    }
                                    const handleOnClickAction = (event: any) => {
                                        if (action.onClick) {
                                            action.onClick(event, action.position === "toolbarOnSelect" ? props.selectedRows : props.data);
                                            event.stopPropagation();
                                        }
                                    };

                                    return (
                                        <Tooltip title={action.tooltip}>
                                            <IconButton color="secondary" component="label" disabled={action.disabled} onClick={handleOnClickAction} style={{ padding: '0 5px' }}>
                                                <action.icon style={{ width: 20 , height: 20, padding: '0px 10px'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    );
                                })}
                                {props.exportButton && this.renderExportActions(props)}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }

    private renderSearch(props: any) {
        const isRemoteData = !_.isArray(this.props.data);

        const onSearch = (searchText: string) => {
            props.dataManager.changeSearchText(searchText);
            props.onSearchChanged(searchText);
        };

        return (
            <SearchTextField
                searchText={props.searchText}
                isRemoteData={isRemoteData}
                onSearch={onSearch}
            />
        );
    }

    private renderExportActions(props: any) {
        if (this.props.data.length === 0) {
            return <></>;
        }

        let anchorEl: HTMLElement | undefined;
        const openMenu = (event: any) => {
            anchorEl = event.currentTarget;
            this.setState({ openExportMenu: true });
        };
        const closeMenu = () => this.setState({ openExportMenu: false });
        const exportCsv = () => {
            this.exportCsv(props);
            closeMenu();
        };
        const exportPdf = () => {
            this.exportPdf(props);
            closeMenu();
        };
        const exportJson = () => {
            this.exportJson(props);
            closeMenu();
        };

        const open = this.state.openExportMenu;

        return (
            <div>
                <Tooltip title={'Export'}>
                    <IconButton color="secondary" component="label" onClick={openMenu}>
                        <SaveAlt style={{ width: 20 , height: 20 }}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    id={`menu-title-export`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={closeMenu}
                >
                    <MenuItem color="inherit" value={0} onClick={exportCsv}>
                        {'Export Csv'}
                    </MenuItem>
                    <MenuItem color="inherit" value={1} onClick={exportPdf}>
                        {'Export Pdf'}
                    </MenuItem>
                    <MenuItem color="inherit" value={2} onClick={exportJson}>
                        {'Export Json'}
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    private getTableData = (props: any) => {
        const columns = props.columns
            .filter(
                (columnDef: any) =>
                    (!columnDef.hidden || columnDef.export === true) &&
                    columnDef.export !== false &&
                    columnDef.field
            )
            .sort((a: any, b: any) =>
                a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1
            );
        const data = (props.exportAllData
                ? this.props.data
                : props.renderData
        ).map((rowData: any) =>
            columns.map((columnDef: any) => props.getFieldValue(rowData, columnDef))
        );

        return [columns, data];
    };

    private exportCsv = (props: any) => {
        const [ columns, data ] = this.getTableData(props);
        const fileName = this.getFileName(props);
        const builder = new CsvBuilder(fileName + ".csv");
        builder
            .setDelimeter(props.exportDelimiter)
            .setColumns(columns.map((columnDef: any) => columnDef.title))
            .addRows(data)
            .exportFile();
    };

    private exportPdf = (props: any) => {
        if (jsPDF !== null) {
            const [columns, data] = this.getTableData(props);

            const content = {
                startY: 50,
                head: [columns.map((columnDef: any) => columnDef.title)],
                body: data,
            };

            const unit = "pt";
            const size = "A4";
            const orientation = "landscape";

            const doc = new jsPDF(orientation, unit, size);
            doc.setFontSize(15);
            doc.text(props.exportFileName || this.props.title, 40, 40);
            doc.autoTable(content);
            doc.save(
                (props.exportFileName || this.props.title || "data") + ".pdf"
            );
        }
    };

    private exportJson = (props: any) => {
        const data = _.isArray(this.props.data) ? this.props.data : [];
        const fileName = this.getFileName(props);
        const file = new File([JSON.stringify(data, null, 2)], `${fileName}.json`, { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(file);
    };

    private getFileName = (props: any) => {
        let fileName = this.props.title || "data";
        if (props.exportFileName) {
            fileName =
                typeof props.exportFileName === "function"
                    ? props.exportFileName()
                    : props.exportFileName;
        }
        return fileName;
    };

    private renderDataAction(data: any, action: any, large: boolean = true) {
        if (typeof action === "function") {
            action = action(data);
            if (!action) {
                return <></>;
            }
        }

        if (action.action) {
            action = action.action(data);
            if (!action) {
                return <></>;
            }
        }

        if (action.hidden) {
            return <></>;
        }

        const disabled = action.disabled;

        const handleOnClick = (event: any) => {
            if (action.onClick) {
                action.onClick(event, data);
                event.stopPropagation();
            }
        };

        const icon =
            typeof action.icon === "string" ? (
                <Icon {...action.iconProps} style={{ width: large ? 20 : 25 , height: large ? 20 : 25 }}>{action.icon}</Icon>
            ) : typeof action.icon === "function" ? (
                action.icon({ ...action.iconProps, disabled })
            ) : (
                <action.icon style={{ width: large ? 20 : 25 , height: large ? 20 : 25 }}/>
            );

        const button = (
            <IconButton
                color="inherit"
                disabled={disabled}
                onClick={handleOnClick}
                style={{
                    padding: 0,
                    margin: large ? '0px 5px' : '0px 10px',
                }}
            >
                {icon}
            </IconButton>
        );

        if (action.tooltip) {
            return disabled ? (
                <Tooltip title={action.tooltip}>
                    <span>{button}</span>
                </Tooltip>
            ) : (
                <Tooltip title={action.tooltip}>{button}</Tooltip>
            );
        } else {
            return button;
        }
    }

    private renderSmallTableData(data: any, columns: Column<any>[], actions?: any[]) {
        const rowActions = actions ? actions.filter(action => {
            if (typeof action === "function") {
                return true;
            }
            return action.position === 'row';
        }) : undefined;

        return (
            <Grid container={true} direction="row" justifyContent="center" alignItems="center" spacing={1}>
                {_.map(columns, (column, i) => {
                    const field = column.field;
                    if (typeof field === "string") {
                        return (
                            <>
                                <Grid item={true} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant={'subtitle2'} style={{ fontWeight: 600 }}>
                                        {column.title}
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {column.render ? column.render(data, "row") : data[field]}
                                </Grid>
                            </>
                        );
                    }
                    return <></>;
                })}
                {rowActions && rowActions.length > 0 && (
                    <>
                        <Grid item={true} xs={12} />
                        <Grid item={true} xs={12} />
                        <Grid item={true} xs={12}>
                            {_.map(rowActions, action => this.renderDataAction(data, action, false))}
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private getSmallTableData = async (query: Query<any>, columns: Column<any>[], actions?: any[]) : Promise<QueryResult<any>> => {
        if (typeof this.props.data !== "function" ) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = await this.props.data(query);
        const newData: any[] = [];
        data.data.forEach(d => {
            newData.push(
                {
                    newValue: (this.renderSmallTableData(d, columns, actions)),
                    ...d,
                }
            );
        });
        data.data = newData;
        return data;
    };

    private onRefresh = async () => {
        if (!this.tableRef) {
            return;
        }

        const { data, onRefresh } = this.props;
        if (typeof data !== "function" || !onRefresh) {
            return;
        }

        const current = this.tableRef.current;
        if  (!current) {
            return;
        }

        const query = current.state.query;
        const dataManager = current.dataManager;
        const result = await data(query);
        if(!result || !result.data) {
            return;
        }

        onRefresh(dataManager.data, result.data);
    };
}

const StyledSelect = styled(Select)(({
    '& .MuiSelect-select': {
        paddingTop: 0,
        paddingBottom: 0,
    }
}));

const StyledInput = styled(Input)(({
    '&.MuiInput-root': {
        fontSize: '10px',
        fontWeight: 500,
        color: '#0000008c',
        '&:after': {
            borderBottom: 'none !important',
            '&:hover': {
                borderBottom: 'none !important',
            },
            '&:focus': {
                borderBottom: 'none !important',
            },
        },
        '&:before': {
            borderBottom: 'none !important',
            '&:hover': {
                borderBottom: 'none !important',
            },
            '&:focus': {
                borderBottom: 'none !important',
            },
        },
        '&:hover': {
            borderBottom: 'none !important',
        },
        '&:focus': {
            borderBottom: 'none !important',
        },
    },
}));
