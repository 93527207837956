import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import {
    BaseContainer,
    BaseElement,
    JsonDialog,
    Metadata,
    Table,
} from '../components';
import {
    formatDate,
    formatNumber,
    getLeaderBoardLink,
    getPlayerLink,
    isActive,
    isEnded,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary, parseJson,
} from '../utils';
import { ContainerContext, mapProps } from './index';
import { EventType } from '../enums';
import { Grid, Switch, CardMedia, Typography } from '@mui/material';
import {
    BarChartOutlined,
    CalendarToday as CalendarTodayIcon, Circle,
    EventOutlined,
    ExpandMore,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly liveEventId: string;
    readonly details?: Entities.LiveEvent;
    readonly playersCount?: number;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
}

type DialogType = 'CollectEventRewards' | 'ExtraData';
interface State {
    readonly openDialog: DialogType | null;
    readonly timerFilter: PlayerJoinedChartTime;
    readonly countOfLeaderBoards: number;
    readonly leaderBoardView: boolean;
    readonly extraDataSelected: string;
}

enum PlayerJoinedChartTime {
    None = 0,
    OneMinute,
    TenMinutes,
    FifteenMinutes,
    OneHour,
    TwoHours,
    FourHours,
    SixHours,
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        timerFilter: PlayerJoinedChartTime.None,
        countOfLeaderBoards: 0,
        leaderBoardView: false,
        extraDataSelected: '',
    };

    async componentDidMount() {
        const { liveEventId } = this.props;
        await actions.liveEvent.get(liveEventId);
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Live Event',
                to: `/liveEvent/all`,
            },
            {
                text: this.props.liveEventId,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                title = {'Live Event Details'}
                TitleIcon = {CalendarTodayIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderExtraDataDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid item={true} xs={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid item={true} xs={12}>
                    {this.renderGameData()}
                </Grid>
                <Grid item={true} xs={12}>
                    {this.renderTable()}
                </Grid>
            </Grid>
        );
    };

    private renderDetails = () => {
        const { details, leaderBoardDefinition } = this.props;
        if (!details) {
            return <></>;
        }

        let type: React.JSX.Element = (<></>);
        switch (details.eventType) {
            case EventType.Regular:
                type = <> <EventOutlined style={{width: 20, height: 20}}/> Regular </>;
                break;
            case EventType.LeaderBoard:
                type = <> <BarChartOutlined style={{width: 20, height: 20}}/> LeaderBoard</>;
                break;
            default:
                break;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ backgroundColor: 'white' }}>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid item={true} xs={12} sm={3}>
                            <strong>Type</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9} style={{ display: 'flex', alignItems: 'center'}}>
                            {type}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong>Status</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {this.getStatus(details)}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {details.name}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>Start at</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {formatDate(details.startTimestamp)}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>End at</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {formatDate(details.endTimestamp)}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>Edited by</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {details.username}
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                            <strong style={{width: 250}}>Edited at</strong>
                        </Grid>
                        <Grid item={true} xs={12} sm={9}>
                            {formatDate(details.lastUpdatedTimestamp)}
                        </Grid>
                        {leaderBoardDefinition && (
                            <>
                                <Grid item={true} xs={12} sm={3}>
                                    <strong style={{width: 250}}>Max Users Per Instance</strong>
                                </Grid>
                                <Grid item={true} xs={12} sm={9}>
                                    {leaderBoardDefinition.maxUsersPerInstance}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderGameData = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Metadata
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Metadata
                        text={details.gameData}
                        readOnly={true}
                        style={{ width: '100%' }}
                        keyValueContainerStyle={{ boxShadow: 'none' }}
                        showBox={false}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderTable = () => {
        const { details } = this.props;
        if (!details) {
            return;
        }

        switch (details.eventType) {
            case EventType.Regular:
                return this.showRegularTable();
            case EventType.LeaderBoard:
                return this.showLeaderBoardTypeView();
            default:
                return;
        }
    };

    private showRegularTable = () => {
        const { details } = this.props;
        if (!details) {
            return;
        }

        const columns = [
            { title: 'Player', field: 'player', render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => this.getPlayerView(rowData)},
            { title: 'Joined', field: 'joined', render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => formatDate(rowData.joined) },
            { title: 'Value', field: 'statValue', render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => formatNumber(rowData.statValue) },
            { title: 'Stat', field: 'statName'},
            { title: 'Extra Data', field: 'extraData', sorting: false, render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => parseJson(rowData.extraData, this.onExtraDataSelected)},
        ];
        const ended = isEnded(details.endTimestamp);
        if(ended) {
            columns.push({ title: 'Finished', field: 'finished', render: (rowData: any) => formatDate(rowData.finished)});
            columns.push({ title: 'Rewards Collected', field: 'rewardCollected', render: (rowData: any) => formatDate(rowData.rewardCollected)});
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Players
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Table
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: true,
                            draggable: false,
                        }}
                        data={this.getEventRegularPlayers}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private changeLeaderBoardView = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ leaderBoardView: event.target.checked });
    };

    private showLeaderBoardTypeView = () => {
        const { leaderBoardView } = this.state;

        return (
            <Grid container={true} justifyContent="center" spacing={1}>
                <Grid item={true} xs={12}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Players View</Typography>
                        <Switch
                            checked={leaderBoardView}
                            onChange={this.changeLeaderBoardView}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>LeaderBoards View</Typography>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    <div style={{ margin: 0, width: '100%' }}>
                        {leaderBoardView && this.showLeaderBoardTable()}
                        {!leaderBoardView && this.showRegularTable()}
                    </div>
                </Grid>
            </Grid>
        );
    };

    private showLeaderBoardTable = () => {
        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Created', field: 'created'},
            { title: 'Players Count', field: 'playersCount'}
        ];

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    LeaderBoards
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Table
                        data={this.getLeaderBoardEntities}
                        showBox={false}
                        columns={columns}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: true,
                            draggable: false,
                        }}
                        detailPanel={this.getLeaderBoardEntitiesPanel}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getEventRegularPlayers = async (query: any) => {
        const { liveEventId } = this.props;
        const { details } = this.props;
        if (!details) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(
            `liveEvent/${liveEventId}/getRegularPlayers`,
            {
                page: page + 1,
                perPage: pageSize,
                orderBy: orderBy ? orderBy.field : null,
                orderDirection: orderDirection !== "" ? orderDirection : null,
            },
        );
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        console.log(result.players);
        return {
            data: result.players,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getLeaderBoardEntities = async (query: any) => {
        const { leaderBoardDefinition } = this.props;
        const { liveEventId } = this.props;
        if (!leaderBoardDefinition) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { search, page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(
            `liveEvent/${liveEventId}/getLeaderBoardEntities`,
            {
                filter: search,
                page: page + 1,
                perPage: pageSize,
                orderBy: orderBy ? orderBy.field : null,
                orderDirection: orderDirection !== "" ? orderDirection : null,
            },
        );
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        this.setState({ countOfLeaderBoards: result.totalCount });

        const maxUsersPerInstance = leaderBoardDefinition ? leaderBoardDefinition.maxUsersPerInstance : 0;
        const data = result.statistics.map( (statistic: any) => {
            return {
                name: getLeaderBoardLink(statistic.name),
                created: formatDate(statistic.created),
                playersCount: `${statistic.playersCount}/${maxUsersPerInstance}`,
                statistic,
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getLeaderBoardEntitiesPanel = (rowData: any) => {
        const columns = [
            { title: 'Position', field: 'position'},
            { title: 'Player', field: 'player'},
            { title: 'Value', field: 'value'},
            { title: 'Extra Data', field: 'extraData', sorting: false},
        ];

        const getData = (query: any) => this.getLeaderBoardRanking(rowData.statistic.name, query);

        return (
            <div style={{ margin: 10 }}>
                <Table
                    title={
                        <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                            Rankings
                        </Typography>
                    }
                    columns={columns}
                    options={{
                        selection: false,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 30, 50],
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: false,
                        draggable: false,
                    }}
                    data={getData}
                />
            </div>
        );
    };

    private getLeaderBoardRanking = async (name: string, query: any) => {
        const { page, pageSize } = query;

        const splitNames = name.replace('live_event_', '').split('_');
        const eventId = splitNames[0];
        const result = await ActionRequest.get(`liveEvent/${eventId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.leaderBoard.leaderBoard.map((leaderBoard: any) => {
            return {
                position: `#${leaderBoard.position+1}`,
                player: this.getPlayerView(leaderBoard),
                value: formatNumber(leaderBoard.statValue),
                extraData: parseJson(leaderBoard.extraData, this.onExtraDataSelected),
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getPlayerView = (leaderBoardInfo: Entities.LiveEventPlayerLeaderBoardEntry) => {
        return (
            <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                {leaderBoardInfo.avatarImageUrl === '' && (
                    <>
                        <Grid item={true} xs={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                            <Circle style={{ color: leaderBoardInfo.online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                            {getPlayerLink(leaderBoardInfo.playerId)}
                        </Grid>
                        <Grid item={true} xs={12}>
                            {leaderBoardInfo.displayName}
                        </Grid>
                    </>
                )}
                {leaderBoardInfo.avatarImageUrl !== '' && (
                    <>
                        <Grid item={true} xs={2}>
                            <CardMedia image={leaderBoardInfo.avatarImageUrl} style={{width: 40, height: 40, borderRadius: 0, border: `1px solid #c3cfdd`}}/>
                        </Grid>
                        <Grid item={true} xs={10}>
                            <Grid container={true} justifyContent="center" spacing={0}>
                                <Grid item={true} xs={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                    <Circle style={{ color: leaderBoardInfo.online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                    {getPlayerLink(leaderBoardInfo.playerId)}
                                </Grid>
                                <Grid item={true} xs={12}>
                                    {leaderBoardInfo.displayName}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private getStatus = (liveEvent: Entities.LiveEvent) => {
        if (isActive(liveEvent.startTimestamp, liveEvent.endTimestamp)) {
            return <div style={{color: 'green'}}>In Progress</div>;
        }
        if (isEnded(liveEvent.endTimestamp)) {
            return <div style={{color: 'orange'}}>Completed</div>;
        }
        return <div style={{color: 'grey'}}>Scheduled</div>;
    };

    private onExtraDataSelected = (extraDataSelected: string) => {
        if (extraDataSelected !== '') {
            this.setState({ openDialog: 'ExtraData', extraDataSelected });
        }
    };

    private renderExtraDataDialog = () => {
        const { openDialog, extraDataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'ExtraData'}
                title="Extra Data"
                value={extraDataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            extraDataSelected: '',
        });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    liveEventId: '',
    details: state.liveEvent.details,
    leaderBoardDefinition: state.liveEvent.leaderBoardDefinition,
    playersCount: state.liveEvent.playersCount,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const liveEventId = params.liveEventId || '';
    return (<Container {...props} navigate={navigate} liveEventId={liveEventId}/>);
};
export default connect(mapStateToProps)(AppContainer);
