import * as React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { ContainerContext, mapProps } from './index';
import { UserRole, UserTitleRole } from '../enums';
import { formatDate } from '../utils';
import {
    BaseContainer,
    BaseElement,
    CreateUserDialog,
    WarningDialog,
    Table,
    HeaderButton,
} from '../components';
import {
    AddOutlined,
    DeleteForeverOutlined, SyncOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { Avatar, Grid } from '@mui/material';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly users: Entities.User[];
}

type DialogType = 'Create' | 'Delete' | 'Refresh';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedUser: Entities.User | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedUser: null,
    };

    async componentDidMount() {
        actions.title.resetTitle();
        await actions.admin.getUsers();
    }

    protected renderContainer(): React.JSX.Element {
        const { loading } = this.props;
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Create User', icon: AddOutlined, onClick: this.onCreateAdminUser }
        ];
        return (
            <BaseContainer
                loading = {loading}
                title = ""
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDialog()}
                {this.renderConfirmDeleteDialog()}
            </>
        );
    }

    private onCreateAdminUser = () => this.setState({ openDialog: 'Create' });

    private renderContent = () => {
        const { users, userRole } = this.props;
        const columns = [
            { title: 'User', field: 'data'},
            { title: 'Role', field: 'role'},
            { title: 'Type', field: 'type'},
            { title: 'Last Login', field: 'lastLogin'},
            { title: 'Created', field: 'created'},
        ];
        const data = users.map(
            user => ({
                data: this.getUser(user),
                userName: user.username,
                role: UserRole[user.role],
                type: user.loginType,
                lastLogin: formatDate(user.lastLogin),
                created: formatDate(user.created),
                user,
            })
        );
        return (
            <Table
                data={data}
                columns={columns}
                onRowClick={this.onRowClick}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                }}
                actions={ userRole > UserRole.Developer ?
                    [
                        rowData => ({
                            icon: SyncOutlined,
                            tooltip: 'Refresh Titles',
                            onClick: () => this.onRefreshTitles(rowData.user)
                        }),
                        rowData => ({
                            icon: DeleteForeverOutlined,
                            tooltip: 'Delete',
                            onClick: () => this.setState({ openDialog: 'Delete', selectedUser: rowData.user})
                        }),
                    ]
                    : undefined
                }
            />
        );
    };

    private getUser = (user: Entities.User) => {
        const { username, name, picture } = user;

        return (
            <Grid container={true} justifyContent="center" alignItems="center" spacing={0}>
                <Grid item={true} xs={12} style={{ display: 'grid', gridColumnGap: 10, gridAutoFlow: 'column', justifyContent: 'flex-start'}}>
                    <Avatar
                        alt="Remy Sharp"
                        src={picture}
                        sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: 'rgba(0,0,0,0.025)',
                        }}
                    />
                    {name !== '' && (
                        <Grid container={true}>
                            <Grid item={true} xs={12}>
                                <div style={{ color: 'rgba(0,0,0,.87)', fontSize: 14 }} >
                                    {name}
                                </div>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <div style={{  color: 'rgba(0,0,0,.54)', fontSize: 12 }} >
                                    {username}
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    {name === '' && (
                        <Grid container={true} xs={12}>
                            <Grid item={true} >
                                <div style={{ color: 'rgba(0,0,0,.87)', fontSize: 14 }} >
                                    {username}
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }

    private onRowClick = (event: any, rowData: any) => {
        const selectedUser = this.props.users.find(u => u.username === rowData.username);
        if (selectedUser) {
            this.setState({ openDialog: 'Create', selectedUser });
        }
    };

    private renderDialog() {
        const { loginUser, users } = this.props;
        const { openDialog, selectedUser } = this.state;
        const title = loginUser === selectedUser ? 'Update My Information' : 'Create Admin User';
        if (!loginUser) {
            return null;
        }
        return (
            <CreateUserDialog
                open = {openDialog === 'Create'}
                title={title}
                loginUser={loginUser}
                user={selectedUser}
                users={users}
                onClose={this.closeDialog}
                onCreate={selectedUser ? this.updateUser : this.createUser}
            />
        );
    }

    private renderConfirmDeleteDialog = () => {
        const { openDialog, selectedUser } = this.state;
        if (!selectedUser) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title="Delete"
                content={`This will permanently delete '${selectedUser.name}' user.`}
                onClose={this.closeDialog}
                onSubmit={this.onDeleteUser}
                maxWidth={'xs'}
            />
        );
    };
    private onDeleteUser = async () => {
        const { selectedUser } = this.state;
        this.closeDialog();
        if (!selectedUser) {
            return;
        }
        await actions.admin.deleteUser(selectedUser);
    }

    private closeDialog = () => this.setState({ openDialog: null, selectedUser: null });

    private createUser = async (user: Entities.BaseUser) => {
        this.closeDialog();
        await actions.admin.createUser(user);
    };

    private updateUser = async (user: Entities.BaseUser) => {
        this.closeDialog();
        await actions.admin.updateUser(user);
    };

    private onRefreshTitles = async (user: Entities.BaseUser) => {
        await actions.admin.refreshTitles(user);
    }
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    users: state.user.users,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
