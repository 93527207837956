import * as _ from 'lodash';
import * as React from 'react';

import { InputLabelProps, InputProps as StandardInputProps, TextField as MTextField } from '@mui/material';
import { PropsWithChildren } from 'react';

export interface NumberFieldProps extends PropsWithChildren {
    readonly id?: string;
    readonly margin?: 'dense';
    readonly key?: string;
    readonly className?: string;
    readonly label: string;
    readonly value?: number;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly readOnly?: boolean;
    readonly disabled?: boolean;
    readonly multiline?: boolean;
    readonly rows?: number;
    readonly error?: boolean;
    readonly select?: boolean;
    readonly fullWidth?: boolean;
    readonly InputProps?: Partial<StandardInputProps>;
    readonly InputLabelProps?: Partial<InputLabelProps>;
    readonly defaultValue?: unknown;
    readonly variant?: 'standard' | 'outlined' | 'filled';
    readonly color?: 'primary' | 'secondary';
    readonly onChange?: (val: number) => void;
}

export class NumberField extends React.PureComponent<NumberFieldProps> {
    render() {
        const { value, variant, color, children, margin } = this.props;
        return (
            <MTextField
                {..._.pick(
                    this.props,
                    'id',
                    'key',
                    'className',
                    'label',
                    'placeholder',
                    'required',
                    'disabled',
                    'multiline',
                    'rows',
                    'error',
                    'select',
                    'fullWidth',
                    'InputLabelProps',
                    'defaultValue',
                    'InputProps',
                    'readOnly',
                )}
                value={value || 0}
                onChange={this.onChange}
                margin={margin || 'none'}
                color={color || 'primary'}
                variant={variant || 'standard'}
                type={'number'}
            >
                {children}
            </MTextField>
        );
    }

    private onChange = (eventObject: any) => {
        let val = eventObject.target.value;
        const { value, onChange } = this.props;
        if (typeof value !== 'number') {
            return;
        }
        val = +val;
        if (isNaN(val)) {
            return;
        }
        if (onChange) {
            onChange(val);
        }
    };
}
