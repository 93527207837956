import * as React from 'react';
import GoogleButton from 'react-google-button';
import {
    useGoogleLogin,
    UseGoogleLoginProps,
} from 'react-google-login';

export function GoogleLoginButton (props: UseGoogleLoginProps)
{
    const { signIn } = useGoogleLogin({...props});
    return (<GoogleButton onClick={signIn} />);
}
