import * as React from 'react';
import {
    Button,
    Metadata,
    NumberField,
} from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly season: Entities.Season | null;
    readonly onEdit: (seasonId: string, metadata: string, maxAmountPerReport: number) => void;
}

interface State extends BaseDialogState {
    readonly metadata: string;
    readonly maxAmountPerReport: number;
}

export class EditLiveSeasonDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidMount() {
        const { season } = this.props;
        if (!season) {
            return;
        }
        this.setState({
            metadata: season.metadata,
            maxAmountPerReport: season.maxAmountPerReport,
        });
    }

    componentDidUpdate(prevProps: Props) {
        const { season } = this.props;
        if (!season) {
            return;
        }
        if (season === prevProps.season) {
            return;
        }

        this.setState({
            metadata: season.metadata,
            maxAmountPerReport: season.maxAmountPerReport,
        });
    }

    protected renderContent(): React.JSX.Element {
        const { metadata, maxAmountPerReport } = this.state;

        return (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                        style={{ padding: '10px 0px' }}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Max Amount Per Report</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <NumberField
                        label=""
                        value={maxAmountPerReport}
                        onChange={this.handleMaxAmountPerReport}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validate()}/>
        );
    }

    protected initState(): State {
        return {
            metadata: '',
            maxAmountPerReport: 0,
        };
    }

    private onSubmit = () => {
        const { season } = this.props;
        if (!season) {
            return;
        }
        const { metadata, maxAmountPerReport } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onEdit(season.id, metadata, maxAmountPerReport);
        this.onClose();
    };

    private validate(): boolean {
        const { season } = this.props;
        const { metadata, maxAmountPerReport } = this.state;
        if (!season) {
            return false;
        }
        return season.metadata !== metadata || season.maxAmountPerReport !== maxAmountPerReport;
    }

    private handleChangeMetadata = (metadata: string) => this.setState({ metadata });

    private handleMaxAmountPerReport = (maxAmountPerReport: number) => this.setState({ maxAmountPerReport });
}
