import { LiveEventActionType, ListEventFilterStateType, LeaderBoardType } from '../enums';
import { Request } from '../utils';
import { dispatch } from '../reducers';

export const list = async (
    filterState: ListEventFilterStateType = ListEventFilterStateType.All
) => {
    const response = await Request.get<Entities.LiveEvent>(`liveEvent/list`, { filterState });
    dispatch({
        type: LiveEventActionType.Update,
        payload: {
            list: response,
        },
    });
};

export const get = async (liveEventId: string) => {
    const liveEventDetail = await Request.get(`liveEvent/get/${liveEventId}`);
    dispatch({
        type: LiveEventActionType.Update,
        payload: {
            details: liveEventDetail.liveEvent,
            leaderBoardDefinition: liveEventDetail.leaderBoardDefinition,
        },
    });
};

export const getAndList = async (
    liveEventId: string,
    filterState: ListEventFilterStateType = ListEventFilterStateType.All
) => {
    const [listLiveEvent, liveEventDetail] = await Promise.all([
        Request.get<Entities.LiveEvent>(`liveEvent/list`, { filterState }),
        Request.get(`liveEvent/get/${liveEventId}`)
    ]);
    dispatch({
        type: LiveEventActionType.Update,
        payload: {
            list: listLiveEvent,
            details: liveEventDetail.liveEvent,
            leaderBoardDefinition: liveEventDetail.leaderBoardDefinition,
        },
    });
};

export const createEventRegular = async(
    name: string,
    gameData: string,
    start: number,
    end: number,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
) => {
    await Request.post(
        `liveEvent/createEventRegular`,
        {
            name,
            gameData,
            start,
            end,
            scheduleColor,
            developerOnly,
            finishActions,
        },
    );
};

export const createEventLeaderBoard = async(
    name: string,
    gameData: string,
    start: number,
    end: number,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
    ranks: Entities.EntityLeaderBoardRank[],
    maxUsersPerInstance: number,
    leaderBoardType: LeaderBoardType,
) => {
    await Request.post(
        `liveEvent/createEventLeaderBoard`,
        {
            name,
            gameData,
            start,
            end,
            scheduleColor,
            developerOnly,
            maxUsersPerInstance,
            leaderBoardType,
            ranks,
            finishActions,
        },
    );
};

export const updateEventRegular = async (
    eventId: string,
    name: string,
    gameData: string,
    start: number,
    end: number,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
) => {
    await Request.post(
        `liveEvent/${eventId}/updateEventRegular`,
        {
            name,
            gameData,
            start,
            end,
            scheduleColor,
            developerOnly,
            finishActions,
        },
    );
};

export const updateEventLeaderBoard = async (
    eventId: string,
    name: string,
    gameData: string,
    start: number,
    end: number,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
    ranks: Entities.EntityLeaderBoardRank[],
    maxUsersPerInstance: number,
) => {
    await Request.post(
        `liveEvent/${eventId}/updateEventLeaderBoard`,
        {
            name,
            gameData,
            start,
            end,
            scheduleColor,
            developerOnly,
            maxUsersPerInstance,
            ranks,
            finishActions,
        },
    );
};

export const remove = async (eventId: string) => {
    await Request.post(`liveEvent/${eventId}/delete`);
};

export const collectPlayerRewards = async (eventId: string, actions: Entities.AutomaticPlayerAction[]) => {
    await Request.post(`liveEvent/${eventId}/collectPlayerRewards`, { actions });
};
