import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    BaseElement,
    CreateEconomyStoreItemComponent,
} from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { Paid as PaidIcon } from '@mui/icons-material';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly items: Entities.EconomyItem[];
    readonly segments: Entities.Segment[];
    readonly currencies: Entities.Currency[];
}

class Container extends BaseElement<Props> {

    async componentDidMount() {
        await actions.economy.getCreateStoreData();
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Store',
                to: `/economy/store`,
            },
            {
                text: 'New',
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                title = {"New Economy Store Item"}
                TitleIcon = {PaidIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { items, segments, currencies } = this.props;

        return (
            <CreateEconomyStoreItemComponent
                type={'Create'}
                onCreate={this.onCreate}
                onCancel={this.toEconomyStore}
                items={items}
                segments={segments}
                currencies={currencies}
            />
        );
    }

    private onCreate = async (displayName: string, metadata: string, productId: string, purchaseLimit: number, start: number | null, end: number | null, segmentIds: string[], items: string[], virtualCurrencyPrices: string | null) => {
        await actions.economy.createStoreItem(displayName, metadata, productId, purchaseLimit, start, end, segmentIds, items, virtualCurrencyPrices);
        this.toEconomyStore();
    };

    private toEconomyStore = () => this.toLink(`/economy/store`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    items: state.economy.items,
    segments: state.segment.segments,
    currencies: state.economy.currencies,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
