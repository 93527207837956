import * as React from 'react';
import { ContainerContext } from '../../containers';
import { BaseContainer, CommonHeaderProps } from '../common';
import { toLink } from '../../utils';
import { Loyalty as LoyaltyIcon } from '@mui/icons-material';
import { PropsWithChildren } from 'react';

interface Props extends ContainerContext.Props, CommonHeaderProps, PropsWithChildren {
    readonly showSubTabs?: boolean;
}

export class BaseCostumerSupportCompensationContainer extends React.Component<Props> {
    render() {
        const { loading, headline, headlines, children, buttons, showSubTabs } = this.props;

        const subTabsTitles = ['PreDefined Items', 'Bundles'];

        if(showSubTabs) {
            return (
                <BaseContainer
                    loading= {loading}
                    title = {'Compensations'}
                    TitleIcon={LoyaltyIcon}
                    headline = {headline}
                    headlines = {headlines}
                    subTabs={{
                        titles: subTabsTitles,
                        selected: this.subTabsSelected(),
                        onChange: this.changeSubTabs,
                    }}
                    buttons={buttons}
                >
                    {children}
                </BaseContainer>
            );
        }

        return (
            <BaseContainer
                loading= {loading}
                title = {'Compensations'}
                headline = {headline}
                headlines = {headlines}
                buttons={buttons}
            >
                {children}
            </BaseContainer>
        );
    }

    private subTabsSelected = () => {
        if (location.pathname.includes(this.getSubPath('preDefinedItems'))) {
            return 0;
        } else if (location.pathname.includes(this.getSubPath('bundles'))) {
            return 1;
        }

        return 0;
    };

    private changeSubTabs = (tab: number) => {
        let to = '';
        switch (tab) {
            case 0:
                to = this.getSubPath('preDefinedItems');
                break;
            case 1:
                to = this.getSubPath('bundles');
                break;
        }
        toLink(this.props, to);
    };

    private getSubPath = (component: string) => {
        return `/compensation/${component}`;
    };
}
