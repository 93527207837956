import { Request } from '../utils';

export const create = async (data: Entities.Achievement) => {
    await Request.post(`achievement/create`, data);
};

export const update = async (data: Entities.Achievement) => {
    await Request.post(`achievement/update`, data);
};

export const remove = async (id: string) => {
    await Request.post(`achievement/delete`, { id });
};
