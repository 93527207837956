import { CloudCodeActionType } from '../enums';
import { Request } from '../utils';
import { dispatch } from '../reducers';

export const get = async (revision: number) => {
    const data = await Request.get(`cloudCode/get/${revision}`);
    dispatch({
        type: CloudCodeActionType.Update,
        payload: data,
    });
};

export const upload = async (file: any, isLive: boolean) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("isLive", isLive.toString());
    await Request.postForm(`cloudCode/upload`, formData);
};

export const activate = async (revision: number) => {
    await Request.post(`cloudCode/${revision}/activate`);
};

export const getMethods = async (revision?: number) => {
    const data = await Request.get(`cloudCode/${revision}/getMethods`);
    dispatch({
        type: CloudCodeActionType.Update,
        payload: data,
    });
};
