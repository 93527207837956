import * as React from 'react';
import { TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onCreate: (code: string, name: string) => void;
}

interface State extends BaseDialogState {
    readonly code: string;
    readonly name: string;
}

export class CreateCurrencyDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { code, name } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Currency code (2 uppercase characters)</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        error={!this.validateCode()}
                        value={code}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.updateCode}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Display name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        error={!this.validateName()}
                        value={name}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.updateName}
                        disabled={false}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="text" color="secondary" disabled={!this.validateCode() || !this.validateName()} />
        );
    }

    private updateCode = (code: string) => this.setState({ code });
    private updateName = (name: string) => this.setState({ name });

    private validateCode(): boolean {
        const { code } = this.state;
        if (!code) {
            return false;
        }
        if (code.length !== 2) {
            return false;
        }
        return true;
    }

    private validateName(): boolean {
        const { name } = this.state;
        if (!name) {
            return false;
        }
        return true;
    }

    private onCreate = () => {
        const { code, name } = this.state;
        if (!this.validateCode() || !this.validateName()) {
            return;
        }
        this.props.onCreate(code, name);
        this.onClose();
    };

    protected initState(): State {
        return {
            code: '',
            name: '',
        };
    }
}
