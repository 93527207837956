import { Action } from './index';
import { TitleActionType, CdnActionType, AppActionType } from '../enums';

export interface CdnState {
    readonly versions: Entities.FileVersion[];
    readonly selectedVersion: Entities.FileVersion | null;
    readonly items: Entities.FileVersionItem[];
    readonly canRemove: boolean;
}

export type CdnAction = Action<Partial<CdnState>>;

const initState: CdnState = {
    versions: [],
    selectedVersion: null,
    items: [],
    canRemove: true,
};

const reducer = (state: CdnState = initState, action: CdnAction): CdnState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case CdnActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
