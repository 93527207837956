import * as React from 'react';
import actions, { ActionRequest } from '../actions';
import {
    AlertDialog,
    WarningDialog,
    CreateTitleDataDialog,
    BaseContainer,
    Table,
    BaseElement,
    EditorDialog,
    ImportJsonDialog,
    HeaderButton,
} from '../components';
import { formatJSON, getTitleDataVersionsViews, parseJson } from '../utils';
import {
    CloudUploadOutlined,
    CloudDownloadOutlined,
    AddOutlined,
    DeleteOutlined,
    Description as DescriptionIcon,
    CheckCircleOutlineOutlined,
} from '@mui/icons-material';
import { connect } from 'react-redux';
import { ContainerContext, mapProps } from './index';
import {
    FormControl,
    Grid,
    OutlinedInput,
    Select, SelectChangeEvent,
} from '@mui/material';
import { UserTitleRole } from '../enums';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly version: string;
    readonly versions: Entities.TitleData[];
    readonly titleData: Entities.TitleData | null;
    readonly canRemove: boolean;
}

type DialogType = 'NewVersion' | 'Export' | 'Import' | 'Description' | 'ConfirmSetAsDefault' | 'ConfirmDelete' | 'ConfirmKeyDelete';

interface State {
    readonly openDialog: DialogType | null;
    readonly singleSelectedData: Entities.TitleDataItem | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        singleSelectedData: null,
    };

    async componentDidMount() {
        const { version } = this.props;
        await actions.titleData.get(version);
    }

    protected renderContainer(): React.JSX.Element {
        const { titleData, canRemove } = this.props;
        let buttons: HeaderButton[] | undefined;
        if (this.props.userTitleRole > UserTitleRole.Viewer) {
            buttons = [];
            if (titleData && !titleData.isDefault) {
                buttons.push({ text: 'Set As Default', icon: CheckCircleOutlineOutlined, onClick: this.openSetAsDefaultConfirmDialog});
            }
            buttons.push({ text: 'New Version', icon: AddOutlined, onClick: this.openNewVersionDialog});
            if (canRemove) {
                buttons.push({ text: 'Delete', icon: DeleteOutlined, onClick: this.openConfirmDeleteDialog, color: 'error'});
            }
        }
        return (
            <BaseContainer
                {...this.props}
                title = {"Title Data"}
                TitleIcon = {DescriptionIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderNewVersionDialog()}
                {this.renderDescriptionDialog()}
                {this.renderExportDialog()}
                {this.renderImportDialog()}
                {this.renderSetAsDefaultConfirmDialog()}
                {this.renderConfirmDeleteDialog()}
                {this.renderConfirmKeyDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { versions, titleData, userTitleRole } = this.props;
        if (!titleData) {
            return;
        }

        const columns = [
            { title: 'Key', field: 'key'},
            { title: 'Value', field: 'value'},
        ];

        const titleDataVersionsViews = getTitleDataVersionsViews(versions, false);
        return (
            <Grid container={true} justifyContent="center" spacing={2}>
                <Grid item={true} xs={12}>
                    <FormControl variant="outlined" style={{ width: '100%', height: '100%', borderRadius: 4, backgroundColor: 'white', }}>
                        <Select
                            native={true}
                            value={titleData.version}
                            onChange={this.onChangeVersion}
                            input={<OutlinedInput style={{ border: 'none'}}/>}
                            style={{
                                width: '100%',
                                height: '100%',
                                color: 'rgba(0, 0, 0, 0.8)',
                                borderRadius: 0,
                                boxSizing: 'border-box',
                                fontSize: '14px',
                                backgroundColor: 'white',
                                boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                                border: 'none',
                            }}
                        >
                            {titleDataVersionsViews}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item={true} xs={12}>
                    <Table
                        data={this.getData}
                        columns={columns}
                        onRowClick={this.onRowClick}
                        options={{
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: false,
                            draggable: false,
                            showTitle: false,
                        }}
                        actions={[
                            {
                                hidden: userTitleRole === UserTitleRole.Viewer,
                                tooltip: 'New',
                                position: 'toolbar',
                                icon: AddOutlined,
                                onClick: this.openCreateDialog,
                            },
                            {
                                hidden: userTitleRole === UserTitleRole.Viewer,
                                tooltip: 'Upload',
                                position: 'toolbar',
                                icon: CloudUploadOutlined,
                                onClick: this.openImportDialog,
                            },
                            {
                                tooltip: 'Download',
                                position: 'toolbar',
                                icon: CloudDownloadOutlined,
                                onClick: this.openExportDialog,
                            },
                            {
                                hidden: userTitleRole === UserTitleRole.Viewer,
                                tooltip: 'Remove All',
                                position: 'toolbar',
                                icon: DeleteOutlined,
                                onClick: this.onDeleteClick,
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { titleData } = this.props;
        if (!titleData) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`titleData/${titleData.version}/listItems`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.TitleDataItem) => {
            return {
                key: e.key,
                value: parseJson(e.value),
                titleDataItem: e,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private onChangeVersion = async (event: SelectChangeEvent) => {
        const { titleData } = this.props;
        const version = event.target.value as string;
        if (!titleData || titleData.version === version) {
            return;
        }
        await actions.titleData.get(version);
    };

    private onRowClick = (event: any, rowData: any) => {
        const selectedData = rowData.titleDataItem;
        if (selectedData) {
            this.setState({ openDialog: 'Description', singleSelectedData: selectedData });
        }
    };

    private openNewVersionDialog = () => this.setState({ openDialog: 'NewVersion' });
    private renderNewVersionDialog = () => {
        const { versions } = this.props;
        const { openDialog } = this.state;
        return (
            <CreateTitleDataDialog
                open={openDialog === 'NewVersion'}
                title={'Create New Title Data Version'}
                TitleIcon = {DescriptionIcon}
                versions={versions}
                onClose={this.closeDialog}
                onCreate={this.onNewVersion}
            />
        );
    };

    private openCreateDialog = () => this.setState({ openDialog: 'Description' });
    private renderDescriptionDialog = () => {
        const { openDialog, singleSelectedData } = this.state;
        if (!singleSelectedData) {
            return <></>;
        }
        const { key, value } = singleSelectedData;

        return (
            <EditorDialog
                open={openDialog === 'Description'}
                title="Update Title Data"
                TitleIcon={DescriptionIcon}
                id={key}
                value={formatJSON(value)}
                onClose={this.closeDialog}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    };

    private openExportDialog = () => this.setState({ openDialog: 'Export' });
    private renderExportDialog = () => {
        const { openDialog } = this.state;
        return (
            <AlertDialog
                open={openDialog === 'Export'}
                title="Export Title Data"
                TitleIcon = {DescriptionIcon}
                content="Export the title data?"
                onClose={this.closeDialog}
                submitButtonText={'Export'}
                onSubmit={this.exportTitleData}
            />
        );
    };

    private openImportDialog = () => this.setState({ openDialog: 'Import' });
    private renderImportDialog = () => {
        const { openDialog } = this.state;
        return (
            <ImportJsonDialog
                open={openDialog === 'Import'}
                title="Import Title Data"
                TitleIcon = {DescriptionIcon}
                content="Import the title data from JSON file."
                dropzoneText={'Drop exported player data.'}
                onClose={this.closeDialog}
                submitButtonText={'Import'}
                onImport={this.importTitleData}
            />
        );
    };

    private openSetAsDefaultConfirmDialog = () => this.setState({ openDialog: 'ConfirmSetAsDefault' });
    private renderSetAsDefaultConfirmDialog = () => {
        const { openDialog } = this.state;
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }
        return (
            <AlertDialog
                open={openDialog === 'ConfirmSetAsDefault'}
                title="Set as Default"
                TitleIcon = {DescriptionIcon}
                content={`Are you sure you want set ${titleData.version} as default`}
                submitButtonText={'Confirm'}
                onClose={this.closeDialog}
                onSubmit={this.onSetAsDefault}
            />
        );
    };

    private openConfirmDeleteDialog = () => this.setState({ openDialog: 'ConfirmDelete' });
    private renderConfirmDeleteDialog = () => {
        const { openDialog } = this.state;
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title="Delete Title Data"
                TitleIcon = {DescriptionIcon}
                content={`Are you sure you want to delete the ${titleData.version} title data`}
                onClose={this.closeDialog}
                onSubmit={this.delete}
                maxWidth={'xs'}
            />
        );
    };

    private renderConfirmKeyDeleteDialog = () => {
        const { openDialog } = this.state;
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'ConfirmKeyDelete'}
                title="Delete Keys"
                TitleIcon = {DescriptionIcon}
                content={`This will permanently delete all items.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteTitleDataKeys}
                maxWidth={'xs'}
            />
        );
    };

    private onNewVersion = async (version: string, isDefault: boolean) => {
        await actions.titleData.create(version, isDefault);
    };

    private onSetAsDefault = async () => {
        this.closeDialog();
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }
        await actions.titleData.setAsDefault(titleData.version);
        await actions.titleData.get();
    }

    private delete = async () => {
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }
        await actions.titleData.remove(titleData.version);
        await actions.titleData.get();
    }

    private onEdit = async (key: string, value: string) => {
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }

        await actions.titleData.updateItem(titleData.version, key, value);
    };

    private onDeleteClick = async () => {
        this.setState({openDialog: 'ConfirmKeyDelete'});
    };

    private deleteTitleDataKeys = async () => {
        this.closeDialog();
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }

        await actions.titleData.removeAllItems(titleData.version);
    };

    private onDelete = async (key: string) => {
        this.closeDialog();
        if (!key) {
            return;
        }
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }

        await actions.titleData.removeItem(titleData.version, key);
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            singleSelectedData: null,
        });

    private exportTitleData = async () => {
        this.closeDialog();
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }

        await actions.titleData.exportItems(titleData.version);
    };

    private importTitleData = async (data: string) => {
        this.closeDialog();
        const { titleData } = this.props;
        if (!titleData) {
            return;
        }
        await actions.titleData.importItems(titleData.version, JSON.parse(data));
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    version: '',
    versions: state.titleData.versions,
    titleData: state.titleData.titleData,
    canRemove: state.titleData.canRemove,
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const version = params.version || '';
    return (<Container {...props} navigate={navigate} version={version}/>);
};
export default connect(mapStateToProps)(AppContainer);
