import { AppActionType } from '../enums';
import { Request, LocalStorage } from '../utils';
import { dispatch, getState } from '../reducers';

export const getInfo = async () => {
    const { title } = LocalStorage.get();
    const { appContainer } = LocalStorage.get();
    const showDrawer = appContainer && appContainer.showDrawer;
    const smDrawerOpen = appContainer && appContainer.smDrawerOpen;
    const { user = null } = await Request.get('logincheck', undefined, undefined, false);
    const current = await Request.get('environment/get', undefined, undefined, false);

    let titles: Entities.UserTitleData[] | null = null;
    if (user) {
        titles = await Request.get('title/list', undefined, undefined, false);
    }

    dispatch({
        type: AppActionType.Init,
        payload: {
            current,
            selectedTitle: title,
            loginUser: user,
            showDrawer,
            smDrawerOpen,
            titles,
        },
    });
};

export const changeShowDrawer = () => {
    const showDrawer = !getState().app.showDrawer;
    LocalStorage.set('appContainer', { showDrawer });
    dispatch({
        type: AppActionType.Update,
        payload: { showDrawer },
    });
};

export const changeSmDrawerOpen = () => {
    const smDrawerOpen = !getState().app.smDrawerOpen;
    LocalStorage.set('appContainer', { smDrawerOpen });
    dispatch({
        type: AppActionType.Update,
        payload: { smDrawerOpen },
    });
};
