import { Request } from '../utils';
import { dispatch } from '../reducers';
import { GroupActionType, PlayerGroupState } from '../enums';

export const get = async (groupId: string): Promise<void> => {
    const group: Entities.Group | null = await Request.get(`group/${groupId}/get`);
    dispatch({
        type: GroupActionType.Update,
        payload: {
            details: group,
        },
    });
}

export const create = async (name: string, privacy: number, description: string, maxPlayersCount: number, metadata: string) => {
    await Request.post(`group/create`, { name, privacy, description, maxPlayersCount, metadata });
};

export const update = async (groupId: string, name: string, privacy: number, description: string, maxPlayersCount: number, metadata: string) => {
    await Request.post(`group/${groupId}/update`, { name, privacy, description, maxPlayersCount, metadata });
};

export const remove = async (groupId: string) => {
    await Request.post(`group/${groupId}/delete`);
};

export const join = async (groupId: string, playerId: string) => {
    await Request.post(`group/${groupId}/join`, { playerId });
};

export const apply = async (groupId: string, playerId: string) => {
    await Request.post(`group/${groupId}/apply`, { playerId });
};

export const approveApplicant = async (groupId: string, playerId: string) => {
    await Request.post(`group/${groupId}/approveApplicant`, { playerId });
};

export const rejectApplicant = async (groupId: string, playerId: string) => {
    await Request.post(`group/${groupId}/rejectApplicant`, { playerId });
};

export const promotePlayer = async (groupId: string, playerId: string, state: PlayerGroupState) => {
    await Request.post(`group/${groupId}/promotePlayer`, { playerId, state });
};

export const demotePlayer = async (groupId: string, playerId: string, state: PlayerGroupState) => {
    await Request.post(`group/${groupId}/demotePlayer`, { playerId, state });
};

export const removePlayer = async (groupId: string, playerId: string) => {
    await Request.post(`group/${groupId}/removePlayer`, { playerId });
};