import React, { CSSProperties, PropsWithChildren } from 'react';
import { Link } from '../../utils';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
    CardMedia,
    Collapse,
    Divider,
    Grid,
    Tooltip,
    Typography,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer as DrawerCore,
    List,
    Theme,
} from '@mui/material';
import {
    CalendarToday as CalendarTodayIcon,
    Category as CategoryIcon,
    Code as CodeIcon,
    Paid as PaidIcon,
    Description as DescriptionIcon,
    EmojiEvents as EmojiEventsIcon,
    ExpandLess,
    ExpandMore,
    Newspaper as NewspaperIcon,
    Games as GamesIcon,
    GroupWork as GroupWorkIcon,
    Home as HomeIcon,
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
    Notes as NotesIcon,
    Group as GroupIcon,
    Poll as PollIcon,
    Public as PublicIcon,
    Settings as SettingsIcon,
    SvgIconComponent,
    Timeline as TimelineIcon,
    CardGiftcard as CardGiftcardIcon,
    Apple as AppleIcon,
    Build as BuildIcon,
    Loyalty as LoyaltyIcon,
} from '@mui/icons-material';
import { Button } from './input';
import { UserTitleRole } from '../../enums';
import { styled } from '@mui/styles';
import { ListItemTextProps } from '@mui/material/ListItemText/ListItemText';
import { SxProps } from '@mui/system';
import actions from '../../actions';

export interface DrawerProps {
    readonly variant: 'permanent' | 'temporary';
    readonly openDrawer: boolean;
    readonly expanded: boolean;
    readonly userTitleRole: UserTitleRole;
    readonly sx?: SxProps<Theme>;
}

interface State {
    readonly anchorEl: HTMLElement | undefined;
    readonly buildMenuOpen: boolean;
    readonly liveOpsMenuOpen: boolean;
    readonly csMenuOpen: boolean;
    readonly devOpsMenuOpen: boolean;
    readonly marketingMenuOpen: boolean;
}

export class Drawer extends React.Component<DrawerProps, State> {

    state = {
        anchorEl: undefined,
        buildMenuOpen: window.location.href.match(/\/players\/|\/segments\/|\/clientVersion\//g) !== null,
        liveOpsMenuOpen: window.location.href.match(/\/group\/|\/cloudCode\/|\/economy\/|\/currency\/|\/titleData\/|\/titleNews\/|\/files\/|\/achievements\/|\/liveEvent\/|\/season\/|\/leaderBoard\//g) !== null,
        csMenuOpen: window.location.href.match(/\/analyzePlayersData\/|\/compensation\/|\/appleSupport\//g) !== null,
        devOpsMenuOpen: window.location.href.match(/\/cloudBuild\//g) !== null,
        marketingMenuOpen: window.location.href.match(/\/marketing\//g) !== null,
    };

    render() {
        const { variant, openDrawer, expanded, sx } = this.props;

        return (
            <DrawerCore
                variant={variant}
                open={openDrawer}
                onClose={variant === 'permanent' ? undefined : this.onClickSmallDrawer}
                sx={sx}
            >
                <StyledTitleLink to={'/'} >
                    <Grid container={true} justifyContent="center" alignItems="center" spacing={0} onClick={ variant === 'permanent' ? undefined : this.onClickSmallDrawer }>
                        <Grid item={true} xs={12} sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gridColumnGap: 10}}>
                            <CardMedia
                                sx={{ width: 40, height: 40, backgroundSize: 39, margin: '0px !important' }}
                                image={`${process.env.PUBLIC_URL}/images/nucleo-logo.png`}
                                title="Logo"
                            />
                            {expanded && (
                                <Typography children={'Nucleo Portal'} variant="h1" color="inherit" sx={{ fontSize: '1rem', fontWeight: 600}}/>
                            )}
                        </Grid>
                    </Grid>
                </StyledTitleLink>
                <StyledDivider />
                {expanded ? this.renderWideList() : this.renderNarrowList()}
                <StyledDivider />
                <div style={{
                        display: 'flex',
                        padding: '8px 5px',
                        backgroundColor: '#141414',
                        width: 'calc(100% - 10px)',
                        justifyContent: expanded ? 'flex-end' : 'center',
                    }}
                >
                    <ArrowButton
                        icon={ expanded ? KeyboardArrowLeftOutlined : KeyboardArrowRightOutlined }
                        variant='text'
                        onClick={ variant === 'permanent' ? this.onClickDrawerIcon : this.onClickSmallDrawer }
                    />
                </div>
            </DrawerCore>
        );
    }

    private renderWideList = () => {
        const { buildMenuOpen, liveOpsMenuOpen, csMenuOpen, devOpsMenuOpen, marketingMenuOpen } = this.state;

        return (
            <DivStyled>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {this.renderDrawerItem('Project Overview', `/dashboard`, HomeIcon, undefined, undefined, { height: '56px' })}
                    <Divider orientation="vertical" style={{ height: 20, backgroundColor: '#ffffff1a' }}/>
                    {this.renderDrawerItem('', `/settings/general`, SettingsIcon, 'settings/', 'Settings', { height: '56px' })}
                </div>
                <StyledDivider />
                <WideStyledList>
                    <StyledListItem open={buildMenuOpen} onClick={this.handleClickBuild} style={{ borderRadius: 0}}>
                        <StyledListItemText primary="Build"/>
                        {buildMenuOpen ? (<StyledExpandLess/>) : (<StyledExpandMore/> )}
                    </StyledListItem>
                    <Collapse in={buildMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Players', `/players/all`, GroupIcon, 'players/')}
                            {this.renderDrawerItem('Segments', `/segments/all`, CategoryIcon, 'segments/')}
                            {this.renderDrawerItem('Client Versions', `/clientVersion/all`, NotesIcon, 'clientVersion/')}
                        </ListNested>
                    </Collapse>
                    <StyledWideListDivider />
                    <StyledListItem open={liveOpsMenuOpen} onClick={this.handleClickLiveOps}>
                        <StyledListItemText primary="Live Ops"/>
                        {liveOpsMenuOpen ? (<StyledExpandLess/>) : (<StyledExpandMore/> )}
                    </StyledListItem>
                    <Collapse in={liveOpsMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('CloudCode', `/cloudCode/all`, CodeIcon, 'cloudCode/')}
                            {this.renderDrawerItem('Groups', `/group/all`, GroupWorkIcon, 'group/')}
                            {this.renderDrawerItem('Economy', `/economy/items`, PaidIcon, 'economy/')}
                            {this.renderDrawerItem('Title Data', `/titleData/default`, DescriptionIcon, 'titleData/')}
                            {this.renderDrawerItem('Title News', `/titleNews/all`, NewspaperIcon, 'titleNews/')}
                            {this.renderDrawerItem('Cloud Content Delivery', `/files/default`, PublicIcon, 'files/')}
                            {this.renderDrawerItem('Achievements', `/achievements/all`, EmojiEventsIcon, 'achievements/')}
                            {this.renderDrawerItem('Live Events', `/liveEvent/all`, CalendarTodayIcon, 'liveEvent/')}
                            {this.renderDrawerItem('Seasons', `/season/all`, GamesIcon, 'season/')}
                            {this.renderDrawerItem('LeaderBoard', `/leaderBoard/all`, PollIcon, 'leaderBoard/')}
                        </ListNested>
                    </Collapse>
                    <StyledWideListDivider />
                    <StyledListItem open={csMenuOpen} onClick={this.handleClickCostumerSupport}>
                        <StyledListItemText primary="Costumer Support" />
                        {csMenuOpen ? ( <StyledExpandLess/>) : (<StyledExpandMore/> )}
                    </StyledListItem>
                    <Collapse in={csMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Analyze Players Data', `/analyzePlayersData/achievements`, TimelineIcon, 'analyzePlayersData/')}
                            {this.renderDrawerItem('Compensations', `/compensation/preDefinedItems`, LoyaltyIcon, 'compensation/')}
                            {this.renderDrawerItem('Apple Support', `/appleSupport/all`, AppleIcon, 'appleSupport/')}
                        </ListNested>
                    </Collapse>
                    <StyledWideListDivider />
                    <StyledListItem open={devOpsMenuOpen} onClick={this.handleClickDevOps} style={{ borderRadius: 0}}>
                        <StyledListItemText primary="Dev Ops" />
                        {devOpsMenuOpen ? (<StyledExpandLess/>) : (<StyledExpandMore/> )}
                    </StyledListItem>
                    <Collapse in={devOpsMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Cloud Build', `/cloudBuild/history`, BuildIcon, 'cloudBuild/')}
                        </ListNested>
                    </Collapse>
                    <StyledWideListDivider />
                    <StyledListItem open={marketingMenuOpen} onClick={this.handleClickMarketing} style={{ borderRadius: 0}}>
                        <StyledListItemText primary="Marketing" />
                        {marketingMenuOpen ? (<StyledExpandLess/>) : (<StyledExpandMore/> )}
                    </StyledListItem>
                    <Collapse in={marketingMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Promo Campaigns', `/marketing/promoCampaigns`, CardGiftcardIcon, 'marketing/')}
                        </ListNested>
                    </Collapse>
                </WideStyledList>
            </DivStyled>
        );
    };

    private renderNarrowList = () => {
        return (
            <NarrowStyledList>
                <Grid container={true} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item={true} xs={12}>
                        {this.renderDrawerItem('', `/dashboard`, HomeIcon, undefined, 'Project Overview')}
                        {this.renderDrawerItem('', `/settings/general`, SettingsIcon, 'settings/', 'Settings')}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledDivider />
                    </Grid>
                    <Grid item={true} xs={12}>
                        {this.renderDrawerItem('', `/players/all`, GroupIcon, 'players/', 'Players')}
                        {this.renderDrawerItem('', `/segments/all`, CategoryIcon, 'segments/', 'Segments')}
                        {this.renderDrawerItem('', `/clientVersion/all`, NotesIcon, 'clientVersion/', 'Client Versions')}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledDivider />
                    </Grid>
                    <Grid item={true} xs={12}>
                        {this.renderDrawerItem('', `/cloudCode/all`, CodeIcon, 'cloudCode/', 'CloudCode')}
                        {this.renderDrawerItem('', `/group/all`, GroupWorkIcon, 'group/', 'Groups')}
                        {this.renderDrawerItem('', `/economy/items`, PaidIcon, 'economy/', 'Economy')}
                        {this.renderDrawerItem('', `/titleData/default`, DescriptionIcon, 'titleData/', 'Title Data')}
                        {this.renderDrawerItem('', `/titleNews/all`, NewspaperIcon, 'titleNews/', 'Title News')}
                        {this.renderDrawerItem('', `/files/default`, PublicIcon, 'files', 'CDN')}
                        {this.renderDrawerItem('', `/achievements/all`, EmojiEventsIcon, 'achievements/', 'Achievements')}
                        {this.renderDrawerItem('', `/liveEvent/all`, CalendarTodayIcon, 'liveEvent/', 'Live Events')}
                        {this.renderDrawerItem('', `/season/all`, GamesIcon, 'season/', 'Seasons')}
                        {this.renderDrawerItem('', `/leaderBoard/all`, PollIcon, 'leaderBoard/', 'LeaderBoard')}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledDivider />
                    </Grid>
                    <Grid item={true} xs={12}>
                        {this.renderDrawerItem('', `/analyzePlayersData/achievements`, TimelineIcon, `analyzePlayersData/`, 'Analyze Player Data')}
                        {this.renderDrawerItem('', `/compensation/preDefinedItems`, LoyaltyIcon, 'compensation/', 'Compensations')}
                        {this.renderDrawerItem('', `/appleSupport/all`, AppleIcon, 'appleSupport/', 'Apple')}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledDivider />
                    </Grid>
                    <Grid item={true} xs={12}>
                        {this.renderDrawerItem('', `/cloudBuild/history`, BuildIcon, 'cloudBuild', 'Cloud Build')}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledDivider />
                    </Grid>
                    <Grid item={true} xs={12}>
                        {this.renderDrawerItem('', `/marketing/promoCampaigns`, CardGiftcardIcon, 'marketing', 'Promo Campaign')}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledDivider />
                    </Grid>
                </Grid>
            </NarrowStyledList>
        );
    };

    private renderDrawerItem(title: string, to: string, Icon: SvgIconComponent, base?: string, tooltip?: string, style?: CSSProperties | undefined) {
        const { expanded, variant } = this.props;
        const selected = window.location.href.includes(base || to);

        const onClick = () => {
            if (variant !== 'permanent') {
                this.onClickSmallDrawer();
            }
        };


        return (
            <Link to={to} style={{ minWidth: 200, textDecoration: 'none', color: 'black'}}>
                <MainListItem
                    selected={selected}
                    onClick={ onClick }
                    sx={{
                        textDecoration: 'none',
                        paddingLeft: expanded ? '18px' : '24px',
                        paddingRight: expanded ? '18px' : '24px',
                        ...style
                    }}
                >
                    <Tooltip title={tooltip || ''} placement="right">
                        <ListItemIcon
                            sx={{
                                margin: 0,
                                minWidth: 36,
                                color: selected ? '#669df6' : '#F2F2F2',
                            }}
                        >
                            <Icon style={{ height: 18, width: 18 }} />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                        inset={true}
                        primary={title}
                        disableTypography={true}
                        sx={{
                            padding: 0,
                            fontSize: '13px',
                            fontWeight: 500,
                            letterSpacing: '.25px',
                            color: selected ? '#669df6' : '#F2F2F2',
                        }}
                    />
                </MainListItem>
            </Link>
        );
    }

    private onClickDrawerIcon = () => actions.app.changeShowDrawer();
    private onClickSmallDrawer = () => actions.app.changeSmDrawerOpen();

    private handleClickBuild = () => this.setState({ buildMenuOpen: !this.state.buildMenuOpen });
    private handleClickLiveOps = () => this.setState({ liveOpsMenuOpen: !this.state.liveOpsMenuOpen });
    private handleClickCostumerSupport = () => this.setState({ csMenuOpen: !this.state.csMenuOpen });
    private handleClickDevOps = () => this.setState({ devOpsMenuOpen: !this.state.devOpsMenuOpen });
    private handleClickMarketing = () => this.setState({ marketingMenuOpen: !this.state.marketingMenuOpen });
}

const MainListItem = styled(ListItem)(({
    '&.MuiListItem-root': {
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: 'rgba(255,255,255,.08)',
        },
        "&:focus": {
            backgroundColor: 'rgba(255,255,255,.08)',
        }
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent',
        "&:hover": {
            backgroundColor: 'rgba(255,255,255,.08)',
        },
        "&:focus": {
            backgroundColor: 'rgba(255,255,255,.08)',
        }
    },
}));

const StyledTitleLink = styled(ReactRouterLink)(({
    display: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#F2F2F2',
    padding: '10px 0',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));

const DivStyled = styled('div')(({
    height: 'calc(100% - 52px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: 8,
        backgroundColor: '#141414',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#F2F2F2',
        borderRadius: 0,
        border: '2px solid #141414',
    }
}));

const WideStyledList = styled(List)(({
    margin: 10,
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#242529cc',
}));

const NarrowStyledList = styled(List)(({
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: 0,
    height: 'calc(100% - 52px)',
    paddingTop: 0,
    '&::-webkit-scrollbar': {
        width: 8,
        backgroundColor: '#141414',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#F2F2F2',
        borderRadius: 0,
        border: '2px solid #141414',
    }
}));

const ListNested = styled(List)(({
    overflowY: 'hidden',
    overflowX: 'hidden',
    margin: 0,
    paddingBottom: 10,
}));

const ArrowButton = styled(Button)(({
    minWidth: 'auto',
    backgroundColor: '#383940',
    color: '#F2F2F2',
    '&:hover': {
        backgroundColor: '#40414b'
    },
    '&:focus': {
        backgroundColor: '#40414b',
    }
}));

const StyledExpandLess = styled(ExpandLess)(({
    margin: 0,
    minWidth: 36,
    color: 'white',
}));

const StyledExpandMore = styled(ExpandMore)(({
    margin: 0,
    minWidth: 36,
    color: 'white',
}));

interface StyledListItemProps extends PropsWithChildren {
    readonly open: boolean;
    style?: React.CSSProperties;
    onClick?: any;
}
const StyledListItem = styled((props: StyledListItemProps) => {
    return (
        <ListItem
            {...props}
            button={true}
            onClick={props.onClick}
            sx={{
                '&.MuiListItem-root': {
                    textDecoration: 'none',
                    paddingLeft: '20px',
                    paddingRight: '5px',
                    height: 50,
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,.08)',
                    },
                    '&:focus': {
                        backgroundColor: 'transparent',
                    }
                },
            }}
        >
            {props.children}
        </ListItem>
    );
})(() => ({
}));

const StyledListItemText = styled((props: ListItemTextProps) => (
    <ListItemText
        {...props}
        primaryTypographyProps={{
            style: {
                padding: 0,
                fontSize: '14px',
                fontWeight: 500,
                color: '#F2F2F2',
            }
        }}
        secondaryTypographyProps={{
            style: {
                padding: 0,
                fontSize: '13px',
                fontWeight: 200,
                color: '#808080',
            }
        }}
    />
))(() => ({
}));

const StyledDivider = styled(Divider)(({
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderColor: 'rgba(255, 255, 255, 0.1)',
}));

const StyledWideListDivider = styled(Divider)(({
    backgroundColor: '#141414',
    borderColor: '#141414',
    borderStyle: 'double',
}));
