import * as React from 'react';
import { AggregationMethod, ResetFrequency } from '../../enums';
import {
    AggregationMethodMenuType,
    getAggregationMethodMenus,
    ResetFrequencyMenuType,
    getResetFrequencyMenus,
    Menu,
} from '../../utils';
import { Grid, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';

interface Props extends BaseDialogProps {
    readonly onCreate: (name: string, resetFrequency: ResetFrequency, aggregationMethod: AggregationMethod) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly resetFrequency: ResetFrequency;
    readonly aggregationMethod: AggregationMethod;
    readonly resetFrequencyMenu: Record<ResetFrequencyMenuType, Menu>;
    readonly aggregationMethodMenu: Record<AggregationMethodMenuType, Menu>;
}

export class CreateLeaderBoardDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const {
            name,
            resetFrequency,
            aggregationMethod,
            resetFrequencyMenu,
            aggregationMethodMenu
        } = this.state;

        const resetFrequencyViews: React.JSX.Element[] = [];
        Object.keys(resetFrequencyMenu).forEach(key => {
            const resetFrequencyMenuType = key as ResetFrequencyMenuType;
            const {title, value} = resetFrequencyMenu[resetFrequencyMenuType];
            resetFrequencyViews.push(
                <MenuItem key={key} value={value}>
                    {title}
                </MenuItem>
            );
        });

        const aggregationMethodViews: React.JSX.Element[] = [];
        Object.keys(aggregationMethodMenu).forEach(key => {
            const aggregationMethodMenuType = key as AggregationMethodMenuType;
            const {title, value} = aggregationMethodMenu[aggregationMethodMenuType];
            aggregationMethodViews.push(
                <MenuItem key={key} value={value}>
                    {title}
                </MenuItem>
            );
        });

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        id="live-event-name"
                        label=""
                        value={name}
                        onChange={this.handleNameChange}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel id="select-reset-frequency">Reset frequency</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Select
                        labelId={`select-reset-frequency`}
                        value={resetFrequency}
                        variant={'outlined'}
                        onChange={this.handleResetFrequencyChange}
                        disableUnderline={true}
                        fullWidth={true}
                    >
                        {resetFrequencyViews}
                    </Select>
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel id={`select-aggregation-method`}>Aggregation method</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Select
                        labelId={`select-aggregation-method`}
                        value={aggregationMethod}
                        variant={'outlined'}
                        onChange={this.handleAggregationMethodChange}
                        disableUnderline={true}
                        fullWidth={true}
                    >
                        {aggregationMethodViews}
                    </Select>
                </Grid>
            </Grid>
        );

    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    protected initState(): State {
        const resetFrequencyMenu = getResetFrequencyMenus();
        const aggregationMethodMenu = getAggregationMethodMenus();

        return {
            name: '',
            resetFrequency: ResetFrequency.Never,
            aggregationMethod: AggregationMethod.Last,
            resetFrequencyMenu,
            aggregationMethodMenu,
        };
    }

    private handleNameChange = (name: string) => this.setState({ name });

    private handleResetFrequencyChange = (event: SelectChangeEvent<number>) => this.setState({ resetFrequency: event.target.value as number });

    private handleAggregationMethodChange = (event: SelectChangeEvent<number>) => this.setState({ aggregationMethod: event.target.value as number });

    private validate(): boolean {
        const { name } = this.state;
        return name !== '';

    }

    private onCreate = () => {
        const { name, resetFrequency, aggregationMethod } = this.state;
        this.props.onCreate(name, resetFrequency, aggregationMethod);
    };
}
