import React from 'react';
import { Hidden } from '@mui/material';
import { Drawer, DrawerProps } from './Drawer';
import { styled } from '@mui/styles';
import { styledTheme, toLink as baseToLink } from '../../utils';
import { AppHeader } from './header';
import actions from '../../actions';
import { Navigate } from 'react-router-dom';
import { ContainerContext } from '../../containers';

interface State {
}

const wideDrawerWith = 256;
const narrowDrawerWith = 68;

export class BaseElement<P extends ContainerContext.Props = ContainerContext.Props, S extends State = State> extends React.Component<P, S> {

    render() {
        if (!this.props.loginUser) {
            return <Navigate to="/" replace={true} />;
        }

        return (
            <>
                <Hidden mdUp={true}>
                    {this.renderMainSmall()}
                </Hidden>
                <Hidden mdDown={true}>
                    {this.renderMainRegular()}
                </Hidden>
                {this.renderDialogs()}
            </>
        );
    }

    protected renderMainSmall() {
        const { titleSelected } = this.props;
        const smDrawerOpen = this.props.app.smDrawerOpen;

        return (
            <>
                {titleSelected && titleSelected.id !== '' && (
                    <StyledDrawer
                        open={true}
                        variant={'temporary'}
                        openDrawer={smDrawerOpen}
                        expanded={true}
                        userTitleRole={titleSelected.userTitleRole}
                    />
                )}
                <main style={{ display: 'flex', flexDirection: 'column' }}>
                    <section style={{ height: 42, paddingBottom: 8 }}>
                        {this.renderHeader(titleSelected !== undefined && titleSelected.id !== '')}
                    </section>
                    <section style={{ height: 'calc(100% - 50px)', display: 'flex' }}>
                        <section style={{
                            padding: '0px 16px',
                            flexGrow: 1,
                            width: 'calc(100% - 32px)',
                            height: '100%',
                        }}>
                            {this.renderContainer()}
                        </section>
                    </section>
                </main>
            </>
        );
    }

    protected renderMainRegular() {
        const { titleSelected } = this.props;
        const expandDrawer = this.props.app.showDrawer;
        const showDrawer = titleSelected && titleSelected.id !== '';

        return (
            <>
                {showDrawer && (
                    <StyledDrawer
                        open={expandDrawer}
                        variant={'permanent'}
                        openDrawer={true}
                        expanded={expandDrawer}
                        userTitleRole={titleSelected.userTitleRole}
                    />
                )}
                <main
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ...this.mainStyle()
                    }}
                >
                    <section style={{ height: 42, paddingBottom: 8 }}>
                        {this.renderHeader(false)}
                    </section>
                    <section style={{ height: 'calc(100% - 50px)', display: 'flex' }}>
                        <section style={{
                            padding: '0px 16px',
                            flexGrow: 1,
                            width: 'calc(100% - 32px)',
                            height: '100%',
                        }}>
                            {this.renderContainer()}
                        </section>
                    </section>
                </main>

            </>
        );
    }

    protected renderContainer(): React.JSX.Element {
        return <></>;
    }

    protected renderDialogs(): React.JSX.Element {
        return <></>;
    }

    protected toLink = (to: string) => {
        baseToLink(this.props, to);
    }

    private renderHeader(small: boolean) {
        const { loginUser, titleSelected, titles, navigate, userRole } = this.props;
        const expandDrawer = this.props.app.showDrawer;

        return (
            <AppHeader
                position="fixed"
                navigate={navigate}
                userRole={userRole}
                showDrawerOpener={small}
                loginUser={loginUser as Entities.User}
                titles={titles}
                titleSelected={titleSelected}
                auth={!!loginUser}
                clickLogoutMenu={this.clickLogoutMenu}
                onTitlesMenuClick={this.handleTitlesMenu}
                style={{
                    width: small || !titleSelected || titleSelected.id === '' ? `100%` :
                        expandDrawer ? `calc(100% - ${wideDrawerWith}px)`
                            : `calc(100% - ${narrowDrawerWith}px)`,
                    padding: '8px 16px',
                    backgroundColor: !titleSelected ? 'transparent' : '#f2f3f3',
                    backgroundImage: !titleSelected ? 'url("/images/nucleo-background.png")' : 'none',
                    backgroundSize: 'cover',
                }}
            />
        );
    }

    private mainStyle(): React.CSSProperties {
        const expandDrawer = this.props.app.showDrawer;
        const { titleSelected } = this.props;

        return {
            width: !titleSelected || titleSelected.id === '' ? `100%` :
                expandDrawer ? `calc(100% - ${wideDrawerWith}px)`
                    : `calc(100% - ${narrowDrawerWith}px)`,
            marginLeft: !titleSelected || titleSelected.id === '' ? 0 : expandDrawer ? wideDrawerWith : narrowDrawerWith,
        };
    }

    private clickLogoutMenu = async () => {
        actions.title.resetTitle();
        await actions.auth.logout();
        this.redirectTo('/');
    }

    private redirectTo = (redirectTo: string) => {
        const { navigate } = this.props;
        this.toLink(redirectTo);
    }

    private handleTitlesMenu = (index: number) => {
        actions.title.resetTitle();
        if (index === -1) {
            this.redirectTo(`/titles`);
            return;
        }
        if (!this.props.titles) {
            return;
        }

        const title = this.props.titles[index];
        actions.title.setTitle(title);

        this.toLink('/dashboard');
    };
}

interface StyledDrawerProps extends DrawerProps {
    readonly open: boolean;
}
const StyledDrawer = styled((props: StyledDrawerProps) => {
    let style = {
        width: wideDrawerWith,
        backgroundColor: '#141414',
        overflow: 'hidden',
        height: '100%',
        boxShadow: 'none',
        transition: styledTheme.transitions.create('width', {
            easing: styledTheme.transitions.easing.sharp,
            duration: styledTheme.transitions.duration.enteringScreen,
        }),
    };
    if (!props.open) {
        style = {
            width: narrowDrawerWith,
            backgroundColor: '#141414',
            overflow: 'hidden',
            height: '100%',
            boxShadow: 'none',
            transition: styledTheme.transitions.create('width', {
                easing: styledTheme.transitions.easing.sharp,
                duration: styledTheme.transitions.duration.leavingScreen,
            }),
        };
    }
    return (
        <Drawer
            {...props}
            sx={{ '& .MuiDrawer-paper': style }}
        />
    );
})(() => ({}));
