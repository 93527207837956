import { SegmentActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, SegmentAction } from '../reducers';

export const listAll = async () => {
    const segments = await Request.get(`segment/listAll`);
    const action: SegmentAction = {
        type: SegmentActionType.Update,
        payload: {
            segments
        },
    };
    dispatch(action);
};

export const create = async (name: string, color: string, rules: Entities.SegmentRule[]) => {
    await Request.post(`segment/create`, { name, rules, color });
};

export const update = async (segmentId: string, name: string, color: string, rules: Entities.SegmentRule[]) => {
    await Request.post(`segment/${segmentId}/update`, { name, color, rules });
};

export const remove = async (segmentId: string) => {
    await Request.post(`segment/delete`, { segmentId });
};
