import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { Components } from '@mui/material/styles/components';

export const fontFamily = [
    'Roboto',
    '"Helvetica Neue"',
    'sans-serif'
].join(',');

export const styleComponents: Components<Omit<Theme, 'components'>> = {
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: 0,
                boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                border: 'none',
            },
            elevation2: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
            },
        },
    },
    MuiGrid: {
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                },
            }),
            item: ({ theme }) => ({
                alignItems: 'center',
                [theme.breakpoints.only('xs')]: {
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                },
            }),
        },
    },
    MuiInput: {
        styleOverrides: {
            underline: {
                "&:after": {
                    borderBottom: `1px solid rgba(0,0,0,0.8)`
                }
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                height: 28,
            },
            multiline: {
                height: '100%',
                padding: '5px !important',
            },
            input: {
                fontSize: 13,
                padding: '5px !important',
            }
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                paddingTop: 10,
                paddingBottom: 2,
                fontWeight: 600,
                fontSize: 11,
            },
            outlined: {
                paddingTop: 0,
                fontWeight: 600,
                fontSize: 11,
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                backgroundColor: 'white',
                borderRadius: 0,
            },
            input: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                },
                paddingTop: 6,
                paddingBottom: 6,
            }),
            inputAdornedStart: {
                paddingTop: 6,
                paddingBottom: 6,
            },
            notchedOutline: {
                border: 'none',
                boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                width: '100%',
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                },
                '&$focused': {
                    color: 'rgba(0,0,0,0.8)',
                }
            }),
        },
    },
    MuiAppBar: {
        styleOverrides: {
            colorPrimary: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            outlined: {
                display: 'flex',
                gridAutoFlow: 'column',
                gridColumnGap: 5,
                justifyContent: 'center',
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                borderRadius: 0,
                '&:hover': {},
                '&:focus': {},
            },
            contained: {
                color: 'white',
                backgroundColor: '#1a73e8',
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                boxShadow: 'rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: '#1967d2',
                },
                '&:focus': {
                    backgroundColor: '#1967d2',
                },
            },
            containedPrimary: {
                color: 'white',
                backgroundColor: '#1a73e8',
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: '#1967d2',
                },
                '&:focus': {
                    backgroundColor: '#1967d2',
                },
            },
            containedSecondary: {
                backgroundColor: "#fff",
                borderColor: "#545b64",
                color: "#545b64",
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: "#fff",
                    color: "#16191f",
                    borderColor: '#16191f',
                },
                '&:focus': {
                    backgroundColor: "#fff",
                    color: "#16191f",
                    borderColor: '#16191f',
                },
            },
            containedError: {
                color: 'rgb(211, 47, 47)',
                backgroundColor: 'white',
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: 'white',
                },
                '&:focus': {
                    backgroundColor: 'white',
                },
            },
            text: {
                color: '#0000008c',
                borderColor: 'rgba(0, 0, 0, 0)',
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                '&:disabled': {
                    borderColor: 'rgba(0, 0, 0, 0)',
                },
                '&:hover': {
                    backgroundColor: '#f2f2f2',
                },
                '&:focus': {
                    backgroundColor: '#f2f2f2',
                },
            },
            textSecondary: {
                color: 'white',
                backgroundColor: '#1a73e8',
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                '&:disabled': {
                    color: '#00000042',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    backgroundColor: 'transparent',
                },
                '&:hover': {
                    backgroundColor: '#1967d2',
                },
                '&:focus': {
                    backgroundColor: '#1967d2',
                },
            },
            outlinedSecondary: {
                backgroundColor: "#fff",
                borderColor: "#545b64",
                color: "#545b64",
                fontWeight: 500,
                lineHeight: 'inherit',
                textTransform: 'inherit',
                padding: '6px 16px',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: "#fff",
                    color: "#16191f",
                    borderColor: '#16191f',
                },
                '&:focus': {
                    backgroundColor: "#fff",
                    color: "#16191f",
                    borderColor: '#16191f',
                },
            }
        },
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                color: '#0073bb',
                fontSize: '0.875rem',
                fontWeight: 600,
                borderColor: 'rgba(0, 0, 0, 0)',
                textTransform: 'none',
                '&:disabled': {
                    borderColor: 'rgba(0, 0, 0, 0)',
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            }
        }
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                '&.Mui-active': {
                    color: '#0070c9',
                },
                '&.Mui-completed': {
                    color: '#0070c9',
                }
            },
        },
    },
    MuiStepLabel: {
        styleOverrides: {
            label: {
                '&$alternativeLabel': {
                    textTransform: 'uppercase',
                    fontSize: 'smaller',
                    fontWeight: 'bold',
                }
            }
        },
    },
    MuiStepConnector: {
        styleOverrides: {
            'root': {
                '&.Mui-active': {
                    'color': "#0070c9",
                    '& $line': {}
                },
                '&.Mui-completed': {
                    '& $line': {
                        borderColor: '#0070c9',
                    },
                }
            },
            alternativeLabel: {
                top: 10,
                left: 'calc(-50% + 16px)',
                right: 'calc(50% + 16px)',
            },
            line: {
                borderTopWidth: 3,
                borderRadius: 1,
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            gutters: {
                display: 'contents',
                padding: '0 !important',
            }
        },
    },
    MuiCard: {
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                },
                backgroundColor: 'white',
                boxShadow: '0px 0px 1px 1px #e0e0e0',
                borderRadius: 0,
            })
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: 10,
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                },
                '&:last-child': {
                    paddingBottom: 10,
                },
            })
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                },
            })
        },
    },
    MuiCardMedia: {
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                    margin: 'auto',
                },
            })
        },
    },
    MuiCardActions: {
        styleOverrides: {
            root: ({ theme }) => ({
                justifyContent: 'flex-end',
                [theme.breakpoints.only('xs')]: {
                    textAlign: 'center',
                    justifyContent: 'center',
                },
            })
        },
    },
    MuiSelect: {
        styleOverrides: {
            select: ({ theme }) => ({
                width: '100%',
                padding: '0px 10px',
                display: 'grid',
                gridAutoFlow: 'column',
                gridColumnGap: 10,
                justifyContent: 'flex-start',
                [theme.breakpoints.only('xs')]: {
                    justifyContent: 'center',
                },
                '&:disabled': {
                    backgroundColor: 'transparent',
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            }),
            outlined: {
                '&:disabled': {
                    backgroundColor: 'transparent',
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            }
        },
    },
    MuiMenu: {
        styleOverrides: {
            paper: {
                boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                border: '1px solid #e0e0e0',
                borderRadius: 0,
                backgroundColor: 'white',
            }
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            selected: ({ theme }) => ({
                width: '100%',
                padding: '0px 10px',
                display: 'grid',
                gridAutoFlow: 'column',
                gridColumnGap: 10,
                justifyContent: 'flex-start',
                [theme.breakpoints.only('xs')]: {
                    justifyContent: 'center',
                },
            }),
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                borderRadius:  0,
                backgroundColor: 'white',
            }
        }
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }
        }
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: ({ theme }) => ({
                height: 20,
                padding: '10px',
                [theme.breakpoints.only('xs')]: {
                    display: 'flex',
                    justifyContent: 'center',
                },
            })
        }
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: '10px !important',
                '&::-webkit-scrollbar': {
                    width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                    border: '2px solid white',
                    borderRadius: 4,
                    backgroundColor: 'rgba(0, 0, 0, .3)',
                }
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    display: 'flex',
                    justifyContent: 'center',
                },
            })
        }
    },
    MuiDivider: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: '#c3cfdd',
                [theme.breakpoints.only('xs')]: {
                    width: '100%'
                },
            }),
            vertical: ({ theme }) => ({
                backgroundColor: '#c3cfdd',
                [theme.breakpoints.only('xs')]: {
                    width: 0,
                },
            }),
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            colorSecondary: {
                '&$checked': {
                    color: '#0073bb',
                },
            }
        },
    },
    MuiIcon: {
        styleOverrides: {
            colorPrimary: {
                color: '#0073bb',
            }
        },
    },
    MuiTabs: {
        styleOverrides: {
            flexContainer: ({ theme }) => ({
                [theme.breakpoints.only('xs')]: {
                    display: 'block',
                    justifyContent: 'center',
                },
            })
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: 'transparent',
                [theme.breakpoints.only('xs')]: {
                    display: 'inline-flex',
                    justifyContent: 'center',
                    flexDirection: 'row-reverse',
                    gridColumnGap: 5,
                },
            })
        },
    },
};


export const styledTheme = createTheme({
    typography: {
        fontFamily,
    },
    palette: {
        primary: {
            main: 'rgba(0,0,0,0.8)',
            light: '#4DB6AC',
            dark: '#00796B',
        },
        secondary: {
            main: '#747b8e',
            light: '#747b8e',
            dark: '#747b8e',
        },
    },
    components: styleComponents,
});
