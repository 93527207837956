import * as React from 'react';
import { CircularProgress } from '@mui/material';

interface Props {
    disableText?: boolean;
}

export class Loading extends React.Component<Props> {
    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
                <CircularProgress style={{width: 40, height: 40, color: '#141414'}}/>
                {!this.props.disableText && (
                    <div style={{color: 'rgba(0,0,0,0.8)', marginTop: 5, fontSize: 'x-small'}}>
                        Loading...
                    </div>
                )}
            </div>
        );
    }
}
