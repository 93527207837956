import * as React from 'react';
import { BaseContainer, CommonHeaderProps } from '../common';
import { toLink } from '../../utils';
import { ContainerContext } from '../../containers';
import { Timeline as TimelineIcon } from '@mui/icons-material';
import { PropsWithChildren } from 'react';

interface Props extends ContainerContext.Props, CommonHeaderProps, PropsWithChildren {
}

export class BaseAnalyzePlayers extends React.Component<Props> {
    render() {
        const { loading, headline, headlines, children, buttons } = this.props;
        const subTabsTitles = ['Achievements', 'Compensations', 'Custom Login Keys', 'Facebook Profiles',
            'Firebase Data', 'Game Center Login Keys', 'Google Login Keys', 'Live Events',
            'Match Makings', 'Payments', 'Seasons', 'Stats'];

        return (
            <BaseContainer
                loading= {loading}
                title={"Analyze Players Data"}
                TitleIcon={TimelineIcon}
                headline = {headline}
                headlines = {headlines}
                subTabs={{
                    titles: subTabsTitles,
                    selected: this.subTabsSelected(),
                    onChange: this.changeSubTabs,
                }}
                buttons={buttons}
            >
                {children}
            </BaseContainer>
        );
    }

    private subTabsSelected = () => {
        if (location.pathname.includes(this.getPath('achievements'))) {
            return  0;
        } else if (location.pathname.includes(this.getPath('compensations'))) {
            return  1;
        } else if (location.pathname.includes(this.getPath('customLoginKeys'))) {
            return  2;
        } else if (location.pathname.includes(this.getPath('facebookProfiles'))) {
            return  3;
        } else if (location.pathname.includes(this.getPath('firebaseData'))) {
            return  4;
        } else if (location.pathname.includes(this.getPath('gameCenterLoginKeys'))) {
            return  5;
        } else if (location.pathname.includes(this.getPath('googleLoginKeys'))) {
            return 6;
        } else if (location.pathname.includes(this.getPath('liveEvents'))) {
            return 7;
        } else if (location.pathname.includes(this.getPath('matchMakings'))) {
            return 8;
        } else if (location.pathname.includes(this.getPath('payments'))) {
            return 9;
        } else if (location.pathname.includes(this.getPath('seasons'))) {
            return 10;
        } else if (location.pathname.includes(this.getPath('stats'))) {
            return 11;
        }

        return 0;
    };

    private changeSubTabs = (tab: number) => {
        let to = '';
        switch (tab) {
            case 0:
                to = this.getPath('achievements');
                break;
            case 1:
                to = this.getPath('compensations');
                break;
            case 2:
                to = this.getPath('customLoginKeys');
                break;
            case 3:
                to = this.getPath('facebookProfiles');
                break;
            case 4:
                to = this.getPath('firebaseData');
                break;
            case 5:
                to = this.getPath('gameCenterLoginKeys');
                break;
            case 6:
                to = this.getPath('googleLoginKeys');
                break;
            case 7:
                to = this.getPath('liveEvents');
                break;
            case 8:
                to = this.getPath('matchMakings');
                break;
            case 9:
                to = this.getPath('payments');
                break;
            case 10:
                to = this.getPath('seasons');
                break;
            case 11:
                to = this.getPath('stats');
                break;
        }
        toLink(this.props, to);
    };

    private getPath = (component: string) => {
        return `/analyzePlayersData/${component}`;
    };
}
