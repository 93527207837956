import { LeaderBoardType, SeasonActionType } from '../enums';
import { Request } from '../utils';
import { dispatch } from '../reducers';

export const createRegular = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], maxAmountPerReport: number) => {
    await Request.post(`season/createRegular`, { name, metadata, start, end, developerOnly, tiers, maxAmountPerReport });
};

export const createLeaderBoard = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, maxAmountPerReport: number, leaderBoardType: LeaderBoardType) => {
    await Request.post(`season/createLeaderBoard`, { name, metadata, start, end, developerOnly, tiers, ranks, maxUsersPerInstance, maxAmountPerReport, leaderBoardType });
};

export const edit = async (seasonId: string, name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[]) => {
    await Request.post(`season/${seasonId}/edit`, { name, metadata, start, end, developerOnly, tiers });
};

export const editLive = async (seasonId: string, metadata: string, maxAmountPerReport: number) => {
    await Request.post(`season/${seasonId}/editLive`, { metadata, maxAmountPerReport });
};

export const get = async (seasonId: string, getTiers: boolean = false) => {
    const response = await Request.get<Entities.GetSeasonResponse>(`season/${seasonId}/get`, { getTiers });
    dispatch({
        type: SeasonActionType.Update,
        payload: response,
    });
};

export const remove = async (seasonId: string) => {
    await Request.post(`season/${seasonId}/delete`);
};
