import React, { useState, useEffect } from 'react';
import {
    Snackbar,
    IconButton,
    Typography
} from '@mui/material';
import {
    Close as CloseIcon
} from '@mui/icons-material';

interface Props {
    readonly error: Error | null;
}

export const ErrorDialog = ({ error }: Props) => {
    const isError = !!error;
    const message = error ? error.message : '';
    const [open, setOpen] = useState(isError);
    const [curError, setError] = useState(error);

    useEffect(() => {
        if (error === curError) {
            return;
        }
        setError(error);
        setOpen(isError);
    });

    const onClose = () => setOpen(false);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={
                <Typography color="error" id="message-id">
                    {message}
                </Typography>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    );
};
