import { UserActionType } from '../enums';
import { Request, LocalStorage } from '../utils';
import { dispatch, UserAction } from '../reducers';

export const checkLogin = async () => {
    const { user = null } = await Request.get('logincheck', undefined, undefined, false);
    dispatch({
        type: UserActionType.UpdateLoginUser,
        payload: {
            loginUser: user,
        },
    });
};

export const login = async (username: string, password: string) => {
    const { user, sessionId } = await Request.post('login', { username, password }, undefined, false);
    LocalStorage.set('auth', { sessionId });
    dispatch({
        type: UserActionType.UpdateLoginUser,
        payload: {
            loginUser: user,
        },
    });
};

export const loginWithGoogle = async (code?: string) => {
    const { user, sessionId } = await Request.post('loginWithGoogle', { code }, undefined, false);
    LocalStorage.set('auth', { sessionId });
    dispatch({
        type: UserActionType.UpdateLoginUser,
        payload: {
            loginUser: user,
        },
    });
};

export const logout = async () => {
    document.cookie = '';
    LocalStorage.set('auth', { sessionId: '' });
    const action: UserAction = {
        type: UserActionType.UpdateLoginUser,
        payload: {
            loginUser: null,
        },
    };
    dispatch(action);
};
