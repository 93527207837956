import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid, InputLabel } from '@mui/material';
import { UserTitleRole } from '../../enums';
import { UserTitleRoleSelect } from './UserTitleRoleSelect';

interface Props extends BaseDialogProps {
    readonly onCreate: (username: string, role: UserTitleRole) => void;
}

interface State extends BaseDialogState {
    readonly username: string;
    readonly role: UserTitleRole;
}

export class AddUserTitleDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { username, role } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Email address</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={username}
                        label=""
                        required={true}
                        onChange={this.updateUsername}
                        variant="outlined"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Role</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <UserTitleRoleSelect role={role} updateRole={this.updateRole} />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    protected initState(): State {
        return {
            username: '',
            role: UserTitleRole.Viewer,
        };
    }

    private validate() {
        const { username, role } = this.state;
        if (username === '') {
            return false;
        }
        return role !== UserTitleRole.NoRole;

    }

    private updateUsername = (username: string) => this.setState({ username });

    private updateRole = (role: UserTitleRole) => this.setState({ role });

    private onCreate = () => {
        const { username, role } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onCreate(username, role);
        this.onClose();
    };
}
