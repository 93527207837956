import * as React from 'react';
import { Divider, Grid, InputLabel } from '@mui/material';
import { Button, LabelCheckBox, NumberField, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { default as moment, Moment } from 'moment';
import { DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import {
    AddOutlined,
    DeleteForeverOutlined,
    ListOutlined,
} from '@mui/icons-material';
import { SelectCompensationPreDefinedItemDialog } from './SelectCompensationPreDefinedItemDialog';
import { styled } from '@mui/styles';

interface Props extends BaseDialogProps {
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
    readonly selectedCompensationData: Entities.CompensationData;
    readonly onUpdate: (title: string, description: string, from: number, to:number, allUsers:boolean, userList: string, itemList: string) => void;
}

interface State extends BaseDialogState {
    readonly preDefinedSelectOpen: boolean;
    readonly title: string;
    readonly description: string;
    readonly from: Moment;
    readonly to: Moment;
    readonly allUsers: boolean;
    readonly users: string[];
    readonly items: Entities.CompensationItem[];
}

export class UpdateCompensationBundleDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedCompensationData.compensationId !== prevProps.selectedCompensationData.compensationId) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const {title, description, from, to, allUsers, users, items } = this.state;

        const itemViews: React.JSX.Element[] = [];
        if (items.length === 0) {
            itemViews.push(
                <Grid item={true} xs={12} style={{ textAlign: 'center', fontSize: 13, color: 'grey'}}>
                    No items
                </Grid>
            );

        } else {
            items.forEach((item, index) => {
                const updateKeyMethod = (key: string) => this.updateKey(index, key);
                const updateAmountMethod = (amount: number) => this.updateAmount(index, amount);
                const deleteMethod = () => this.deleteItem(index);

                itemViews.push(
                    <>
                        {index > 0 && (
                            <Grid item={true} xs={12}>
                                <StyledDivider />
                            </Grid>
                        )}
                        <Grid item={true} xs={12}>
                            <InputLabel>Key</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                fullWidth={true}
                                label=""
                                value={item.Key}
                                variant="outlined"
                                required={true}
                                onChange={updateKeyMethod}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <InputLabel>Amount</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <NumberField
                                fullWidth={true}
                                label=""
                                value={item.Amount}
                                variant="outlined"
                                required={true}
                                onChange={updateAmountMethod}
                            />
                        </Grid>
                        <Grid item={true} xs={12} style={{display: 'grid', justifyContent: 'end'}}>
                            <StyledButton text="Delete" icon={DeleteForeverOutlined} variant={'text'} onClick={deleteMethod} />
                        </Grid>
                    </>
                );
            });
        }

        const usersViews: React.JSX.Element[] = [];
        if (users.length === 0) {
            usersViews.push(
                <Grid item={true} xs={12} style={{ textAlign: 'center', fontSize: 13, color: 'grey'}}>
                    No users
                </Grid>
            );

        } else {
            users.forEach((item, index) => {
                const updateUserMethod = (key: string) => this.updateUser(index, key);
                const deleteUserMethod = () => this.deleteUser(index);

                usersViews.push(
                    <>
                        {index > 0 && (
                            <Grid item={true} xs={12}>
                                <StyledDivider />
                            </Grid>
                        )}
                        <Grid item={true} xs={12}>
                            <InputLabel>User {index + 1}</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                fullWidth={true}
                                label=""
                                value={item}
                                variant="outlined"
                                required={true}
                                onChange={updateUserMethod}
                            />
                        </Grid>
                        <Grid item={true} xs={12} style={{ display: 'grid', justifyContent: 'end' }}>
                            <StyledButton text="Delete" icon={DeleteForeverOutlined} variant={'text'}
                                          onClick={deleteUserMethod} />
                        </Grid>
                    </>
                );
            });
        }

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Title</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={title}
                        label=""
                        required={true}
                        onChange={this.updateTitle}
                        variant="outlined"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Description</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={description}
                        label=""
                        required={true}
                        onChange={this.updateDescription}
                        variant="outlined"
                        multiline={true}
                        rows={4}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Items</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={0} style={{backgroundColor: '#f5f5f5', width: '100%', margin: 0, padding: 10}}>
                        {itemViews}
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <StyledButton text="Add Item" icon={AddOutlined} variant={'text'} onClick={this.addItem} />
                    <StyledButton text="Add Pre Defined" icon={ListOutlined} variant={'text'} onClick={this.openPreDefined} />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Start</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <DateTimePicker
                        label=""
                        value={from}
                        onChange={this.updateFrom}
                        disablePast={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>End</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <DateTimePicker
                        label=""
                        value={to}
                        onChange={this.updateTo}
                        disablePast={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <LabelCheckBox
                        label="Compensation for all registered users"
                        checked={allUsers}
                        onChange={this.updateAllUsers}
                        readOnly={false}
                        labelPlacement={'end'}
                    />
                </Grid>
                {!allUsers && (
                    <>
                        <Grid item={true} xs={12}>
                            <InputLabel>Users</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Grid container={true} spacing={0} style={{backgroundColor: '#f5f5f5', width: '100%', margin: 0, padding: 10}}>
                                {usersViews}
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <StyledButton text="Add User" icon={AddOutlined} variant={'text'} onClick={this.addUser} />
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onUpdate} variant="text" color="secondary" disabled={!this.validate()}/>
        );
    }

    protected renderExtraDialogs(): React.JSX.Element {
        const { preDefinedItems } = this.props;
        const { preDefinedSelectOpen } = this.state;
        return (
            <SelectCompensationPreDefinedItemDialog
                open={preDefinedSelectOpen}
                items={preDefinedItems}
                onClose={this.onClosePreDefined}
                onSelect={this.onSelectPreDefined}
            />
        );
    }

    protected initState(): State {
        const { selectedCompensationData } = this.props;
        if (!selectedCompensationData) {
            return {
                preDefinedSelectOpen: false,
                title: '',
                description: '',
                from: moment.parseZone(),
                to:moment.parseZone(),
                allUsers: false,
                users: [],
                items: [],
            };
        }

        const items: Entities.CompensationItem[] = [];
        selectedCompensationData.itemList.forEach(item => {
           const split = item.split(':');
           items.push({
               Key: split[0],
               Amount: parseInt(split[1], 10),
           });
        });

        return {
            preDefinedSelectOpen: false,
            title: selectedCompensationData.title,
            description: selectedCompensationData.description,
            from: moment(selectedCompensationData.from),
            to: moment(selectedCompensationData.to),
            allUsers: selectedCompensationData.allUsers,
            users: selectedCompensationData.userList,
            items,
        };

    }

    private updateTitle = (title: string) => this.setState({ title });
    private updateDescription = (description: string) => this.setState({ description });
    private updateFrom = (from: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (from === null) {
            return;
        }
        this.setState({ from });
    }
    private updateTo = (to: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (to === null) {
            return;
        }
        this.setState({ to });
    }
    private updateAllUsers = (allUsers: boolean) => this.setState({ allUsers });

    private updateKey = (index: number, key: string) => {
        const { items } = this.state;
        if(items.length < index) {
            return;
        }
        items[index].Key = key;
        this.setState({items});
        this.forceUpdate();
    };
    private updateAmount = (index: number, amount: number) => {
        const { items } = this.state;
        if(items.length < index) {
            return;
        }
        items[index].Amount = amount;
        this.setState({items});
        this.forceUpdate();
    };

    private addItem = () => {
        this.setState({items: [...this.state.items, { Key: '', Amount: 0 }]});
    };
    private deleteItem = (index: number) => {
        const items = this.state.items;
        if(items.length < index) {
            return;
        }
        this.setState({items: items.filter((item, i) => i !== index)});
    };

    private updateUser = (index: number, id: string) => {
        const { users } = this.state;
        if (users.length < index) {
            return;
        }
        // @ts-ignore
        users[index] = id;
        this.setState({ users });
        this.forceUpdate();
    };
    private addUser = () => {
        this.setState({users: [...this.state.users, '']});
    };
    private deleteUser = (index: number) => {
        const users = this.state.users;
        if(users.length < index) {
            return;
        }
        this.setState({users: users.filter((item, i) => i !== index)});
    };

    private validate(): boolean {
        
        const { title, description, items, allUsers, users } = this.state;
        if (title === '' || description === '' || items.length === 0) {
            return false;
        }

        return !(!allUsers && users.length === 0);
    }

    private onUpdate = () => {
        if (!this.validate()) {
            return;
        }

        const {title, description, from, to, allUsers, users, items } = this.state;
        const fromValue = from.utc().valueOf();
        const toValue = to.utc().valueOf();

        let itemList = "";
        items.forEach(item => {
            itemList += `${item.Key}:${item.Amount}|`;
        });
        itemList = itemList.substring(0, itemList.length - 1);

        let userList = "";
        users.forEach(user => userList += `${user}|`);
        userList = userList.substring(0, userList.length - 1);

        this.props.onUpdate(title, description, fromValue, toValue, allUsers, userList, itemList);
        this.onClose();
    };

    private openPreDefined = () => this.setState({ preDefinedSelectOpen: true });
    private onClosePreDefined = () => this.setState({ preDefinedSelectOpen: false });
    private onSelectPreDefined = (key: string, amount: number) => {
        this.setState({
            preDefinedSelectOpen: false,
            items: [...this.state.items, { Key: key, Amount: amount }]
        });
    };
}

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));

const StyledDivider = styled(Divider)(({
    backgroundColor: 'rgb(245, 245, 245, 0.5)',
    width: '100%',
    margin: '8px 0px',
}));
