import * as React from 'react';
import { Grid, InputLabel, TextField as MTextField } from '@mui/material';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';

interface Props extends BaseDialogProps {
    readonly onCreate: (name: string, apiUrl: string, apiSecretKey: string) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly apiUrl: string;
    readonly apiSecretKey: string;
}

export class CreateTitleDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { name, apiUrl, apiSecretKey } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <MTextField
                        fullWidth={true}
                        value={name}
                        label=""
                        required={true}
                        variant="outlined"
                        onChange={this.updateName}
                        disabled={false}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Api Url</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={apiUrl}
                        label=""
                        required={true}
                        variant="outlined"
                        onChange={this.updateApiUrl}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Api Secret Key</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={apiSecretKey}
                        label=""
                        required={true}
                        variant="outlined"
                        onChange={this.updateApiSecretKey}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="text" color="secondary" disabled={!this.validateTitle()} />
        );
    }

    protected initState(): State {
        return {
            name: '',
            apiUrl: '',
            apiSecretKey: '',
        };
    }

    private updateName = (event: any) => this.setState({ name: event.target.value });

    private updateApiUrl = (apiUrl: string) => this.setState({ apiUrl });

    private updateApiSecretKey = (apiSecretKey: string) => this.setState({ apiSecretKey });

    private validateTitle = () =>  this.state.name !== '' && this.state.apiUrl !== '';

    private onCreate = () => {
        const { name, apiUrl, apiSecretKey } = this.state;
        this.props.onCreate(name, apiUrl, apiSecretKey);
        this.onClose();
    };
}
