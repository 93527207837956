import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import { BaseContainer, BaseElement, CreatePromoCampaignComponent } from '../components';
import actions from '../actions';
import { CardGiftcard as CardGiftcardIcon } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly promoCampaigns: Entities.PromoCampaign[];
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        await actions.promoCampaign.list();
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Promo Campaigns',
                to: `/marketing/promoCampaigns`,
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                title = {"Create Promo Campaigns"}
                TitleIcon = {CardGiftcardIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { preDefinedItems } = this.props;
        return (
            <CreatePromoCampaignComponent promoCampaigns={[]} preDefinedItems={preDefinedItems} onCreate={this.onCreate} />
        );
    }

    private onCreate = async (
        name: string,
        start: number,
        end: number,
        maxRedeems: number,
        scheduleColor: string,
        developerOnly: boolean,
        codes: string[],
        rewards: Entities.PromoCampaignReward[]
    ) => {
        await actions.promoCampaign.create(
            name,
            start,
            end,
            maxRedeems,
            scheduleColor,
            developerOnly,
            codes,
            rewards,
        );
        this.toPromoCampaigns();
    };

    private toPromoCampaigns = () => this.toLink( `/marketing/promoCampaigns`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    promoCampaigns: state.promoCampaign.promoCampaigns,
    preDefinedItems: state.costumerSupport.preDefinedItems,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate} />);
};
export default connect(mapStateToProps)(AppContainer);
