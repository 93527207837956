import { Action } from './index';
import { AppActionType, UserActionType } from '../enums';

export interface UserState {
    readonly loginUser?: Entities.User | null;
    readonly users: Entities.User[];
}

export type UserAction = Action<Partial<UserState>>;

const initState: UserState = {
    users: [],
};

const reducer = (
    state: UserState = initState,
    action: UserAction,
): UserState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case UserActionType.UpdateUsers:
            return { ...state, ...action.payload };
        case UserActionType.UpdateLoginUser:
            return { ...state, ...action.payload };
    }
    return state;
};
export default reducer;
