import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { ContainerContext, mapProps } from './';
import { CreateLeaderBoardDialog, BaseContainer, Table, BaseElement, HeaderButton, WarningDialog } from '../components';
import { AggregationMethod, ResetFrequency, UserTitleRole } from '../enums';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    Newspaper as NewspaperIcon,
    Poll as PollIcon,
} from '@mui/icons-material';
import { formatDate, getLeaderBoardLink } from '../utils';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Create' | 'Reset';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedStat: Entities.Statistic | null;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedStat: null
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openCreateDialog}
        ];
        return (
            <BaseContainer
                {...this.props}
                title = {"LeaderBoards"}
                TitleIcon = {PollIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderConfirmResetDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Reset Frequency', field: 'resetFrequency'},
            { title: 'Aggregation Method', field: 'aggregationMethod'},
            { title: 'Current version', field: 'currentVersion'},
        ];

        const onRowClick = (event: any, rowData: any) => this.toLink(`/leaderBoard/${rowData.stat.name}/details`);

        return (
            <Table
                data={this.getData}
                columns={columns}
                onRowClick={onRowClick}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: false,
                    draggable: false,
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                }}
                actions={ userTitleRole > UserTitleRole.Viewer ?
                    [
                        rowData => ({
                            icon: DeleteOutlineOutlined,
                            tooltip: 'reset',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openResetDialog(rowData.stat)
                        }),
                    ]
                    : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`statistic/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.Statistic) => {
            return {
                stat: e,
                details: this.getDetails(e),
                resetFrequency: ResetFrequency[e.resetFrequency],
                aggregationMethod: AggregationMethod[e.aggregationMethod],
                created: formatDate(e.created),
                currentVersion: e.currentVersion,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getDetails = (statistic: Entities.Statistic) => {
        return (
            <div>
                {getLeaderBoardLink(statistic.name)}
                <div>
                    <div style={{ fontWeight: 500 }}>Created</div>
                    {formatDate(statistic.created)}
                </div>
            </div>
        );
    };

    private renderCreateDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateLeaderBoardDialog
                open={openDialog === 'Create'}
                title={'Create LeaderBoard'}
                TitleIcon = {PollIcon}
                onCreate={this.onCreateStatistic}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () => {
        this.setState({ openDialog: null });
    };

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });

    private onCreateStatistic = async (name: string, resetFrequency: ResetFrequency, aggregationMethod: AggregationMethod) => {
        this.closeDialog();
        await actions.statistic.create(name, resetFrequency, aggregationMethod);
    };

    private openResetDialog = (selectedStat: Entities.Statistic) => this.setState({openDialog: 'Reset', selectedStat});
    private renderConfirmResetDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'Reset'}
                title={'Confirm Delete'}
                TitleIcon={NewspaperIcon}
                content="Do you want to reset the leaderboard."
                onClose={this.closeDialog}
                onSubmit={this.reset}
                maxWidth={'xs'}
            />
        );
    };
    private reset = async () => {
        const { selectedStat } = this.state;
        this.closeDialog();
        if (!selectedStat) {
            return;
        }
        await actions.statistic.reset(selectedStat.name);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
