import React from 'react';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Cancel, Search } from '@mui/icons-material';
import { styled } from '@mui/styles';

interface Props {
    readonly isRemoteData: boolean;
    readonly searchText: string;
    readonly readOnly?: boolean;
    readonly onSearch: (searchText: string) => void;
}

interface State {
    readonly searchText: string;
    readonly autoFocus: boolean;
}

export class SearchTextField extends React.Component<Props, State> {
    state = {
        searchText: '',
        autoFocus: false,
    };

    componentDidMount() {
        const { searchText } = this.props;
        this.setState({ searchText });
    }

    componentDidUpdate(prevProps: Props) {
        const { searchText } = this.props;
        if ( prevProps.searchText === searchText ) {
            return;
        }

        this.setState({ searchText });
    }

    render() {
        const { isRemoteData, readOnly } = this.props;
        const { autoFocus, searchText } = this.state;

        const onSearchChange = (event: any) => {
            const search = event.target.value;
            onSearch(search, true);
        };

        const onSearchReset = () => {
            this.setState(
                { searchText: '', autoFocus: false },
                () => this.props.onSearch(''),
            );
        };

        const onSearch = (search: string, enableAutoFocus: boolean, forceRemote: boolean = false) => {
            this.setState(
                { searchText: search, autoFocus: enableAutoFocus },
                () => {
                    if (isRemoteData && !forceRemote) {
                        return;
                    }
                    this.props.onSearch(search);
                }
            );
        };

        const onKeyPress = (ev: any) => {
            if (ev.key === 'Enter' && isRemoteData && autoFocus) {
                onSearch(searchText, false, true);
            }
        };

        return (
            <StyleOutlinedInput
                autoFocus={autoFocus}
                onChange={onSearchChange}
                value={searchText}
                placeholder="Search"
                readOnly={readOnly}
                startAdornment={
                    <InputAdornment position="end">
                        <Search fontSize="small" />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            disabled={!this.state.searchText}
                            onClick={onSearchReset}
                        >
                            <Cancel
                                fontSize="small"
                                aria-label="clear"
                            />
                        </IconButton>
                    </InputAdornment>
                }
                onKeyPress={onKeyPress}
            />
        );
    }
}

const StyleOutlinedInput = styled(OutlinedInput)(({
    width: '100%',
    padding: 0,
    borderRadius: 0,
    cursor: 'text',
    height: 24,
    color: 'rgba(0,0,0,0.5)',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-input': {
        padding: 5,
        fontSize: 13,
    }
}));
