import { BaseDialogState } from '../common/dialog/BaseDialog';
import * as React from 'react';
import { Grid, Chip, DialogProps, Dialog, DialogContent } from '@mui/material';
import { localeNumber } from '../../utils';

interface Props extends DialogProps {
    readonly segments: Entities.Segment[];
    readonly onSelectSegment: (id: string) => void;
    readonly onClose: () => void;
}

interface State extends BaseDialogState {
}

export class SelectSegmentDialog extends React.Component<Props, State> {
    state = this.initState();

    render() {
        const { segments, onSelectSegment } = this.props;
        const segmentElements: React.JSX.Element[] = [];
        segments.forEach(segment => {
            const selectSegment = () => {
                onSelectSegment(segment.id);
            };
            segmentElements.push(
                <Grid item={true} xs={12} sm={6}>
                    <Chip
                        color={'secondary'}
                        label={`${segment.name}`}
                        style={{ width: '100%', backgroundColor: `${segment.color}`, color: 'white' }}
                        onClick={selectSegment}
                    />
                </Grid>
            );
        });

        return (
            <Dialog
                open={this.props.open}
                onClose={this.onClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogContent style={{ padding: 20 }}>
                    <Grid container={true} direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                        {segmentElements}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    protected initState(): State {
        return {
            name: '',
            rules: [],
        };
    }

    private onClose = () => {
        this.setState(this.initState());
        this.props.onClose();
    };
}
