import { TitleNewsStatus } from '../enums';
import { Request } from '../utils';

export const create = async (date: number, status: TitleNewsStatus, title: string, body: string, file: any, metadata: string, segmentFilterId: string | null) => {
    const formData = new FormData();
    formData.append("date", date.toString());
    formData.append("status", status.toString());
    formData.append("title", title);
    formData.append("body", body);
    formData.append("file", file);
    formData.append("metadata", metadata);
    if (segmentFilterId) {
        formData.append("segmentFilterId", segmentFilterId);
    }
    await Request.postForm(`titleNews/create`, formData);
};

export const remove = async (newsId: string) => {
    await Request.post(`titleNews/delete`, { newsId });
};

export const removeMultiples = async (newsIds: string[]) => {
    await Request.post(`titleNews/deleteMultiple`, { newsIds });
};

export const update = async (newsId: string, date: number, status: TitleNewsStatus, title: string, body: string, file: any, metadata: string, segmentFilterId: string | null) => {
    const formData = new FormData();
    formData.append("id", newsId);
    formData.append("date", date.toString());
    formData.append("status", status.toString());
    formData.append("title", title);
    formData.append("body", body);
    formData.append("file", file);
    formData.append("metadata", metadata);
    if (segmentFilterId) {
        formData.append("segmentFilterId", segmentFilterId);
    }
    await Request.postForm(`titleNews/update`, formData);
};
