import { Action } from './index';
import { AppActionType, TitleActionType } from '../enums';

export interface TitleState {
    readonly selectedTitle?: Entities.UserTitleData;
    readonly titles?: Entities.UserTitleData[];
    readonly config?: Entities.Config;
    readonly users?: Entities.UserTitleRoleData[];
}

export type TitleAction = Action<Partial<TitleState>>;

const initState = (): TitleState => ({});

const reducer = (
    state: TitleState = initState(),
    action: TitleAction,
): TitleState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case TitleActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state, selectedTitle: undefined };
    }
    return state;
};
export default reducer;
