import React from 'react';
import { Filter } from '../common';
import { BarChartOutlined, DeveloperModeOutlined, EventOutlined } from '@mui/icons-material';
import { SeasonTypeFilter, SeasonStatusFilter } from '../../enums';
import { Checkbox } from '@mui/material';
import { FilterList, FilterListItem, FilterListItemIcon, FilterListItemText } from '../../utils';
import { toNumber } from 'lodash';

interface Props {
    readonly developerOnly: boolean;
    readonly typeFilter: SeasonTypeFilter;
    readonly statusFilter: SeasonStatusFilter;
    readonly onFilter: (typeFilter: SeasonTypeFilter, statusFilter: SeasonStatusFilter, developerOnly: boolean) => void;
}

interface State {
    readonly typeFilter: SeasonTypeFilter;
    readonly statusFilter: SeasonStatusFilter;
    readonly developerOnly: boolean;
}

export class SeasonFilter extends React.Component<Props, State> {
    state: State = {
        typeFilter: SeasonTypeFilter.All,
        statusFilter: SeasonStatusFilter.All,
        developerOnly: false,
    };

    componentDidUpdate(prevProps: Props) {
        const { developerOnly, typeFilter, statusFilter } = this.props;
        if ( prevProps.developerOnly === developerOnly && prevProps.typeFilter === typeFilter && prevProps.statusFilter === statusFilter ) {
            return;
        }

        this.setState({
            typeFilter,
            statusFilter,
            developerOnly,
        });
    }

    render() {
        const { typeFilter, statusFilter, developerOnly } = this.state;

        const onFilterDeveloper = () => this.setState({ developerOnly: !developerOnly });

        const onFilter = () => {
            this.props.onFilter(typeFilter, statusFilter, developerOnly);
        };

        const onClose = () => this.setState({
            typeFilter: this.props.typeFilter,
            statusFilter: this.props.statusFilter,
            developerOnly: this.props.developerOnly,
        });

        return (
            <Filter
                title={'Filter Seasons'}
                menuTitles = {['Event Type', 'Status', 'Player Type']}
                menuElements = {[
                    (
                        <FilterList>
                            {Object.keys(SeasonTypeFilter)
                                .filter((key) => Number.isNaN(+key))
                                .map((key, index) => {
                                    if (index === SeasonTypeFilter.All) {
                                        return <></>;
                                    }
                                    const handleTypeFilterChange = () => this.setState({ typeFilter: index });

                                    return (
                                        <FilterListItem
                                            key={`season_type_filter_${index}`}
                                            onClick={handleTypeFilterChange}
                                            secondaryAction={
                                                <Checkbox
                                                    edge="end"
                                                    onChange={handleTypeFilterChange}
                                                    checked={typeFilter === index}
                                                />
                                            }
                                        >
                                            <FilterListItemIcon>
                                                {index === SeasonTypeFilter.Regular ? <EventOutlined style={{ width: 18, height: 18, marginRight: 5 }} /> : <BarChartOutlined style={{ width: 18, height: 18, marginRight: 5 }} />}
                                            </FilterListItemIcon>
                                            <FilterListItemText text={key} />
                                        </FilterListItem>
                                    );
                                }
                            )}
                        </FilterList>
                    ),
                    (
                        <FilterList>
                            {Object.keys(SeasonStatusFilter)
                                .filter((key) => Number.isNaN(+key))
                                .map((key, index) => {
                                    if (index === SeasonStatusFilter.All) {
                                        return;
                                    }
                                    const handleStatusFilterChange = () => this.setState({ statusFilter: index });

                                    let text = '';
                                    let color = '';
                                    switch (index) {
                                        case SeasonStatusFilter.Active:
                                            text = 'In Progress';
                                            color = 'green';
                                            break;
                                        case SeasonStatusFilter.Coming:
                                            text = 'Scheduled';
                                            color = 'grey';
                                            break;
                                        case SeasonStatusFilter.Completed:
                                            text = 'Completed';
                                            color = 'orange';
                                            break;
                                    }
                                    return (
                                        <FilterListItem
                                            key={`season_status_filter_${index}`}
                                            onClick={handleStatusFilterChange}
                                            secondaryAction={
                                                <Checkbox
                                                    edge="end"
                                                    onChange={handleStatusFilterChange}
                                                    checked={statusFilter === index}
                                                />
                                            }
                                        >
                                            <FilterListItemText text={text} color={color} />
                                        </FilterListItem>
                                    );
                                }
                            )}
                        </FilterList>
                    ),
                    (
                        <FilterList>
                            <FilterListItem
                                key={`developer_only`}
                                onClick={onFilterDeveloper}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterDeveloper}
                                        checked={developerOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon>
                                    <DeveloperModeOutlined style={{ width: 18, height: 18, color: '#21BA47' }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Developers Only'} />
                            </FilterListItem>
                        </FilterList>
                    ),
                ]}
                onFilter={onFilter}
                onClose={onClose}
            />
        );
    }
}
