import React from 'react';
import { formatDate } from '../../utils';
import axios, { AxiosRequestConfig } from 'axios';
import { AppBar, Grid, Tab, Tabs } from '@mui/material';
import { default as moment } from 'moment';
import { LogsAceEditor, Table } from '../common';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { CloudBuildReport } from './CloudBuildReport';
import { styled } from '@mui/styles';

interface Props {
    readonly job: Entities.CloudBuildJobDetails;
    readonly cloudBuildId: string;
    readonly buildNumber: string;
}

interface State {
    readonly tagIndex: number;
}

export class CloudBuildDetails extends React.Component<Props, State> {
    state: State = {
        tagIndex: 0,
    };

    render() {
        const { job } = this.props;
        const { cloudBuildId, buildNumber } = this.props;
        const { tagIndex } = this.state;

        const reportArtifact = job.artifacts.find(a => a.name.includes(".json"));
        const changesColumns = [
            { title: 'Rev #', field: 'revision'},
            { title: 'Timestamp', field: 'timestamp'},
            { title: 'User', field: 'user'},
            { title: 'File changes', field: 'changes'},
        ];

        const changesData = job.changeSet ? job.changeSet.map(item => {
            const revision = (
                <div>
                    {item.commitId}
                    <div style={{
                        backgroundColor: '#E4F2FE',
                        padding: '2px 8px',
                        margin: '8px 0px',
                        borderRadius: 2,
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: '0.75rem',
                    }}>
                        <b>Notes: </b>{item.comment}
                    </div>
                </div>
            );

            return {
                revision,
                timestamp: formatDate(item.timestamp),
                user: item.user,
                changes: `${item.changesCount} file changed`,
            };
        }) : [];

        const artifactsColumns = [
            { title: 'File', field: 'fileName'},
        ];

        const artifactsData = job.artifacts.map(artifact => {
            return {
                ...artifact,
                fileName: artifact.name,
                size: '',
                created: '',
            };
        });

        const onDownloadArchive = async (artifact: any) => {
            try {
                const config: AxiosRequestConfig = {
                    url: artifact.file.href,
                    method: 'GET',
                    responseType: 'blob',
                    auth: {
                        username: "DevOps",
                        password: "6Trainstation"
                    },
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': 'true',
                        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                        'Access-Control-Allow-Headers': 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
                    },
                };

                const response = await axios.request(config);

                if (!response) {
                    return;
                }

                const file = new Blob([response.data], {type: response.headers['content-type']});
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file);
                link.download = artifact.fileName;
                link.click();
            } catch (error) {
                console.log(error);
            }
        };

        return (
            <div style={{
                width: '100%',
                boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                border: 'none',
                borderRadius: 0,
                boxSizing: 'border-box',
                backgroundColor: 'white',
                padding: 0,
            }}>
                <AppBarWithStyle position="static">
                    <TabsWithStyle
                        value={tagIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        allowScrollButtonsMobile={true}
                        scrollButtons="auto"
                    >
                        <TabWithStyle label="Overview" />
                        <TabWithStyle label="Logs" />
                        <TabWithStyle label={`Changes (${job.changeSet.length})`} />
                        <TabWithStyle label={`Artifacts (${job.artifacts.length})`} />
                        {reportArtifact && (
                            <TabWithStyle label="Report" />
                        )}
                    </TabsWithStyle>
                </AppBarWithStyle>
                <TabPanel value={tagIndex} index={0}>
                    <div style={{ padding: 20 }}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Result:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{job.result}</PSubtitle>
                            </Grid>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Build target:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{job.displayName}</PSubtitle>
                            </Grid>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Started:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{formatDate(job.timestamp)}</PSubtitle>
                            </Grid>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Build time:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{moment.duration(job.duration).humanize()}</PSubtitle>
                            </Grid>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Branch:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{job.branch}</PSubtitle>
                            </Grid>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Last commit:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{job.commit}</PSubtitle>
                            </Grid>
                            <Grid item={true} xs={4} sm={3} md={2}>
                                <PTitle>Clean build:</PTitle>
                            </Grid>
                            <Grid item={true} xs={8} sm={9} md={10}>
                                <PSubtitle>{job.cleanBuild ? 'TRUE' : 'FALSE'}</PSubtitle>
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>
                <TabPanel value={tagIndex} index={1}>
                    <LogsAceEditor
                        url={`devOps/cloudBuild/${cloudBuildId}/${buildNumber}/getLogs`}
                        mode="sh"
                        theme="github"
                        value={""}
                        showPrintMargin={false}
                        showGutter={false}
                        highlightActiveLine={false}
                        height={`${screen.height-400}px`}
                        setOptions={{
                            showLineNumbers: false,
                            tabSize: 2,
                        }}
                        showGoTo={true}
                    />
                </TabPanel>
                <TabPanel value={tagIndex} index={2}>
                    <div style={{ padding: 0 }}>
                        <Table
                            data={changesData}
                            columns={changesColumns}
                            showBox={false}
                            options={{
                                showTitle: false,
                                selection: false,
                                paging: false,
                                search: false,
                                sorting: false,
                                draggable: false,
                                headerStyle: {
                                    height: 30,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                },
                            }}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={tagIndex} index={3}>
                    <div style={{ padding: 0 }}>
                        <Table
                            data={artifactsData}
                            columns={artifactsColumns}
                            showBox={false}
                            options={{
                                showTitle: false,
                                selection: false,
                                paging: false,
                                search: false,
                                sorting: false,
                                draggable: false,
                                exportButton: false,
                                headerStyle: {
                                    height: 30,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                },
                                actionsColumnIndex: -1,
                            }}
                            actions={[
                                rowData => ({
                                    tooltip: 'Download',
                                    position: 'row',
                                    icon: CloudDownloadOutlined,
                                    onClick: () => onDownloadArchive(rowData)
                                }),
                            ]}
                        />
                    </div>
                </TabPanel>
                {reportArtifact && (
                    <TabPanel value={tagIndex} index={4}>
                        <CloudBuildReport
                            cloudBuildId={cloudBuildId}
                            buildNumber={buildNumber}
                            reportArtifact={reportArtifact}
                        />
                    </TabPanel>
                )}
            </div>
        );
    }

    private handleChange = (event: any, newValue: any) => this.setState({ tagIndex: newValue });
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const hidden = value !== index;
    if (hidden) {
        return (<></>);
    }

    return (
        <div
            role="cloud-build-details"
            id={`cloud-build-details-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

const AppBarWithStyle = styled(AppBar)(({
    '&.MuiAppBar-root': {
        minHeight: 40,
        margin: 0,
        backgroundColor: '#fafafa',
    },
    '& .MuiAppBar-flexContainer': {
        display: 'box',
    },
    '& .MuiAppBar-indicator': {
        display: 'contents',
        backgroundColor: '#0073bb',
    },
}));

const TabsWithStyle = styled(Tabs)(({
    '&.MuiTabs-root': {
        padding: 0,
        minHeight: 40,
        borderBottom: '1px solid #e0e0e0',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#2196f3 !important',
    },
    '& .MuiTabs-scrollButtons': {
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));

const TabWithStyle = styled(Tab)(({
    '&.MuiTab-root': {
        padding: '6px 20px',
        fontSize: '0.8rem',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 600,
        minWidth: 0,
        margin: 0,
        textTransform: 'none',
        minHeight: 40,
        '&:hover': {
            backgroundColor: '#eef2f8',
        },
        '&$selected': {
            backgroundColor: '#eef2f8',
        },
        '&:focus': {
            backgroundColor: '#eef2f8',
        },
    },
    '&.Mui-selected': {
        fontWeight: 'bold',
        color: '#0073bb',
    },
}));

const PTitle = styled('p')({
    color: 'rgba(0, 0, 0, 1)',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: '1.25rem',
    letterSpacing: '-0.006rem',
    padding: 0,
    margin: 0,
});

const PSubtitle = styled('p')({
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: 400,
    fontSize: '0.8rem',
    lineHeight: '1.25rem',
    letterSpacing: '-0.006rem',
    padding: 0,
    margin: 0,
});
