import * as React from 'react';
import { Button, LabelCheckBox, NumberField, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Divider, Grid, InputLabel } from '@mui/material';
import { AddOutlined, ListOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import { default as moment, Moment } from 'moment';
import { MobileDatePicker as DatePicker, MobileTimePicker as TimePicker } from '@mui/x-date-pickers';;
import { SelectCompensationPreDefinedItemDialog } from '../compensation';
import { styled } from '@mui/styles';

interface Props extends BaseDialogProps {
    readonly selected: Entities.PlayerCompensation;
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
    readonly onUpdate: (id: string, data: any) => void;
}

interface State extends BaseDialogState {
    readonly preDefinedSelectOpen: boolean;
    readonly name: string;
    readonly description: string;
    readonly schedule: Moment | null;
    readonly items: Entities.CompensationItem[];
}

export class UpdatePlayerCompensationDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.selected.id !== prevProps.selected.id) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { name, description, schedule, items } = this.state;

        const itemViews: React.JSX.Element[] = [];
        items.forEach((item, index) => {
            const updateKeyMethod = (key: string) => this.updateKey(index, key);
            const updateAmountMethod = (amount: number) => this.updateAmount(index, amount);
            const deleteMethod = () => this.deleteItem(index);

            itemViews.push(
                <>
                    {index > 0 && (
                        <Grid item={true} xs={12}>
                            <StyledDivider />
                        </Grid>
                    )}
                    <Grid item={true} xs={12}>
                        <InputLabel>Key</InputLabel>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextField
                            fullWidth={true}
                            label=""
                            value={item.Key}
                            variant="outlined"
                            required={true}
                            onChange={updateKeyMethod}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <InputLabel>Amount</InputLabel>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <NumberField
                            fullWidth={true}
                            label=""
                            value={item.Amount}
                            variant="outlined"
                            required={true}
                            onChange={updateAmountMethod}
                        />
                    </Grid>
                    <Grid item={true} xs={12} style={{display: 'grid', justifyContent: 'end', padding: 0}}>
                        <StyledButton text="Delete" icon={DeleteForeverOutlined} variant={'text'} onClick={deleteMethod} />
                    </Grid>
                </>
            );
        });

        return (
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.updateName}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel>Description</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        label=""
                        value={description}
                        variant="outlined"
                        multiline={true}
                        rows={4}
                        fullWidth={true}
                        onChange={this.updateDescription}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <LabelCheckBox
                        label="Schedule in the future"
                        checked={schedule !== null}
                        onChange={this.updateScheduleInTheFuture}
                        readOnly={false}
                        labelPlacement={'end'}
                    />
                </Grid>
                {schedule !== null && (
                    <>
                        <Grid item={true} xs={12}>
                            <InputLabel>Schedule Date</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <DatePicker
                                label=""
                                value={schedule}
                                onChange={this.updateSchedule}
                                disablePast={true}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <InputLabel>Schedule Time</InputLabel>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TimePicker
                                ampm={false}
                                label=""
                                value={schedule}
                                onChange={this.updateSchedule}
                            />
                        </Grid>
                    </>
                )}
                <Grid item={true} xs={12}>
                    <InputLabel>Items</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2} style={{backgroundColor: '#f5f5f5', width: '100%', margin: 0, padding: 10}}>
                        {itemViews}
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <StyledButton text="Add Item" icon={AddOutlined} variant={'text'} onClick={this.addItem} />
                    <StyledButton text="Add Pre Defined" icon={ListOutlined} variant={'text'} onClick={this.openPreDefined} />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    protected renderExtraDialogs(): React.JSX.Element {
        const { preDefinedItems } = this.props;
        const { preDefinedSelectOpen } = this.state;
        return (
            <SelectCompensationPreDefinedItemDialog
                open={preDefinedSelectOpen}
                items={preDefinedItems}
                onClose={this.onClosePreDefined}
                onSelect={this.onSelectPreDefined}
            />
        );
    }

    protected initState(): State {
        const { selected } = this.props;
        if (!selected) {
            return {
                preDefinedSelectOpen: false,
                name: '',
                description: '',
                schedule: null,
                items: [{ Key: '', Amount: 0}]
            };
        }

        return {
            preDefinedSelectOpen: false,
            name: selected.name,
            description: selected.description,
            schedule: selected.schedule ? moment(selected.schedule) : null,
            items: selected.items.map(i => ({ Key: i.key, Amount: i.amount}))
        };
    }

    private updateScheduleInTheFuture = (active: boolean) => {
        const schedule = active? moment.parseZone() : null;
        this.setState({ schedule });
    }

    private addItem = () => {
        this.setState({items: [...this.state.items, { Key: '', Amount: 0 }]});
    };
    private deleteItem = (index: number) => {
        const items = this.state.items;
        if(items.length < index) {
            return;
        }
        this.setState({items: items.filter((item, i) => i !== index)});
    };

    private updateName = (name: string) => this.setState({ name });
    private updateDescription = (description: string) => this.setState({ description });
    private updateKey = (index: number, key: string) => {
        const { items } = this.state;
        if(items.length < index) {
            return;
        }
        items[index].Key = key;
        this.setState({items});
        this.forceUpdate();
    };
    private updateAmount = (index: number, amount: number) => {
        const { items } = this.state;
        if(items.length < index) {
            return;
        }
        items[index].Amount = amount;
        this.setState({items});
        this.forceUpdate();
    };
    private updateSchedule = (schedule: Moment | null) => {
        if (!schedule) {
            return;
        }
        this.setState({ schedule});
    }

    private validate(): boolean {
        const { name, description, items } = this.state;
        if( name.length === 0 || description.length === 0 || items.length === 0 ) {
            return false;
        }
        let valid = true;
        items.forEach(item => {
            if(item.Key.length === 0 || item.Amount === 0) {
                valid = false;
            }
        });
        return valid;
    }

    private openPreDefined = () => this.setState({ preDefinedSelectOpen: true });
    private onClosePreDefined = () => this.setState({ preDefinedSelectOpen: false });
    private onSelectPreDefined = (key: string, amount: number) => {
        this.setState({
            preDefinedSelectOpen: false,
            items: [...this.state.items, { Key: key, Amount: amount }]
        });
    };

    private onSubmit = () => {
        const { selected } = this.props;
        const { name, description, schedule, items } = this.state;
        if (!this.validate()) {
            return;
        }
        const data = {
            name,
            description,
            schedule: null,
            items
        };
        if (schedule) {
            // @ts-ignore
            data.schedule = schedule.utc().valueOf();
        }
        this.props.onUpdate(selected.id, data);
        this.onClose();
    };
}

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    fontWeight: 'normal',
    textTransform: 'none',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    }
    ,
    '&:hover': {
        backgroundColor: 'transparent',
    }
}));

const StyledDivider = styled(Divider)(({
    backgroundColor: 'rgb(245, 245, 245, 0.5)',
    width: '100%',
    margin: '8px 0px',
}));
