import * as React from 'react';
import actions, { ActionRequest } from '../actions';
import {
    AddOutlined,
    BarChartOutlined,
    DeleteOutlineOutlined,
    DeveloperModeOutlined,
    EventOutlined,
    Games as GamesIcon,
    LibraryAddOutlined,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { ContainerContext, mapProps } from './index';
import { connect } from 'react-redux';
import { Chip, Grid, Hidden, Menu, MenuItem } from '@mui/material';
import { formatDate, isActive, isComing, isEnded, Link, truncateString } from '../utils';
import {
    WarningDialog,
    BaseContainer,
    EditLiveSeasonDialog,
    SeasonFilter,
    Table,
    BaseElement,
    HeaderButton,
} from '../components';
import { SeasonTypeFilter, SeasonStatusFilter, SeasonType, UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { styled } from '@mui/styles';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly list?: Entities.ListSeasonsResponse;
}

type DialogType = 'Create' | 'Edit' | 'EditLive' | 'ConfirmDelete';
interface State {
    readonly openDialog: DialogType | null;
    readonly developerOnly: boolean;
    readonly typeFilter: SeasonTypeFilter;
    readonly statusFilter: SeasonStatusFilter;
    readonly selectedSeason: Entities.Season | null;
    readonly createAnchorEl: HTMLElement | undefined;
}

class Container extends BaseElement<Props, State> {
    state = {
        totalCount: 0,
        typeFilter: SeasonTypeFilter.All,
        statusFilter: SeasonStatusFilter.All,
        developerOnly: false,
        openDialog: null,
        selectedSeason: null,
        createAnchorEl: undefined,
    };

    protected renderContainer(): React.JSX.Element {
        const handleMenu = (event: any) => this.setState({ createAnchorEl: event.currentTarget });
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: handleMenu}
        ];
        return (
            <BaseContainer
                {...this.props}
                title = "Seasons"
                TitleIcon = {GamesIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateMenu()}
                {this.renderConfirmDeleteDialog()}
                {this.renderEditLiveDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { loading, userTitleRole } = this.props;
        const { developerOnly, typeFilter, statusFilter } = this.state;

        const tableRef = React.createRef();

        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Schedule', field: 'schedule'},
        ];

        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };

        const onResetDeveloper = () => {
            this.setState({ developerOnly: false });
            onSearch();
        };

        const onResetTypeFilter = () => {
            this.setState({ typeFilter: SeasonTypeFilter.All });
            onSearch();
        };

        const onResetStatusFilter = () => {
            this.setState({ statusFilter: SeasonStatusFilter.All });
            onSearch();
        };

        const onFilter = (type: SeasonTypeFilter, status: SeasonStatusFilter, developer: boolean) => {
            this.setState({ typeFilter: type, statusFilter: status, developerOnly: developer });
            onSearch();
        };

        const showChips = typeFilter !== SeasonTypeFilter.All || statusFilter !== SeasonStatusFilter.All || developerOnly;
        const title = (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                    <SeasonFilter
                        typeFilter={typeFilter}
                        statusFilter={statusFilter}
                        developerOnly={developerOnly}
                        onFilter={onFilter}
                    />
                </Grid>
                {showChips &&
                    <Grid item={true} xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gridColumnGap: 5, gridGap: 5}} >
                            {typeFilter === SeasonTypeFilter.Regular && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<EventOutlined />}
                                    label='Event Type: "Regular"'
                                    onDelete={onResetTypeFilter}
                                />
                            )}
                            {typeFilter === SeasonTypeFilter.LeaderBoard && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<BarChartOutlined />}
                                    label='Event Type: "Leaderboard"'
                                    onDelete={onResetTypeFilter}
                                />
                            )}
                            {statusFilter === SeasonStatusFilter.Active && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "In Progress"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {statusFilter === SeasonStatusFilter.Coming && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "Scheduled"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {statusFilter === SeasonStatusFilter.Completed && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "Completed"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {developerOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<DeveloperModeOutlined />}
                                    label='Player Type: "Developer only"'
                                    onDelete={onResetDeveloper}
                                />
                            )}
                        </div>
                    </Grid>
                }
            </Grid>
        );

        const onRowClick = (event: any, rowData: any) => this.toLink(`/season/${rowData.season.id}/details`);

        return (
            <Table
                tableRef={tableRef}
                isLoading = {loading}
                title={title}
                columns = {columns}
                onRowClick={onRowClick}
                options = {{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    sorting: false,
                    draggable: false,
                    search: true,
                    actionsColumnIndex: -1,
                }}
                data = {this.getSeasons}
                actions = {
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                hidden: !isComing(rowData.season.startTimestamp, rowData.season.endTimestamp),
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.goToEdit(rowData.season)
                            }),
                            rowData => ({
                                hidden: isComing(rowData.season.startTimestamp, rowData.season.endTimestamp) || isEnded(rowData.season.endTimestamp),
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openEditLiveDialog(rowData.season)
                            }),
                            rowData => ({
                                icon: LibraryAddOutlined,
                                tooltip: 'Duplicate',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.goToDuplicate(rowData.season)
                            }),
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openConfirmDeleteDialog(rowData.season)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private goToCreateRegular = () => this.toLink('/season/createRegular');

    private goToCreateLeaderBoard = () => this.toLink('/season/createLeaderBoard');

    private goToEdit = (season: Entities.Season) => this.toLink(`/season/${season.id}/edit`);

    private goToDuplicate = (season: Entities.Season) => this.toLink(`/season/${season.id}/duplicate`);

    private openConfirmDeleteDialog = (season: Entities.Season) => this.setState({openDialog: 'ConfirmDelete', selectedSeason: season});
    private renderConfirmDeleteDialog = () => {
        const { openDialog, selectedSeason } = this.state;
        // @ts-ignore
        const name = selectedSeason ? selectedSeason.name : '';
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title="Delete Season"
                TitleIcon = {GamesIcon}
                content={`Are you sure you want to delete the ${name} season`}
                onClose={this.onCloseDialog}
                onSubmit={this.onDelete}
                maxWidth={'xs'}
            />
        );
    };
    private onDelete = async () => {
        this.onCloseDialog();
        const { selectedSeason } = this.state;
        // @ts-ignore
        const id = selectedSeason ? selectedSeason.id : '';
        await actions.season.remove(id);
    };

    private openEditLiveDialog = (season: Entities.Season) => this.setState({openDialog: 'EditLive', selectedSeason: season});
    private renderEditLiveDialog = () => {
        const { openDialog, selectedSeason } = this.state;
        return (
            <EditLiveSeasonDialog
                open={openDialog === 'EditLive'}
                title={'Edit Season'}
                TitleIcon = {GamesIcon}
                season={selectedSeason}
                onClose={this.onCloseDialog}
                onEdit={this.onEditLive}
            />
        );
    };
    private onEditLive = async (seasonId: string, metadata: string, maxAmountPerReport: number) => {
        this.onCloseDialog();
        await actions.season.editLive(seasonId, metadata, maxAmountPerReport);
    };

    private renderCreateMenu = () => {
        const { createAnchorEl } = this.state;
        const open = !!createAnchorEl;

        const closeMenu = () => this.setState({ createAnchorEl: undefined });

        return (
            <StyledMenu
                id="menu-new-event"
                anchorEl={createAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={closeMenu}
            >
                <MenuItem color="inherit" onClick={this.goToCreateRegular} >
                    <EventOutlined style={{ paddingRight: 16 }}/>
                    Regular Season
                </MenuItem>
                <MenuItem color="inherit" onClick={this.goToCreateLeaderBoard} >
                    <BarChartOutlined style={{ paddingRight: 16 }}/>
                    Leaderboard Season
                </MenuItem>
            </StyledMenu>
        );
    };

    private getSeasons = async (query: any) => {
        const { developerOnly, typeFilter, statusFilter } = this.state;
        const { search, page, pageSize } = query;

        const list = await ActionRequest.get<Entities.ListSeasonsResponse>(`season/list`, {
            name: search,
            developerOnly,
            typeFilter,
            statusFilter,
            page: page + 1,
            perPage: pageSize,
        });

        if (!list) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const seasons = list.seasons.sort((s1, s2) => {
                const e1Active = isActive(s1.startTimestamp, s1.endTimestamp);
                const e2Active = isActive(s2.startTimestamp, s2.endTimestamp);
                const e1Coming = isComing(s1.startTimestamp, s1.endTimestamp);
                const e2Coming = isComing(s2.startTimestamp, s2.endTimestamp);
                if (e1Active && e2Coming) {
                    return -1;
                } else if (e2Active && e1Coming) {
                    return 1;
                } else if ((e1Active && !e2Active) || (e1Coming && !e2Coming)) {
                    return -1;
                } else if (!e1Active && e2Active || (!e1Coming && e2Coming)) {
                    return 1;
                }
                return s2.startTimestamp - s1.startTimestamp;
            });

        const data = seasons.map(s => {
                const eventDetails = this.getDetails(s);
                const eventSchedule = this.getSchedule(s);
                return {
                    season: s,
                    details: eventDetails,
                    schedule: eventSchedule,
                };
            }
        );
        return {
            data,
            page: list.page - 1,
            totalCount: list.totalCount,
        };
    };

    private getDetails = (season: Entities.Season) => {
        const to = `/season/${season.id}/details`;
        let icon: React.JSX.Element = (<></>);
        switch (season.type) {
            case SeasonType.Regular:
                icon = <EventOutlined style={{width: 25, height: 25}}/>;
                break;
            case SeasonType.LeaderBoard:
                icon = <BarChartOutlined style={{width: 25, height: 25}}/>;
                break;
            default:
                break;
        }

        return (
            <div>
                {season.developerOnly && (
                    <Chip
                        color={'secondary'}
                        icon={<DeveloperModeOutlined />}
                        size='small'
                        label='Developer Only'
                        style={{ backgroundColor: '#21BA47', color: 'white' }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    <Hidden smDown={true}>
                        <div style={{
                            padding: '4px 0px',
                            display: 'grid',
                            justifyContent: 'flex-end',
                            gridAutoFlow: 'column',
                        }}>
                            {icon}
                        </div>
                    </Hidden>
                    <div style={{display: 'inline-block', marginLeft: 10, verticalAlign: 'top'}}>
                        <Link to={to}>
                            {season.name !== '' ? truncateString(season.name) : season.id}
                        </Link>
                        <div>
                            <div style={{ fontWeight: 500 }}>Last Edited</div>
                            {formatDate(season.updated)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private getSchedule = (season: Entities.Season) => {
        return (
            <div>
                <div>
                    {this.getStatus(season)}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>Start</div>
                    {formatDate(season.startTimestamp)}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>End</div>
                    {formatDate(season.endTimestamp)}
                </div>
            </div>
        );
    };

    private getStatus = (season: Entities.Season) => {
        if (isActive(season.startTimestamp, season.endTimestamp)) {
            return <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        if (isEnded(season.endTimestamp)) {
            return <div style={{color: 'orange', fontWeight: 500}}>Completed</div>;
        }
        return <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
    };

    private onCloseDialog = () => this.setState({ openDialog: null, selectedSeason: null});
}

export const StyledChip = styled(Chip)(({
    backgroundColor: '#1a73e8',
    borderRadius: 0,
}));

const StyledMenu = styled(Menu)(({
    '& .MuiMenu-paper': {
        borderRadius: 0,
        marginTop: 2,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 8px 3px, rgba(0, 0, 0, 0.3) 0px 1px 3px',
    },
    '& .MuiMenu-list': {
        padding: 0,
    },
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    list: state.season.list,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
