import React from 'react';
import { AceEditor, AceEditorProps } from './AceEditor';
import { Loading } from './Loading';
import { ActionRequest } from '../../actions';

interface Props extends AceEditorProps{
    url: string;
}

interface State {
    readonly loading: boolean;
    readonly data: string;
}

export class LogsAceEditor extends React.Component<Props, State> {
    state: State = {
        loading: true,
        data: '',
    };

    async componentDidMount() {
        const { url } = this.props;
        const data = await ActionRequest.get(url);
        this.setState({
            loading: false,
            data,
        });
    }

    render() {
        const { loading, data } = this.state;
        if (loading) {
            return (
                <div style={{ padding: 10 }}>
                    <Loading />
                </div>
            );
        }

        return (
            <AceEditor
                {...this.props}
                value={data}
            />
        );
    }
}
