import { Action } from './index';
import { TitleActionType, AppActionType, GroupActionType } from '../enums';

export interface GroupState {
    readonly list?: Entities.Group[];
    readonly details?: Entities.Group;
}

export type GroupAction = Action<Partial<GroupState>>;

const initState: GroupState = {
};

const reducer = (
    state: GroupState = initState,
    action: GroupAction,
): GroupState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case GroupActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
