import * as React from 'react';
import { Button, TextField } from '../common';
import { Grid, InputLabel } from '@mui/material';

interface Props {
    readonly onSubmit: (username: string, password: string) => any;
}

interface State {
    readonly username: string;
    readonly password: string;
}

export class EmailLogin extends React.PureComponent<Props, State> {
    state = {
        username: '',
        password: '',
    };

    render() {
        const { username, password } = this.state;

        return (
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                    <InputLabel style={{ color: 'white', fontWeight: 500 }}>Username</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        id="username"
                        label=""
                        value={username}
                        onChange={this.setUsername}
                        variant="outlined"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <InputLabel style={{ color: 'white', fontWeight: 500 }}>Password</InputLabel>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        id="password"
                        type="password"
                        label=""
                        value={password}
                        onChange={this.setPassword}
                        variant="outlined"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <Button text="Log In" style={{ marginLeft: 'auto' }} variant="contained" onClick={this.submit} disabled={!this.validate()} />
                </Grid>
            </Grid>
        );
    }

    private setUsername = (username: string) => this.setState({ username });

    private setPassword = (password: string) => this.setState({ password });

    private submit = () => this.validate() && this.props.onSubmit(this.state.username, this.state.password);

    private validate = () => this.state.username;
}
