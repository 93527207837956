import { AggregationMethod, ResetFrequency } from '../enums';
import { Request } from '../utils';

export const create = async (name: string, resetFrequency: ResetFrequency, aggregationMethod: AggregationMethod) => {
    await Request.post(`statistic/create`, { name, resetFrequency, aggregationMethod });
};

export const reset = async (name: string) => {
    await Request.post(`statistic/reset`, { name });
};
