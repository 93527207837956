import { Action } from './index';
import { AppActionType, TitleActionType, TitleDataActionType } from '../enums';

export interface TitleDataState {
    readonly versions: Entities.TitleData[];
    readonly titleData: Entities.TitleData | null;
    readonly canRemove: boolean;
}

export type TitleDataAction = Action<Partial<TitleDataState>>;

const initState: TitleDataState = {
    versions: [],
    titleData: null,
    canRemove: true,
};

const reducer = (
    state: TitleDataState = initState,
    action: TitleDataAction,
): TitleDataState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case TitleDataActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
