const KEY = 'storage';

export class LocalStorage {
    static get(): Entities.LocalStorage {
        const str = localStorage.getItem(KEY);
        const obj = str ? JSON.parse(str) : {};
        return {
            auth: {
                sessionId: '',
            },
            google: {
                id: '',
                picture: '',
            },
            appContainer: {
                drawer: true,
            },
            ...obj,
        };
    }

    static set(key: keyof Entities.LocalStorage, data: Partial<Entities.LocalStorage[keyof Entities.LocalStorage]>): void {
        const obj = this.get();
        localStorage.setItem(
            KEY,
            JSON.stringify({
                ...obj,
                [key]: {
                    ...obj[key],
                    ...data,
                },
            }),
        );
    }
}
