import { Action } from './index';
import { TitleActionType, EconomyActionType, AppActionType } from '../enums';

export interface EconomyState {
    readonly items: Entities.EconomyItem[];
    readonly item?: Entities.EconomyItem;
    readonly currencies: Entities.Currency[];
    readonly storeItems: Entities.EconomyStoreItem[];
    readonly storeItem?: Entities.EconomyStoreItem;
}

export type EconomyAction = Action<Partial<EconomyState>>;

const initState: EconomyState = {
    items: [],
    currencies: [],
    storeItems: [],
};

const reducer = (
    state: EconomyState = initState,
    action: EconomyAction,
): EconomyState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case EconomyActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
